import React from "react";
import { Avatar, Divider, Flex, Heading, IconButton } from "@chakra-ui/react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import Navitem1 from "./Navitem1";
import { Outlet } from "react-router-dom";

const Farmerslayout = (props) => {
    const [navSize] = useState("large")

  return (
    <Flex
        // pos="sticky"
        left="50"
        h="10vh"
        marginTop="-40px"
        boxShadow="1 4px 12px 0 rgba(0, 0, 0, 0.05)"
        // w={navSize === "small" ? "75px" : "200px"}
        borderRadius={navSize === "small" ? "15px" : "30px"}
        flexDir="row"
        w="100%"
    >
        <Flex
            
            p top="0"
            flexDir="row"
            alignItems="flex-start"
            as="nav"
            w="100%"
        >
    
            {/* <NavLink to="">
                {(props)=><Navitem1  title="Manage" {...props}/>}
            </NavLink> */}
            <NavLink to="/farmers/farmerenrollment">
                {(props)=><Navitem1   title="Farmer Enrollment" {...props}/>}
            </NavLink>
            <NavLink to="/farmers/farmerlanddetails">
                {(props)=><Navitem1  title="Farmer Land Details" {...props}/>}
            </NavLink>
            {/* <NavLink to="">
                {(props)=><Navitem1  title="Farmer Scheduler" {...props}/>}
            </NavLink>
            <NavLink to="">
                {(props)=><Navitem1  title="Dashboard" {...props}/>}
            </NavLink> */}
            {/* <NavLink to="">
                {(props)=><Navitem1  title="Crontab Process" {...props}/>}
            </NavLink>   */}
            <Outlet />
        </Flex>
        </Flex>
      
  )

}

export default Farmerslayout;