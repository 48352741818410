
import { useTable } from 'react-table';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Icon,
  Flex,
  Center,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { getmqttTlsdata, setSchedule } from '../services/data.service';
import moment from 'moment';

const Dashboard = () => {
    const [tableData, setTableData] = useState([])

    // useEffect(()=>{
    //   getmqttdata().then((response)=>{
    //     if (response.data.code === 200) {
    //       const filteredResult=response.data.data.filter(e=>e.DeviceID)
    //     setTableData(filteredResult)
    //     }
    // }).catch((error)=>{
    //     console.log(error)
    // })

    //     const timer = setInterval(()=>{
    //       getmqttdata().then((response)=>{
    //           const filteredResult=response.data.data.filter(e=>e.DeviceID)
    //           setTableData(filteredResult)
    //       }).catch((error)=>{
    //           console.log(error)
    //       })
    //       }, 20000)

    //       return () => clearTimeout(timer)
    // }, [])

    useEffect(()=>{
      const fetchData = () => {
        getmqttTlsdata()
          .then((response) => {
            if (response.data.code === 200) {
              let filteredData = response.data.data.filter(obj => Object.keys(obj).length !== 0);
              const sortedData = filteredData.sort((a, b) => {
                return moment(b.Time, 'HHmmss')- moment(a.Time, 'HHmmss');
              });
              setTableData(sortedData);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      };
      fetchData();

        const timer = setInterval(()=>{
          getmqttTlsdata().then((response)=>{
            if (response.data.code === 200) {
              let filteredData = response.data.data.filter(obj => Object.keys(obj).length !== 0);
              const sortedData = filteredData.sort((a, b) => {
                return moment(b.Time, 'HHmmss')- moment(a.Time, 'HHmmss');
              });
              setTableData(sortedData);
            }
          }).catch((error)=>{
              console.log(error)
          })
          }, 20000)

          return () => clearTimeout(timer)
    }, [])

    if (tableData.length === 0) {
      return (
        <Flex>
          Data yet to load
        </Flex>
      )
    }

    const BinaryOperationsColumn = ({value, v1, v2}) => {


      // Convert P13 to 16-bit binary and left shift by 16
      const v1Binary = (value[v1] << 16).toString(2).padStart(32, '0');
      // Convert P14 to 32-bit binary 
      const v2Binary = value[v2].toString(2).padStart(32, '0');
      // Perform OR operation
      // const resultBinary = (parseInt(v1Binary, 2) | parseInt(v2Binary, 2)).toString(2).padStart(32, '0');
      const resultBinary = parseInt(v1Binary | v2Binary).toString(2).padStart(32, "0")
      // Convert binary to decimal and divide by 10
      const resultDecimal = parseInt(resultBinary, 2) / 10;
    
      return (
        <div>{resultDecimal}</div>
      );
    };

    const CustomHeader = ({ header }) => {
      return (
        <th
        colSpan={header.headers.length}
        {...header.getHeaderProps()}
        style={{ backgroundColor: '#f2f2f2', padding: '10px', textAlign: 'center' }}
        >
          {header.render('Header')}
        </th>
      );
    };
    
    const ChakraReactTable = ({ columns, data }) => {
      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
      } = useTable({ columns, data });
    
    
      return (
        <Table {...getTableProps()} width="100%" variant="striped">
          <Thead>
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  return column.Header === 'DO Status (P2)' ? (
                    <CustomHeader key={column.id} header={column} />
                  ) : (
                    <Th key={column.id} {...column.getHeaderProps()} style={{ backgroundColor: '#f2f2f2', padding: '10px', textAlign: 'center' }}>
                      {column.render('Header')}
                    </Th>
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()}>
                  {row.cells.map((cell, index) => (
                    <Td {...cell.getCellProps()} key={index}>
                      {cell.column.id === 'binaryOperations1' ? (
                        <BinaryOperationsColumn
                          value={row.original}
                          v1="P13"
                          v2="P14"
                         />
                      ) : cell.column.id === 'binaryOperations2' ? (
                        <BinaryOperationsColumn
                          value={row.original}
                          v1="P16"
                          v2="P17"
                         />
                      ) : (
                        cell.render('Cell')
                      )}
                    </Td>
                  ))}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      );
    };
    

    const getValveStatus = (number, position) => {
        const binaryString = parseInt(number).toString(2).padStart(16, '0');
        const character = binaryString.charAt(15 - position); // 15 - position to account for little-endian bit order
        const result = parseInt(character, 10);
        return result;
        }

     
        const columns = [
          {
            Header: "DeviceID",
            accessor: "deviceID",
            Cell: ({value}) => value
          },
          {
            Header: "Date",
            accessor: "Date",
            Cell: ({value}) => moment(value, "DDMMYYYY").format("DD/MM/YYYY")
          },
          {
            Header: "Time",
            accessor: "Time",
            Cell: ({value}) => {
              const hours = parseInt(value.slice(0, 2), 10);
              const minutes = parseInt(value.slice(2, 4), 10);
              const seconds = parseInt(value.slice(4, 6), 10);
              const time = new Date();
              time.setHours(hours, minutes, seconds)
              return (
                <span>{time.toLocaleTimeString([], { hour12: false })}</span>
              )
            }
          },
          {
            Header: 'DS',
            accessor: "P1",
            Cell: ({value})=>(
              <Icon viewBox='0 0 200 200' color={getValveStatus(value, 0) ? 'green.500' : 'red.500'}>
                  <path
                    fill='currentColor'
                    d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'
                  />
              </Icon>
            )
          },
          {
            Header: "DO Status (P2)",
            columns: [
              {
                Header: 'S1OV1',
                id: 'S1OV1',
                accessor: "P2",
                Cell: ({value})=>(
                  <Icon viewBox='0 0 200 200' color={getValveStatus(value, 2) ? 'green.500' : 'red.500'}>
                      <path
                        fill='currentColor'
                        d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'
                      />
                  </Icon>
                )
              },
              {
                Header: 'S1OV2',
                id: 'S1OV2',
                accessor: 'P2',
                Cell: ({value})=>(
                  <Icon viewBox='0 0 200 200' color={getValveStatus(value, 3) ? 'green.500' : 'red.500'}>
                      <path
                        fill='currentColor'
                        d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'
                      />
                  </Icon>            
                )
              },
              {
                Header: 'S1OV3',
                id: 'S1OV3',
                accessor: "P2",
                Cell: ({value})=>(
                  <Icon viewBox='0 0 200 200' color={getValveStatus(value, 4) ? 'green.500' : 'red.500'}>
                      <path
                        fill='currentColor'
                        d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'
                      />
                  </Icon>            
                )
              },
              {
                Header: 'S1OV4',
                id: 'S1OV4',
                accessor: "P2",
                Cell: ({value})=>(
                  <Icon viewBox='0 0 200 200' color={getValveStatus(value, 5) ? 'green.500' : 'red.500'}>
                      <path
                        fill='currentColor'
                        d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'
                      />
                  </Icon>            
                )
              },
              {
                Header: 'S2OV1',
                id: 'S2OV1',
                accessor: "P2",
                Cell: ({value})=>(
                  <Icon viewBox='0 0 200 200' color={getValveStatus(value, 6) ? 'green.500' : 'red.500'}>
                      <path
                        fill='currentColor'
                        d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'
                      />
                  </Icon>            
                )
              },
              {
                Header: 'S2OV2',
                id: 'S2OV2',
                accessor: "P2",
                Cell: ({value})=>(
                  <Icon viewBox='0 0 200 200' color={getValveStatus(value, 7) ? 'green.500' : 'red.500'}>
                      <path
                        fill='currentColor'
                        d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'
                      />
                  </Icon>            
                )
              },
              {
                Header: 'S2OV3',
                id: 'S2OV3',
                accessor: "P2",
                Cell: ({value})=>(
                  <Icon viewBox='0 0 200 200' color={getValveStatus(value, 8) ? 'green.500' : 'red.500'}>
                      <path
                        fill='currentColor'
                        d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'
                      />
                  </Icon>            
                )
              },
              {
                Header: 'S2OV4',
                id: 'S2OV4',
                accessor: "P2",
                Cell: ({value})=>(
                  <Icon viewBox='0 0 200 200' color={getValveStatus(value, 9) ? 'green.500' : 'red.500'}>
                      <path
                        fill='currentColor'
                        d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'
                      />
                  </Icon>            
                )
              },
              {
                Header: 'S1IV',
                id: 'S1IV',
                accessor: "P2",
                Cell: ({value})=>{
                  return (
                  <Icon viewBox='0 0 200 200' color={getValveStatus(value, 10)===1 && getValveStatus(value, 11) === 0 ? 'green.500' : 'red.500'}>
                      <path
                        fill='currentColor'
                        d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'
                      />
                  </Icon>            
                )}
              },
              {
                Header: 'S2IV',
                id: 'S2IV',
                accessor: "P2",
                Cell: ({value})=>(
                  <Icon viewBox='0 0 200 200' color={getValveStatus(value, 12)===1 && getValveStatus(value, 13) === 0 ? 'green.500' : 'red.500'}>
                      <path
                        fill='currentColor'
                        d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'
                      />
                  </Icon>            
                )
              },
            ],
                  
          },
          {
            Header: 'Pressure 1 kg/m3 (P3)',
            accessor: "P3",
            Cell: ({value})=>{
              const mapValueToRange = (value) => {
                if (value < 4000) {
                  return 0;
                } else if (value >= 4000 && value <= 20000) {
                  // Map the value to the range [1, 10]
                  const mappedValue = Math.ceil(((value - 4000) / 16000) * 9) + 1;
                  return Math.min(mappedValue, 10);
                } else {
                  return 10;
                }
              }
              return (
                <span>{mapValueToRange(value)}</span>
              )
            }
          },
          {
            Header: 'Pressure 2 kg/m3 (P4)',
            accessor: "P4",
            Cell: ({value})=>{
              const mapValueToRange = (value) => {
                if (value < 4000) {
                  return 0;
                } else if (value >= 4000 && value <= 20000) {
                  // Map the value to the range [1, 10]
                  const mappedValue = Math.ceil(((value - 4000) / 16000) * 9) + 1;
                  return Math.min(mappedValue, 10);
                } else {
                  return 10;
                }
              }
              return (
                <span>{mapValueToRange(value)}</span>
              )
            }
          },
          {
            Header: 'Pressure 3 kg/m3 (P5)',
            accessor: "P5",
            Cell: ({value})=>{
              const mapValueToRange = (value) => {
                if (value < 4000) {
                  return 0;
                } else if (value >= 4000 && value <= 20000) {
                  // Map the value to the range [1, 10]
                  const mappedValue = Math.ceil(((value - 4000) / 16000) * 9) + 1;
                  return Math.min(mappedValue, 10);
                } else {
                  return 10;
                }
              }
              return (
                <span>{mapValueToRange(value)}</span>
              )
            }
          },
          {
            Header: 'Flow Rate 1 m3/hr (P12)',
            accessor: "P12",
            Cell: ({value}) => value/10
          },
          {
            Header: 'CUM for S1 m3 (P13+P14)',
            accessor: 'binaryOperations1',
            id: 'binaryOperations1',
            Cell: ({value}) => {
              if (!value.P13) {
                return 0
              }
              
              return (
                <BinaryOperationsColumn value={value} v1="P13" v2="P14" />
              )
              
            }
          },
          {
            Header: 'Flow Rate 2 m3/hr (P15)',
            accessor: "P15",
            Cell: ({value}) => value/10
          },
          {
            Header: 'CUM for S2 m3 (P16+P17)',
            accessor: 'binaryOperations2',
            id: 'binaryOperations2',
            Cell: ({value}) => {
              if (!value.P13) {
                return 0
              }
              
              return (
                <BinaryOperationsColumn value={value} v1="P16" v2="P17" />
              )
              
            }
          },
        ];

           
        return (
          <div>
            <ChakraReactTable columns={columns} data={tableData} />
          </div>
        );
}

export default Dashboard;