import React, { useState } from 'react';
import { Box, Button, Input, FormControl, FormLabel, Heading, Alert, AlertIcon , InputGroup, InputRightElement} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';

const Login = () => {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (username === 'admin' && password === 'apex') {
          navigate('/map');
        } else {
          setError('Invalid username or password');
        }
      };

    const handlePasswordVisibility = () => {
        setShowPassword(!showPassword);
      };

  return (
    <Box maxW="sm" mx="auto" mt="150" p="6" boxShadow="md" borderWidth="1px" borderRadius="md">
      <Heading mb="6" textAlign="center">Login</Heading>
      {error && (
        <Alert status="error" mb="4">
          <AlertIcon />
          {error}
        </Alert>
      )}
      <form onSubmit={handleSubmit}>
        <FormControl id="username" mb="4">
          <FormLabel>Username</FormLabel>
          <Input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
        </FormControl>
        <FormControl id="password" mb="6">
          <FormLabel>Password</FormLabel>
          <InputGroup>
            <Input
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <InputRightElement width="4.5rem">
              <Button h="1.75rem" size="sm" onClick={handlePasswordVisibility}>
                {showPassword ? <ViewOffIcon /> : <ViewIcon />}
              </Button>
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <Button type="submit" colorScheme="teal" width="full">Login</Button>
      </form>
    </Box>
  )
}

export default Login