import { Avatar, Divider, Flex, Heading, IconButton } from "@chakra-ui/react";
import { useState } from "react";
import { FiCalendar, FiHome, FiMenu, FiUser } from 'react-icons/fi';
import { FaArrowTrendUp } from "react-icons/fa6";
import { GiFarmer } from "react-icons/gi";
import Navitem from "./Navitem";
import { NavLink } from "react-router-dom";

const Sidebar = (props) => {
    const [navSize, setNavSize] = useState("large")

  return (
    <Flex
        // pos="sticky"
        left="5"
        h="95vh"
        marginTop="2.5vh"
        boxShadow="1 4px 12px 0 rgba(0, 0, 0, 0.05)"
        w={navSize === "small" ? "75px" : "200px"}
        borderRadius={navSize === "small" ? "15px" : "30px"}
        flexDir="column"
        justifyContent="space-between"
    >
        <Flex
            p="5"
            flexDir="column"
            alignItems="flex-start"
            as="nav"
        >
            <IconButton
                background="none"
                mt="5"
                w="5"
                // _hover={{background: "none"}}
                icon={<FiMenu />}
                onClick={()=>{
                    if (navSize === "small") {
                        setNavSize("large")
                    } else {
                        setNavSize("small")
                    }
                }}
            />
            {/* <NavLink to="dashboard">
                {(props)=><Navitem navSize={navSize} icon={FiHome} title="Dashboard" {...props}/>}
            </NavLink> */}
            <NavLink to="image">
                {(props)=><Navitem navSize={navSize} icon={FiUser} title="Water Flow Visualization" {...props}/>}
            </NavLink>
            <NavLink to="graph">
                {(props)=><Navitem navSize={navSize} icon={FaArrowTrendUp} title="Trends" {...props}/>}
            </NavLink>
            <NavLink to="valveStatus">
                {(props)=><Navitem navSize={navSize} icon={FiUser} title="Valve Status" {...props}/>}
            </NavLink>
            <NavLink to="farmers">
                {(props)=><Navitem navSize={navSize} icon={GiFarmer} title="Farmers" {...props}/>}
            </NavLink>
            
        </Flex>
        
        <Flex
            p="5%"
            flexDir="column"
            w="100%"
            alignItems={navSize === "small" ? "center" : "flex-start"}
            mb={4}
        >
        
            <Divider display={navSize === "small" ? "none" : "flex"} />
            <Flex mt={4} align="center">
                <Avatar size="sm" />
                <Flex ml={4} display={navSize === "small" ? "none" : "flex"}>
                    <Heading as="h3" size="sm">Admin</Heading>
                </Flex>
            </Flex>
            
        </Flex>
    </Flex>


  )

}

export default Sidebar;