import Farmerslayout from '../components/Farmerslayout';

import {Flex} from '@chakra-ui/react';

const Farmershome = () => { 
return (
  <Flex flexDirection="column" p={4}>
  <Farmerslayout />
    </Flex>
  );
};

export default Farmershome;
