import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  HStack,
  useToast,
  Flex,
  Select
} from '@chakra-ui/react';

import { saveFarmers } from '../services/data.service';

import Farmershome from './Farmershome';

const Farmersenrollment = () => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [formData, setFormData] = useState({
    FarmerName: '',
    ChalkName: '',
    MobileNo: '',
    EmailID: '',
    RTUID: '',
    GatewayID: '',
    Latitude: '',
    Longitude: ''
  });

  const toast = useToast();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsButtonDisabled(true);
    try {
      await saveFarmers(formData);
      setFormData({
        FarmerName: '',
        ChalkName: '',
        MobileNo: '',
        EmailID: '',
        RTUID: '',
        GatewayID: '',
        Latitude: '' ,
        Longitude: ''
      });
      toast({
        title: 'Success!',
        description: 'Farmer Details saved successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
        variant: 'left-accent',
      });
    } catch (error) {
      console.error(error);
      toast({
        title: 'Error!',
        description: 'Failed to save farmer.',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
        variant: 'left-accent',
      });
    } finally {
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 2000);
    }
  };

  
return (
  <Flex flexDirection="column" p={4}>
    <Farmershome />
    <h3 style={{marginTop: '10px', fontSize: '30px', fontWeight: 'bold', marginLeft: '25px'}}>Add Farmer Details</h3>
    <Box p={4} width="600px" marginTop={2} marginLeft={50}>
      <VStack as="form" spacing={4} onSubmit={handleSubmit}>
        <FormControl id="FarmerName">
          <HStack spacing={4}>
            <FormLabel m={0} minWidth="120px">Farmer Name</FormLabel>
            <Input
              type="text"
              name="FarmerName"
              value={formData.FarmerName}
              onChange={handleChange}
            />
          </HStack>
        </FormControl>
        <FormControl id="ChalkName">
          <HStack spacing={4}>
            <FormLabel m={0} minWidth="120px">Chalk Name</FormLabel>
            <Input
              type="text"
              name="ChalkName"
              value={formData.ChalkName}
              onChange={handleChange}
            />
          </HStack>
        </FormControl>
        <FormControl id="MobileNo">
          <HStack spacing={4}>
            <FormLabel m={0} minWidth="120px">Mobile No</FormLabel>
            <Input
              type="text"
              name="MobileNo"
              value={formData.MobileNo}
              onChange={handleChange}
            />
          </HStack>
        </FormControl>
        <FormControl id="EmailID">
          <HStack spacing={4}>
            <FormLabel m={0} minWidth="120px">Email ID</FormLabel>
            <Input
              type="email"
              name="EmailID"
              value={formData.EmailID}
              onChange={handleChange}
            />
          </HStack>
        </FormControl>
        <FormControl id="RTUID">
          <HStack spacing={4}>
            <FormLabel m={0} minWidth="120px">RTU ID</FormLabel>
            <Select name='RTUID' value={formData.RTUID} onChange={handleChange}>
              <option value='None'>None</option>
              <option value='Ra-08H Two'>Ra-08H Two</option>
              <option value='Ra-08H Two'>Demo-Unit 1</option>
            </Select>
          </HStack>
        </FormControl>
        <FormControl id="GatewayID">
          <HStack spacing={4}>
            <FormLabel m={0} minWidth="120px">Gateway ID</FormLabel>
            <Select name='GatewayID' value={formData.GatewayID} onChange={handleChange}>
              <option value='None'>None</option>
              <option value='0016c001f10ab960'>0016c001f10ab960</option>
            </Select>
          </HStack>
        </FormControl>
        <FormControl id="Latitude">
          <HStack spacing={4}>
            <FormLabel m={0} minWidth="120px">Latitude</FormLabel>
            <Input
              type="text"
              name="Latitude"
              value={formData.Latitude}
              onChange={handleChange}
            />
          </HStack>
        </FormControl>
        <FormControl id="Longitude">
          <HStack spacing={4}>
            <FormLabel m={0} minWidth="120px">Longitude</FormLabel>
            <Input
              type="text"
              name="Longitude"
              value={formData.Longitude}
              onChange={handleChange}
            />
          </HStack>
        </FormControl>
        <Button
          type="submit"
          colorScheme="blue"
          isDisabled={isButtonDisabled}
        >
          Submit
        </Button>
      </VStack>
    </Box>
    </Flex>
  );
};

export default Farmersenrollment;
