import backendApi from '../apis/backendApi';

export const setSchedule = async (formValues) => {
    return await backendApi.post("/scheduleOn", formValues)
}

export const getmqttdata = async () => {
    return await backendApi.get("/getmqttdata")
}


export const getCommandStatus = async () => {
    return await backendApi.get("/getcurrentcommandstatus")
}

export const getmqttTlsdata = async () => {
    return await backendApi.get("/getmqtTlsdata")
}
    
export const tlsscheduleon = async(data) => {
    return await backendApi.post("/tlsscheduleon" , data)
}

export const updateImagepublish = async() => {
    return await backendApi.post("/updateImagepublish")
}

export const updatedownloadflag =  async() => {
    return await backendApi.post("/updatedownloadflag")
}

export const getImageAndSeq = async() => {
    return await backendApi.get('/getImageandSeq')
}

export const saveFarmers = async(data) => {
    return await backendApi.post('/savefarmers' , data)
}

export const getallFarmers = async() => {
    return await backendApi.get('/getallfarmers')
}

export const getDevicedata = async() => {
    return await backendApi.get('/getDevicedata')
} 

export const getFarmerLocation = async() => {
    return await backendApi.get('/getFarmerLocation')
}

export const commandschedule = async(data) => {
    return await backendApi.post('/commandschedule' , data)
}

export const setOverride = async(data) => {
    return await backendApi.post('/setOverride', data)
}

export const farmerbyRtu = async(data) => {
    return await backendApi.post('/farmerbyRtu' , data)
} 

export const getalltlsdata = async() => {
    return await backendApi.get('/getalltlsdata')
}