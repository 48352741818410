import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { getalltlsdata } from '../services/data.service';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';
import { ChakraProvider, Tabs, TabList, TabPanels, Tab, TabPanel, Box } from '@chakra-ui/react';

const TrendsPage = () => {
  const [chartData, setChartData] = useState([]);
  const [inlet1FlowData, setInlet1FlowData] = useState([]);
  const [inlet2FlowData, setInlet2FlowData] = useState([]);


  const binaryOperations = (value, v1, v2) => {
    // Convert P13 to 16-bit binary and left shift by 16
    const v1Binary = (value[v1] << 16).toString(2).padStart(32, '0');
    // Convert P14 to 32-bit binary
    const v2Binary = value[v2].toString(2).padStart(32, '0');
    // Perform OR operation
    const resultBinary = parseInt(v1Binary | v2Binary).toString(2).padStart(32, "0")
    // Convert binary to decimal and divide by 10
    const resultDecimal = parseInt(resultBinary, 2) / 10;
    
    return resultDecimal;
  };

  useEffect(() => {

    fetchData();

    const timer = setInterval(() => {
      fetchData();
    }, 30000);

    return () => clearInterval(timer);
  }, []);

  const fetchData = async () => {
    try {
      const response = await getalltlsdata();
      console.log(response)
      const data = response.data.data.filter((e) => e.deviceName);

      if (data.length) {
        const firstDataTime = moment(data[0].time);
        const twoHoursAgo = firstDataTime.clone().subtract(2, 'hours');

        const filteredData = data
          .filter((e) => moment(e.time).isBetween(twoHoursAgo, firstDataTime, null, '[]'))
          .map((e) => ({
            time: moment(e.time).format('HH:mm'),
            P3: parseFloat(((e.P3 - 4000) / 1000) * 1.6).toFixed(1),
            P4: parseFloat(((e.P4 - 4000) / 1000) * 1.6).toFixed(1),
            P5: parseFloat(((e.P5 - 4000) / 1000) * 1.6).toFixed(1),
            FV_1: (parseInt(e.P12) * 10),
            FV_2: (parseInt(e.P15) / 10),
            CUM_1: binaryOperations(e, 'P13', 'P14').toFixed(1),
            CUM_2: binaryOperations(e, 'P16', 'P17').toFixed(1),
          }));

        const intervalData = [];
        const intervalInlet1FlowData = [];
        const intervalInlet2FlowData = [];
        for (let i = 0; i <= 12; i++) {
          const timePoint = firstDataTime.clone().subtract(i * 10, 'minutes');
          intervalData.push({
            time: timePoint.format('HH:mm'),
            Main_Pressure: filteredData.find(d => d.time === timePoint.format('HH:mm'))?.P3 || 0,
            Inlet1_Pressure: filteredData.find(d => d.time === timePoint.format('HH:mm'))?.P4 || 0,
            Inlet2_Pressure: filteredData.find(d => d.time === timePoint.format('HH:mm'))?.P5 || 0
          });
          intervalInlet1FlowData.push({
            time: timePoint.format('HH:mm'),
            Inlet_1_flowrate: filteredData.find(d => d.time === timePoint.format('HH:mm'))?.FV_1 || 0,
            CUM_1_flow: filteredData.find(d => d.time === timePoint.format('HH:mm'))?.CUM_1 || 0,
          });
          intervalInlet2FlowData.push({
            time: timePoint.format('HH:mm'),
            Inlet_2_flowrate: filteredData.find(d => d.time === timePoint.format('HH:mm'))?.FV_2 || 0,
            CUM_2_flow: filteredData.find(d => d.time === timePoint.format('HH:mm'))?.CUM_2 || 0,
          });
        }

        setChartData(intervalData);
        setInlet1FlowData(intervalInlet1FlowData);
        setInlet2FlowData(intervalInlet2FlowData);
      }
    } catch (error) {

      console.log(error);
    }
  };

  const formatTooltip = (value, name) => {
    let unit = '';
    if (name === 'Main_Pressure' || name === 'Inlet1_Pressure' || name === 'Inlet2_Pressure') {
      unit = ' Kg/cm2';
    } else if (name === 'Inlet_1_flowrate' || name === 'Inlet_2_flowrate') {
      unit = ' LPS';
    } else if (name === 'CUM_1_flow' || name === 'CUM_2_flow') {
      unit = ' m3';
    }
    return `${value}${unit}`;
  };

  return (
    <ChakraProvider>
      <Box width="80%" mx="auto" my={5} p={10} boxShadow="md" borderRadius="md">
        <Tabs variant="unstyled">
          <TabList>
            <Tab _selected={{ color: 'blue.500', borderBottom: '2px solid', borderColor: 'blue.500' }}>Pressure Trend</Tab>
            <Tab _selected={{ color: 'blue.500', borderBottom: '2px solid', borderColor: 'blue.500' }}>Inlet1 Flow Trend</Tab>
            <Tab _selected={{ color: 'blue.500', borderBottom: '2px solid', borderColor: 'blue.500' }}>Inlet2 Flow Trend</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <ResponsiveContainer width="100%" height={500}>
                <LineChart
                  data={chartData}
                  margin={{ top: 30, right: 30, left: 20, bottom: 80 }}
                >
                  <CartesianGrid strokeDasharray="2 3" />
                  <XAxis dataKey="time">
                    <Label value="Time" offset={-20} position="right" dy={30} style={{ fontWeight: 'bold' , fontSize: '13px'}} />
                  </XAxis>
                  <YAxis domain={[0, 6]} tickCount={7}>
                    <Label value="Kg/cm2" angle={360} position="top" offset={18} dx={20} style={{ fontWeight: 'bold' , fontSize: '13px'}} />
                  </YAxis>
                  <Tooltip formatter={formatTooltip} />
                  <Legend verticalAlign="bottom" wrapperStyle={{ paddingTop: 20 }} />
                  <Line type="monotone" dataKey="Main_Pressure" stroke="#800080" strokeWidth={1.5} />
                  <Line type="monotone" dataKey="Inlet1_Pressure" stroke="#19afaa" strokeWidth={1.5} />
                  <Line type="monotone" dataKey="Inlet2_Pressure" stroke="#008000" strokeWidth={1.5} />
                </LineChart>
              </ResponsiveContainer>
            </TabPanel>
            <TabPanel>
              <ResponsiveContainer width="100%" height={500}>
                <LineChart
                  data={inlet1FlowData}
                  margin={{ top: 30, right: 30, left: 20, bottom: 80 }}
                >
                  <CartesianGrid strokeDasharray="2 3" />
                  <XAxis dataKey="time">
                    <Label value="Time" offset={-20} position="right" dy={30} style={{ fontWeight: 'bold' , fontSize: '13px'}} />
                  </XAxis>
                  <YAxis domain={[0, 150]} ticks={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150]}>
                    <Label value="LPS/m3" angle={360} position="top" offset={20} dx={20} style={{ fontWeight: 'bold' , fontSize: '13px'}} />
                  </YAxis>
                  <Tooltip formatter={formatTooltip} />
                  <Legend verticalAlign="bottom" wrapperStyle={{ paddingTop: 20 }} />
                  <Line type="monotone" dataKey="Inlet_1_flowrate" stroke="#800080" strokeWidth={1.5} />
                  <Line type="monotone" dataKey="CUM_1_flow" stroke="#FF5733" strokeWidth={1.5} />
                </LineChart>
              </ResponsiveContainer>
            </TabPanel>
            <TabPanel>
              <ResponsiveContainer width="100%" height={500}>
                <LineChart
                  data={inlet2FlowData}
                  margin={{ top: 30, right: 30, left: 20, bottom: 80 }}
                >
                  <CartesianGrid strokeDasharray="1 3" />
                  <XAxis dataKey="time">
                    <Label value="Time" offset={-20} position="right" dy={30} style={{ fontWeight: 'bold' , fontSize: '13px'}} />
                  </XAxis>
                  <YAxis domain={[0, 150]} ticks={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150]}>
                    <Label value="LPS/m3" angle={360} position="top" offset={20} dx={20} style={{ fontWeight: 'bold' , fontSize: '13px'}} />
                  </YAxis>
                  <Tooltip formatter={formatTooltip} />
                  <Legend verticalAlign="bottom" wrapperStyle={{ paddingTop: 20 }} />
                  <Line type="monotone" dataKey="Inlet_2_flowrate" stroke="#19afaa" strokeWidth={1.5} />
                  <Line type="monotone" dataKey="CUM_2_flow" stroke="#FFC300" strokeWidth={1.5} />
                </LineChart>
              </ResponsiveContainer>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </ChakraProvider>
  );
};

export default TrendsPage;
