import { useEffect, useState } from "react";

const useData = (props) => {

    const [data, setData] = useState([])
    const {IV_1, IV_2, IV_1_OV_1, IV_1_OV_2, IV_1_OV_3, IV_1_OV_4, IV_2_OV_1, IV_2_OV_2, IV_2_OV_3, IV_2_OV_4} = props

    useEffect(()=>{

        var pipeData = [];

    if (IV_1 !== 0) {
        pipeData.push("inlet_pipe_1", "inletID_1")
            if (IV_1_OV_1 !== 0) {
                pipeData.push("inlet_1_outlet_pipe_1", "inlet_1_outlet_1")
            }
            if (IV_1_OV_2 !== 0) {
                pipeData.push("inlet_1_outlet_pipe_2", "inlet_1_outlet_2")
            }
            if (IV_1_OV_3 !== 0) {
                pipeData.push("inlet_1_outlet_pipe_3", "inlet_1_outlet_3")
            }
            if (IV_1_OV_4 !== 0) {
                pipeData.push("inlet_1_outlet_pipe_4", "inlet_1_outlet_4")
            }
    }
 
    if (IV_2 !== 0) {
        pipeData.push("inlet_pipe_2", "inletID_2")
        if (IV_2_OV_1 !== 0) {
            pipeData.push("inlet_2_outlet_pipe_1", "inlet_2_outlet_1")
        }
        if (IV_2_OV_2 !== 0) {
            pipeData.push("inlet_2_outlet_pipe_2", "inlet_2_outlet_2")
        }
        if (IV_2_OV_3 !== 0) {
            pipeData.push("inlet_2_outlet_pipe_3", "inlet_2_outlet_3")
        }
        if (IV_2_OV_4 !== 0) {
            pipeData.push("inlet_2_outlet_pipe_4", "inlet_2_outlet_4")
        }
    }

setData(pipeData)

    }, [IV_1, IV_2, IV_1_OV_1, IV_1_OV_2, IV_1_OV_3, IV_1_OV_4, IV_2_OV_1, IV_2_OV_2, IV_2_OV_3, IV_2_OV_4])

    return data;
};

export default useData;