import { Flex, Menu, Text } from "@chakra-ui/react"

const Navitem1 = (props) => {
    
const {navSize, title, isActive} = props
  return (
    <Flex
        mt={30}
        flexDir="row"
        w="100%"
        ml={2}
        alignItems={navSize === "small" ? "center" : "flex-start"}
    >
        <Menu placement="right">
            <Flex
                backgroundColor={"seagreen"}
                p={3}
                borderRadius={8}
                _hover={{textDecor: "none", backgroundColor: "lightgray"}}
                w={navSize === "large" && "100%"}
                
            >
                    <Flex>
                        <Text ml={0} display={navSize === "small" ? "none" : "flex"}>{title}</Text>
                    </Flex>
            </Flex>
        </Menu>

    </Flex>
  )

}


export default Navitem1