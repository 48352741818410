import { Flex, Icon, Menu, Text } from "@chakra-ui/react"

const Navitem = (props) => {
    
    const {navSize, title, icon, isActive} = props
  return (
    <Flex
        mt={30}
        flexDir="column"
        w="100%"
        alignItems={navSize === "small" ? "center" : "flex-start"}

    >
        <Menu placement="right">
            <Flex
                backgroundColor={isActive && "#AEC8CA"}
                p={3}
                borderRadius={8}
                _hover={{textDecor: "none", backgroundColor: "#AEC8CA"}}
                w={navSize === "large" && "100%"}
            >
                    <Flex>
                        <Icon as={icon} fontSize="xl" />
                        <Text ml={5} display={navSize === "small" ? "none" : "flex"}>{title}</Text>
                    </Flex>
            </Flex>
        </Menu>
        
    </Flex>
  )

}

export default Navitem