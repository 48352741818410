import React, { useState, useEffect, useMemo } from "react";
import {
    Box, Flex, HStack, Heading, Table, Tbody, Td, Th, Thead, Tr, Select, Spinner, useToast
} from "@chakra-ui/react";
import { useTable } from 'react-table';
import moment from "moment";
import { getCommandStatus, commandschedule, getFarmerLocation } from "../services/data.service";

const LoadingOverlay = () => (
    <Box
        position="fixed"
        top="0"
        left="0"
        width="100vw"
        height="100vh"
        backgroundColor="rgba(255, 255, 255, 0.1)"
        display="flex"
        alignItems="center"
        justifyContent="center"
        zIndex="9999"
        style={{ backdropFilter: 'blur(5px)' }}
    >
        <Spinner size="xl" color="blue.500" thickness="4px" emptyColor="gray.500" speed="0.45s" />
    </Box>
);

const ValvesStatus = () => {
    const [tableData, setTableData] = useState([]);
    const [inlet1State, setInlet1State] = useState({});
    const [inlet2State, setInlet2State] = useState({});
    const [successfulCommands, setSuccessfulCommands] = useState({});
    const [loading, setLoading] = useState(false);
    const toast = useToast();
    const [farmerDetails, setFarmerDetails] = useState({});

    useEffect(() => {
        const fetchFarmerDetails = async () => {
            try {
                const response = await getFarmerLocation();
                if (response.data.code === 200) {
                    setFarmerDetails(response.data.data.details[0]);
                }
            } catch (error) {
                console.error('Failed to fetch farmer details:', error);
            }
        };

        fetchFarmerDetails(); // Initial fetch
        const farmerDetailsTimer = setInterval(fetchFarmerDetails, 5000); // Fetch every 5 seconds

        return () => clearInterval(farmerDetailsTimer);
    }, []);

    useEffect(() => {
        const fetchCommandStatus = async () => {
            try {
                const response = await getCommandStatus();
                if (response.data.code === 200) {
                    setTableData(response.data.data);
                    initializeStates(response.data.data);
                }
            } catch (error) {
                console.error('Failed to fetch command status:', error);
            }
        };

        fetchCommandStatus(); // Initial fetch
        const commandStatusTimer = setInterval(fetchCommandStatus, 5000); // Fetch every 5 seconds

        return () => clearInterval(commandStatusTimer);
    }, []);

    const initializeStates = (data) => {
        const inlet1 = {};
        const inlet2 = {};
        const commands = {};

        data.forEach((item) => {
            const slot = `${item.hour}`;
            const commandKey = `${item.day}-${slot}-${item.inletID === '0' ? 'inlet1' : 'inlet2'}`;

            if (item.inletID === '0') {
                inlet1[`${item.day}-${slot}`] = item.outletID;
            } else {
                inlet2[`${item.day}-${slot}`] = item.outletID;
            }

            if (item.outletID !== '0') {
                commands[commandKey] = true;
            }
        });

        setInlet1State(inlet1);
        setInlet2State(inlet2);
        setSuccessfulCommands(commands);
    };

    const allSlots = [
        { start: 0, end: 6, label: "00 a.m. - 6 a.m." },
        { start: 6, end: 12, label: "6 a.m. - 12 p.m." },
        { start: 12, end: 18, label: "12 p.m. - 18 p.m." },
        { start: 18, end: 24, label: "18 p.m. - 00 a.m." }
    ];

    const generateDays = () => {
        const days = [];
        for (let i = 0; i < 7; i++) {
            days.push(i);
        }
        return days;
    };

    const days = generateDays();

    const handleSelectChange = (day, slot, inletID, outletID) => {
        const mappedOutletID = outletID === 'close' ? '0' : outletID;
        const commandKey = `${day}-${slot.start}-${slot.end}-${inletID}`;
        const updatedState = inletID === 'inlet1' ? { ...inlet1State } : { ...inlet2State };
        updatedState[`${day}-${slot.start}-${slot.end}`] = mappedOutletID;

        if (inletID === 'inlet1') {
            setInlet1State(updatedState);
        } else {
            setInlet2State(updatedState);
        }

        const dataArray = [];
        for (let hour = slot.start; hour < slot.end; hour++) {
            dataArray.push({
                day,
                inletID: inletID === 'inlet1' ? 0 : 1,
                slot: hour,
                outletID: mappedOutletID
            });
        }

        setLoading(true); // Start spinner
        commandschedule({ dataArray })
            .then(response => {
                setLoading(false); // Stop spinner
                if (response.data.code === 200) {
                    setSuccessfulCommands(prevState => ({
                        ...prevState,
                        [commandKey]: mappedOutletID !== '0',
                    }));
                    toast({
                        title: "Success",
                        description: "The command was scheduled successfully.",
                        status: "success",
                        duration: 5000,
                        isClosable: true,
                        position: 'top-right',
                        variant: 'left-accent'
                    });
                } else {
                    toast({
                        title: "Error",
                        description: "Failed to schedule the command.",
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                    });
                    if (inletID === 'inlet1') {
                        setInlet1State(prevState => ({
                            ...prevState,
                            [`${day}-${slot.start}-${slot.end}`]: inlet1State[`${day}-${slot.start}-${slot.end}`],
                        }));
                    } else {
                        setInlet2State(prevState => ({
                            ...prevState,
                            [`${day}-${slot.start}-${slot.end}`]: inlet2State[`${day}-${slot.start}-${slot.end}`],
                        }));
                    }
                }
            })
            .catch(error => {
                setLoading(false); // Stop spinner
                console.error('Failed to schedule command:', error);
                toast({
                    title: "Error Scheduling Command",
                    description: "An error occurred while scheduling the command.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right',
                    variant: 'left-accent'
                });
                if (inletID === 'inlet1') {
                    setInlet1State(prevState => ({
                        ...prevState,
                        [`${day}-${slot.start}-${slot.end}`]: inlet1State[`${day}-${slot.start}-${slot.end}`],
                    }));
                } else {
                    setInlet2State(prevState => ({
                        ...prevState,
                        [`${day}-${slot.start}-${slot.end}`]: inlet2State[`${day}-${slot.start}-${slot.end}`],
                    }));
                }
            });
    };

    const columnsInlet1 = useMemo(() => {
        const slotColumns = allSlots.map((slot) => {
            return {
                Header: slot.label,
                accessor: `${slot.start}-${slot.end}`,
                Cell: ({ row: { original } }) => {
                    const commandKey = `${original.day}-${slot.start}-${slot.end}-inlet1`;
                    const isGreen = successfulCommands[commandKey] || true; // Default to true for green
                    const defaultValue = slot.start === 0 && slot.end === 6 ? '1' : slot.start === 6 && slot.end === 12 ? '2' : slot.start === 12 && slot.end === 18 ? '3' : '4';

                    return (
                        <Box border="1px" borderColor="gray.200" p={2} borderRadius="md" backgroundColor={isGreen ? "green" : "transparent"}>
                            <Select
                                value={inlet1State[`${original.day}-${slot.start}-${slot.end}`] || defaultValue}
                                onChange={(e) => handleSelectChange(original.day, slot, 'inlet1', e.target.value)}
                                variant={'unstyled'}
                            >
                                <option value="1">Valve 1</option>
                                <option value="2">Valve 2</option>
                                <option value="3">Valve 3</option>
                                <option value="4">Valve 4</option>
                                <option value="close">Close</option>
                            </Select>
                        </Box>
                    );
                },
            };
        });

        return [
            {
                Header: "Day",
                accessor: "day",
                Cell: ({ value }) => moment.weekdays(value),
            },
            ...slotColumns,
        ];
    }, [inlet1State, successfulCommands]);

    const columnsInlet2 = useMemo(() => {
        const slotColumns = allSlots.map((slot) => {
            return {
                Header: slot.label,
                accessor: `${slot.start}-${slot.end}`,
                Cell: ({ row: { original } }) => {
                    const commandKey = `${original.day}-${slot.start}-${slot.end}-inlet2`;
                    const isGreen = successfulCommands[commandKey] || true; // Default to true for green
                    const defaultValue = slot.start === 0 && slot.end === 6 ? '1' : slot.start === 6 && slot.end === 12 ? '2' : slot.start === 12 && slot.end === 18 ? '3' : '4';

                    return (
                        <Box border="1px" borderColor="gray.200" p={2} borderRadius="md" backgroundColor={isGreen ? "green" : "transparent"}>
                            <Select
                                value={inlet2State[`${original.day}-${slot.start}-${slot.end}`] || defaultValue}
                                onChange={(e) => handleSelectChange(original.day, slot, 'inlet2', e.target.value)}
                                variant={'unstyled'}
                            >
                                <option value="1">Valve 5</option>
                                <option value="2">Valve 6</option>
                                <option value="3">Valve 7</option>
                                <option value="4">Valve 8</option>
                                <option value="close">Close</option>
                            </Select>
                        </Box>
                    );
                },
            };
        });

        return [
            {
                Header: "Day",
                accessor: "day",
                Cell: ({ value }) => moment.weekdays(value),
            },
            ...slotColumns,
        ];
    }, [inlet2State, successfulCommands]);

    const ChakraTable = ({ columns, data }) => {
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            rows,
            prepareRow,
        } = useTable({ columns, data });

        return (
            <Table {...getTableProps()} width="100%" variant="striped">
                <Thead>
                    {headerGroups.map((headerGroup) => (
                        <Tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <Th key={column.id} {...column.getHeaderProps()} style={{ backgroundColor: "grey" }}>
                                    {column.render("Header")}
                                </Th>
                            ))}
                        </Tr>
                    ))}
                </Thead>
                <Tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                        prepareRow(row);
                        return (
                            <Tr {...row.getRowProps()}>
                                {row.cells.map((cell) => (
                                    <Td key={cell.id} {...cell.getCellProps()}>
                                        {cell.render("Cell")}
                                    </Td>
                                ))}
                            </Tr>
                        );
                    })}
                </Tbody>
            </Table>
        );
    };

    const fillInData = (days) => {
        return days.map((day) => {
            const filledValues = allSlots.reduce((acc, slot) => {
                const slotKey = `${slot.start}-${slot.end}`;
                const status = tableData.find(s => s.day === day && s.hour === slotKey);

                if (status) {
                    acc[slotKey] = status.outletID;
                } else {
                    acc[slotKey] = '0';
                }

                return acc;
            }, {});

            return {
                day,
                ...filledValues,
            };
        });
    };

    const filledInlet1Data = fillInData(days);
    const filledInlet2Data = fillInData(days);

    return (
        <Box>
            <Flex flexDir="column" width={1200}>
                <HStack justifyContent="space-between">
                    <Heading>Current Scheduler Page</Heading>
                    <Heading as="h3">Device: {farmerDetails.deviceName}</Heading>
                </HStack>
                <Flex mb={5} flexDir="column" alignItems="center" marginTop={5}>
                    <Heading as="h2" size="md">Inlet 1 Outlet's Current Schedule Status</Heading>
                    <ChakraTable columns={columnsInlet1} data={filledInlet1Data} />
                </Flex>
                <Flex mb={5} flexDir="column" alignItems="center" marginTop={10}>
                    <Heading as="h2" size="md">Inlet 2 Outlet's Current Schedule Status</Heading>
                    <ChakraTable columns={columnsInlet2} data={filledInlet2Data} />
                </Flex>
            </Flex>
            {loading && <LoadingOverlay />}
        </Box>
    );
};

export default ValvesStatus;
