import React, { useState, useEffect } from 'react';
import {
    Box,
    Table,
    Thead,
    Tbody,
    Td,
    Th,
    Tr,
    Flex,
  } from '@chakra-ui/react';

  import Farmerslayout from '../components/Farmerslayout';

  import { getallFarmers } from '../services/data.service';
  
  const Farmerlanddetails=()=>{

   const [farmers, setFarmers] = useState([]);

   useEffect(() => {
    fetchFarmers();
  }, []);

  const fetchFarmers = async () => {
    try {
      const response = await getallFarmers();
      console.log('API Response:', response.data); // For debugging
      if (response.data && response.data.data && response.data.data.details) {
        setFarmers(response.data.data.details);
      } else {
        setFarmers([]);
      }
    } catch (error) {
      console.error('Failed to fetch farmers:', error);
      setFarmers([]);
    }
  };

  return (
    <Flex>
        <Box mt={10}>
            <Farmerslayout />
        <h3 style={{marginTop: '25px' , fontSize: '30px', fontWeight: 'bold', marginLeft: '25px'}}>Farmer Land Details</h3>
          <Table variant="striped" width={1200} marginLeft={8} marginTop={5}>
            <Thead>
              <Tr>
                <Th>Farmer Name</Th>
                <Th>Chalk Name</Th>
                <Th>Mobile No</Th>
                <Th>Email ID</Th>
                <Th>OMSID</Th>
                <Th>Gateway ID</Th>
                <Th>Latitude</Th>
                <Th>Longitude</Th>
              </Tr>
            </Thead>
            <Tbody>
            {farmers && farmers.length > 0 ? (
              farmers.map((farmer) => (
                <Tr key={farmer._id}>
                  <Td>{farmer.FarmerName}</Td>
                  <Td>{farmer.ChalkName}</Td>
                  <Td>{farmer.MobileNo}</Td>
                  <Td>{farmer.EmailID}</Td>
                  <Td>{farmer.RTUID}</Td>
                  <Td>{farmer.GatewayID}</Td>
                  <Td>{farmer.Latitude}</Td>
                  <Td>{farmer.Longitude}</Td>
                </Tr>
              ))
            ) : (
              <Tr>
                <Td colSpan="8" textAlign="center">No data available</Td>
              </Tr>
            )}
          </Tbody>
          </Table>
        </Box>
      </Flex>
    );
  }
  
  export default Farmerlanddetails;
  