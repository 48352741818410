import { useEffect, useState } from "react";
import SvgComponent from "../components/SvgComponent";

import { getmqttTlsdata , updateImagepublish , updatedownloadflag , getImageAndSeq, setOverride, getFarmerLocation} from "../services/data.service";

import { Flex, Text, Box, Button, Progress, useToast } from "@chakra-ui/react";
import { FaDoorClosed, FaWifi, FaBatteryFull, FaSun, FaDoorOpen } from "react-icons/fa";
import moment from "moment";
import { useProgress } from './ProgressContext';


const ImageRep = () => {

  const [tableData, setTableData] = useState([]);
  const [data, setData] = useState({});
  const [farmerDetails, setFarmerDetails] = useState({});
  const { showProgressBar, setShowProgressBar, progressValue, setProgressValue } = useProgress();
  const toast = useToast();

  useEffect(() => {
    const fetchFarmerDetails = async () => {
      try {
        const response = await getFarmerLocation();
        if (response.data.code === 200) {
          setFarmerDetails(response.data.data.details[0]);
        }
      } catch (error) {
        console.error('Failed to fetch farmer details:', error);
      }
    };

    fetchFarmerDetails(); // Initial fetch
    const farmerDetailsTimer = setInterval(fetchFarmerDetails, 5000); // Fetch every 5 seconds

    return () => clearInterval(farmerDetailsTimer);
  }, []);

  useEffect(()=>{
    getmqttTlsdata().then((response)=>{
      const filteredResult=response.data.data.filter(e=>e.deviceID)
      setTableData(filteredResult)
  }).catch((error)=>{
      console.log(error)
  })

  const timer = setInterval(()=>{
    getmqttTlsdata().then((response)=>{
      if (response.data.code === 200) {
        const filteredResult=response.data.data.filter(e=>e.deviceID)
        const sortedData = response.data.data.sort((a, b) => {
          return moment(a.Time, 'HHmmss')- moment(b.Time, 'HHmmss');
        });
        setTableData(sortedData);
      }
  }).catch((error)=>{
      console.log(error)
  })
  }, 5000)

  return () => clearTimeout(timer)
  }, [])

  useEffect(()=>{

    if (tableData.length !==0) {
      let compData = setCompData()
      setData(compData)
    }

  }, [tableData]);

  const Override = (data) => {
    const saveOverride = async () => {
      try {
        console.log('Image', data);
        const response = await setOverride(data);
        console.log('Override successfully', response.data);
        toast({
          title: "Success",
          description: "The override send successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: 'top-right',
          variant: 'left-accent'
        });
      } catch (error) {
        console.log('Error while overriding:', error);
        toast({
          title: "Error",
          description: "Override was not send successfully",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: 'top-right',
          variant: 'left-accent'
        }); 
      }
    };
    saveOverride();
  };


  const getSignalStrength = (rssi, snr) => {
    if (rssi >= -90 && snr >= 10) {
      return "GOOD";
    } else if (rssi >= -110 && snr >= 5) {
      return "FAIR";
    } else {
      return "BAD";
    }
  };
  
  

  if (tableData.length === 0 ) {
    return (
      <Flex>
        <Text>No data yet</Text>
      </Flex>
    )
  }

  const handleUpdateImage = async () => {
    setShowProgressBar(true);

    let isFirstClick = true;
    let isProgressComplete = false;

    try {
      const interval = setInterval(async () => {
        try {
          if (isFirstClick) {
            await Promise.all([updateImagepublish(), updatedownloadflag()]);
            isFirstClick = false;
          }

          const updateData = await getImageAndSeq();
          const { seq_no_sent, image_size_bytes } = updateData.data.data.response[0];
          const seqNo = Number(seq_no_sent);
          const imageSize = Number(image_size_bytes);

          const progress = (seqNo / imageSize) * 100;
          setProgressValue(progress);

          console.log(progress);

          if (progress >= 100) {
            clearInterval(interval);
            setShowProgressBar(false);
            console.log('Image updated successfully!');
            isProgressComplete = true;
          }
        } catch (error) {
          console.error('Error updating image:', error);
          setShowProgressBar(false);
          clearInterval(interval);
        }
      }, 1000);

      while (!isProgressComplete) {
        await new Promise((resolve) => setTimeout(resolve, 100));
      }

      await Promise.all([updateImagepublish(), updatedownloadflag()]);
    } catch (error) {
      console.error('Error in handleUpdateImage:', error);
      setShowProgressBar(false);
    }
  };

  const getValveStatus = (number, position) => {
    const binaryString = parseInt(number).toString(2).padStart(16, '0');
    const character = binaryString.charAt(15 - position); // 15 - position to account for little-endian bit order
    const result = parseInt(character, 10);
    return result;
    }

    const binaryOperations = (value, v1, v2) => {

      // Convert P13 to 16-bit binary and left shift by 16
      const v1Binary = (value[v1] << 16).toString(2).padStart(32, '0');
      // Convert P14 to 32-bit binary
      const v2Binary = value[v2].toString(2).padStart(32, '0');
      // Perform OR operation
      const resultBinary = parseInt(v1Binary | v2Binary).toString(2).padStart(32, "0")
      // Convert binary to decimal and divide by 10
      const resultDecimal = parseInt(resultBinary, 2) / 10;
    
      return (
        resultDecimal
      );
    };

  const mapValueToRange = (value) => {
      if (value < 4000) {
        return 0;
      } else if (value >= 4000 && value <= 20000) {
        // Map the value to the range [1, 10]
        const mappedValue = ((value - 4000) / 16000) * 9 + 1;
        return mappedValue
      } else {
        return 10;
      }
    }

    const P10_value  = parseInt(tableData[0].P10)
    const P9_value = parseInt(tableData[0].P9)

  const setCompData = () => {
    const IV_1_OV_1 = getValveStatus(tableData[0].P2, 2) === 1 ? 1 : 0;
    const IV_1_OV_2 = getValveStatus(tableData[0].P2, 3) === 1 ? 1 : 0;
    const IV_1_OV_3 = getValveStatus(tableData[0].P2, 4) === 1 ? 1 : 0;
    const IV_1_OV_4 = getValveStatus(tableData[0].P2, 5) === 1 ? 1 : 0;
    const IV_2_OV_1 = getValveStatus(tableData[0].P2, 6) === 1 ? 1 : 0;
    const IV_2_OV_2 = getValveStatus(tableData[0].P2, 7) === 1 ? 1 : 0;
    const IV_2_OV_3 = getValveStatus(tableData[0].P2, 8) === 1 ? 1 : 0;
    const IV_2_OV_4 = getValveStatus(tableData[0].P2, 9) === 1 ? 1 : 0;
    const IV_1 = getValveStatus(tableData[0].P2, 10) === 0 && getValveStatus(tableData[0].P2, 11) === 0 ? 0 : 1;
    const IV_2 = getValveStatus(tableData[0].P2, 12) === 0 && getValveStatus(tableData[0].P2, 13) === 0 ? 0 : 1;
    const FV_1 = (tableData[0].P12/10);
    const FV_2 = (tableData[0].P15/10);
    const CUM_1 = binaryOperations(tableData[0], "P13", "P14").toFixed(1);
    const CUM_2 = binaryOperations(tableData[0], "P16", "P17").toFixed(1);
    const PV_1 =  parseFloat(((tableData[0].P3 - 4000) / 1000) * 1.6).toFixed(1);
    const PV_2 = parseFloat(((tableData[0].P4 - 4000) / 1000) * 1.6).toFixed(1);
    const PV_3 = parseFloat(((tableData[0].P5 - 4000) / 1000) * 1.6).toFixed(1);
    return {IV_1_OV_1, IV_1_OV_2, IV_1_OV_3, IV_1_OV_4, IV_2_OV_1, IV_2_OV_2, IV_2_OV_3, IV_2_OV_4, IV_1, IV_2, FV_1, FV_2, CUM_1, CUM_2, PV_1, PV_2, PV_3}
  }


 return (
  <Box style={{display: "flex", flexDirection: "column", height: "100vh", width: "70%"}}>
    <Flex
      position="absolute"
      top="15px"
      right="100px"
      alignItems="center"
      justifyContent="flex-end"
      gap="25px"
    >
      <Box boxSize="30px" as={getValveStatus(tableData[0].P1, 0) === 1 ? FaDoorOpen : FaDoorClosed} color="darkgreen" />
      <Box boxSize="30px" as={getValveStatus(tableData[0].P1, 1) === 1 ? FaDoorOpen : FaDoorClosed} color="darkgreen" />
      <Box>
          <Flex direction="column" align="center" marginTop='12px'>
            <FaWifi size={30} color={farmerDetails.rssi && farmerDetails.snr ? "darkgreen" : "grey"} />
            <Text fontSize="xs" fontWeight="bold">
            {getSignalStrength(farmerDetails?.rssi, farmerDetails?.snr)}
            </Text>
          </Flex>
        </Box>

      <Box>
          <Flex direction="column" align="center" marginTop='12px'>
            <FaBatteryFull size={30} color="darkgreen" />
            <Text fontSize="xs" fontWeight="bold">
            {(P10_value / 1000).toFixed(1)} V
            </Text>
          </Flex>
        </Box>
        <Box>
          <Flex direction="column" align="center" marginTop='12px'>
            <FaSun size={30} color="darkgreen" />
            <Text fontSize="xs" fontWeight="bold">
              {(P9_value / 1000).toFixed(1)} V
            </Text>
          </Flex>
        </Box>
    </Flex>
    {farmerDetails && (
        <Box position="absolute" marginTop={5} marginLeft={1050} bg="white" borderRadius="lg"  boxShadow="base" p={1}>
          <Text fontSize="15px"><span style={{fontWeight: 'bold'}}>RTUID:</span> {farmerDetails.deviceName}</Text>
          <Text fontSize="15px"><span style={{fontWeight: 'bold'}}>Gateway ID:</span>{farmerDetails.gatewayId}</Text>
          <Text fontSize="15px"><span style={{fontWeight: 'bold'}}>Farmer Name:</span> {farmerDetails.FarmerName}</Text>
          <Text fontSize="15px"><span style={{fontWeight: 'bold'}}>MobileNo:</span>{farmerDetails.MobileNo}</Text>
          <Text fontSize="xs"><span style={{fontWeight: 'bold'}}>Latitude:</span>{farmerDetails.Latitude}</Text>
          <Text fontSize="xs"><span style={{fontWeight: 'bold'}}>Longitude:</span>{farmerDetails.Longitude}</Text>
          <Text fontSize="xs"><span style={{fontWeight: 'bold'}}>LastUpdated:</span>{moment(farmerDetails.time).format('DD-MM-YYYY hh:mm:ss')}</Text>
        </Box>
      )}
    <Box style={{height: "80vh", width: "100%"}}>
      <SvgComponent compData={data}  Override={Override} />
    </Box>
   
    <Box style={{ position: 'fixed', bottom: 40, left: 1150, right: 200, marginBottom: '15px' }}>
       <Button style={{marginTop: '15px', backgroundColor: "#63a6ff5b"}} onClick={handleUpdateImage}>Update New Image</Button> 
    </Box>
    {showProgressBar && (
        <Box style={{ position: 'fixed', bottom: 0, left: 300, right: 200, marginBottom: '15px' }}>
          <Text style={{marginBottom: '5px' , marginLeft: '20px'}}>
               Uploading...({`${progressValue}`.substring(0, 2)}%)
          </Text>
          <Progress value={progressValue} hasStripe isAnimated />
        </Box>
      )}
  </Box>
  )

};

export default ImageRep;