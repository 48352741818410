import { Routes, Route, Navigate } from 'react-router-dom';
import Layout from './components/Layout';
import Dashboard from './pages/Dashboard';
import Publish from './pages/Publish';
import ValvesStatus from './pages/ValvesStatus';
import { ProgressProvider } from './pages/ProgressContext';
import GisMap from './pages/GisMap';
import Farmershome from './pages/Farmershome';
import Farmersenrollment from './pages/Farmersenrollment';
import Farmerlanddetails from './pages/Farmerlanddetails';
import ImageRep from './pages/ImageRep';
import Login from './pages/Login';
import GraphComponent from './pages/TrendsPage';


const App = () => {
  return (
    <ProgressProvider>
      <Routes>
        {/* Route for initial load displaying GisMap */}
        <Route path='/' element={<Login /> } />
        <Route path="/map" element={<GisMap />} />

        {/* Layout routes */}
        <Route element={<Layout />}>
          {/* <Route path="/dashboard" element={<Dashboard />} /> */}
          {/* <Route path="/publish" element={<Publish />} /> */}
          {/* <Route path="/publish" element={<ImageRep />} /> */}
          <Route path="/valveStatus" element={<ValvesStatus />} />
          <Route path="/farmers" element={<Farmershome />} />
          <Route path="/farmers/farmerenrollment" element={<Farmersenrollment />} />
          <Route path="/farmers/farmerlanddetails" element={<Farmerlanddetails />} />
          <Route path='/image' element={<ImageRep /> } />
          <Route path="*" element={<Navigate to="/image" />} />
          <Route path='/graph' element={<GraphComponent />} />
        </Route>
      </Routes>
    </ProgressProvider>
  );
};

export default App;
