import { Button, Flex, Heading, Text } from "@chakra-ui/react";
import { MdExitToApp } from 'react-icons/md';
import Sidebar from "./Sidebar";
import { Navigate, Outlet } from 'react-router-dom';
import { useState } from "react";

const Layout = () => {

  return (
    <Flex>
        <Sidebar />
        <Flex
          flexDir="column"
          w="100%"
        >
          <Flex
            flexDir="row"
            h="10vh"
            justify="space-between"
          >
            <Flex>
            <Heading
              as="h3"
              color="gray.500"
              marginTop={3}
            >
              OMS DEMO
            </Heading>
            </Flex>
            
            
          </Flex>
          <Flex
            h="85vh"
          >
              <Outlet />
          </Flex>
        </Flex>
    </Flex>
  )
}

export default Layout;