import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Tooltip } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { useNavigate } from 'react-router-dom';

import Green_icon from '../assets/images/Green_icon.png';

import './GisMap.css';
import { getFarmerLocation } from '../services/data.service';

const myIcon = L.icon({
  iconUrl: Green_icon,
  iconSize: [35, 35],
  iconAnchor: [12.5, 41],
});

const GisMap = () => {
  const [positions, setPositions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [initialPosition, setInitialPosition] = useState([10.611400, 79.263870]); // Initial position for centering the map

  const navigate = useNavigate(); // Use useNavigate hook

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getFarmerLocation();
        const deviceDataResponse = response.data.data.details;

        const validPositions = deviceDataResponse.filter(device => device.Latitude && device.Longitude);
        setPositions(validPositions);

        if (validPositions.length > 0) {
          // Set initial position to the first valid position found
          setInitialPosition([validPositions[0].Latitude, validPositions[0].Longitude]);
        }
      } catch (error) {
        console.error('Failed to fetch device data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const gatewayIdMarker = [12.9558978, 80.243273]; // Assuming gateway ID marker coordinates are available

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
    <MapContainer className="map" center={initialPosition} zoom={17} scrollWheelZoom={false}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {positions.map((device, index) => (
        <Marker key={index} 
        position={[device.Latitude, device.Longitude]} 
        icon={myIcon}
        eventHandlers={{
          click: () => {
            navigate(`/image`);
          },
        }}>
          <Tooltip direction="bottom" offset={[5, -8]} opacity={1} permanent>
            <span style={{ fontWeight: 'bold', fontSize: '11px' }}>
              {device.deviceName}
            </span>
          </Tooltip>
        </Marker>
      ))}
      {positions.map((device, index) => (
        <Marker key={index}
        position={gatewayIdMarker} 
        icon={myIcon} 
        eventHandlers={{
          click: () => {
            navigate(`/image`);
          },
        }}>
          <Tooltip direction="bottom" offset={[5, -8]} opacity={1} permanent>
            <span style={{ fontWeight: 'bold', fontSize: '11px' }}>
              Gateway 1: {device.gatewayId}
            </span>
          </Tooltip>
        </Marker>
      ))}
    </MapContainer>
  </div>
  );
};

export default GisMap;
