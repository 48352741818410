import { useEffect, useRef, useState } from "react";
import "./svgcomponent.css"
import * as d3 from 'd3';
import useData from "../hooks/useData";

const SvgComponent = (props) => {

  const [valveColor, setValveColor] = useState('gray');
  const [pipeColor, setPipeColor] = useState('');
  const [pathVariables, setPathVariables] = useState([]);
  const [buttonStatus, setButtonStatus] = useState({
    "inlet_1_button_1": "",
    "inlet_1_button_2": "",
    "inlet_1_button_3": "",
    "inlet_1_button_4": "",
    "inlet_2_button_1": "",
    "inlet_2_button_2": "",
    "inlet_2_button_3": "",
    "inlet_2_button_4": ""
  });

  const {compData , Override } = props;
  const manipulatedData = useData(compData);

  useEffect(()=>{

    const allIDArray = [...allPipeID, ...allValveID]

    const pathVar = allIDArray.map((eachID)=>{
      return retainAllColors(eachID)  
    })

    setPathVariables(pathVar)

  }, [])

  useEffect(()=>{

    // if (manipulatedData.length === 0 && pathVariables.length !== 0) {
    //   const allIDs = [...allValveID, ...allPipeID]

    //   allIDs.map((id)=>{
    //     revertColors(id)
    //   })
    // } 
    
    if (pathVariables.length !== 0) {

      valves_off(manipulatedData, allValveID).map((valve)=>{
        d3.select(`g#${valve}`).selectAll("path").style("fill", "#c52626ab")      
      })
  
        pipes_off(manipulatedData, allPipeID).map((pipe)=>{
          revertColors(pipe)
        })
  
      valves_on(manipulatedData, allValveID).map((valve)=>{
        d3.select(`g#${valve}`).selectAll("path").style("fill", "#39f072ab")      
      })
  
      pipes_on(manipulatedData, allPipeID).map((pipe)=>{
        d3.select(`g#${pipe}`).selectAll("path").style("fill", "#63a6ff5b")
      })

    }


  }, [manipulatedData, pathVariables])

  useEffect(()=>{

    if (Object.keys(compData).length !==0) {
      d3.select("text#inlet_pressure_text").select("tspan").text(compData.PV_1)
      d3.select("text#inlet_1_pressure_text").select("tspan").text(compData.PV_2)
      d3.select("text#inlet_2_pressure_text").select("tspan").text(compData.PV_3)
      d3.select("text#inlet_1_flow").select("tspan").text(compData.FV_1)
      d3.select("text#inlet_2_flow").select("tspan").text(compData.FV_2)
      d3.select("text#inlet_1_cum").select("tspan").text(compData.CUM_1)
      d3.select("text#inlet_2_cum").select("tspan").text(compData.CUM_2)
    }

  }, [compData])

  const allValveID = [
    "inletID_1",
    "inletID_2",
    "inlet_1_outlet_1",
    "inlet_1_outlet_2",
    "inlet_1_outlet_3",
    "inlet_1_outlet_4",
    "inlet_2_outlet_1",
    "inlet_2_outlet_2",
    "inlet_2_outlet_3",
    "inlet_2_outlet_4",
    ]

    const allPipeID = [
      "inlet_pipe_1",
      "inlet_pipe_2",
      "inlet_1_outlet_pipe_1",
      "inlet_1_outlet_pipe_2",
      "inlet_1_outlet_pipe_3",
      "inlet_1_outlet_pipe_4",
      "inlet_2_outlet_pipe_1",
      "inlet_2_outlet_pipe_2",
      "inlet_2_outlet_pipe_3",
      "inlet_2_outlet_pipe_4"
      ]

      const valves_off = (incomingData, valveIDs) => {
        return valveIDs.filter(element => !incomingData.includes(element))
      }

      const pipes_off = (incomingData, pipeIDs) => {
        return pipeIDs.filter(element => !incomingData.includes(element))
      }

      const valves_on = (incomingData, valveIDs) => {
        return valveIDs.filter(element => incomingData.includes(element))
      }

      const pipes_on = (incomingData, pipeIDs) => {
        return pipeIDs.filter(element => incomingData.includes(element))
      }

  const changeColor = (pipeID, color) => {
    d3.select(`g#${pipeID}`).selectAll("path").style("fill", color)
    }

    const retainAllColors = (id) => {
      var pathObj = {mainID: id, paths: []}
      d3.select(`g#${id}`).selectAll("path").each(function () {
        const path = d3.select(this)
        const pathID = path.attr("id")
        const fill = path.style("fill")
        pathObj.paths.push({pathID, fill})
      })
      return pathObj
    }

    const revertColors = (id) => {
      const item = pathVariables.find(item => item.mainID === id)
      item.paths.map((path)=>{
        d3.select(`path#${path.pathID}`).style("fill", path.fill)
      })
    };

    const handleValveClick = (inletID, outletID) => {
      let cmdValue = 0;
  
      const outletValuesMapping = {
        "0_0": "IV_1",
        "1_0": "IV_2",
        "0_1": "IV_1_OV_1",
        "0_2": "IV_1_OV_2",
        "0_3": "IV_1_OV_3",
        "0_4": "IV_1_OV_4",
        "1_1": "IV_2_OV_1",
        "1_2": "IV_2_OV_2",
        "1_3": "IV_2_OV_3",
        "1_4": "IV_2_OV_4"
      };
  
      const outletValue = outletValuesMapping[`${inletID}_${outletID}`];
      if (outletValue && compData[outletValue] === 0) {
        cmdValue = 1;
      }
  
      const data = { inletID, outletID, cmdValue };
      console.log('SVG DATA', data);
      Override(data);
    };
  
    useEffect(() => {
      const valvesToCheck = [
        "inlet_1_outlet_1",
        "inlet_1_outlet_2",
        "inlet_1_outlet_3",
        "inlet_1_outlet_4",
        "inlet_2_outlet_1",
        "inlet_2_outlet_2",
        "inlet_2_outlet_3",
        "inlet_2_outlet_4"
      ];
  
      const openValves = valves_on(manipulatedData, valvesToCheck);
  
      const inlet1Valves = [
        "inlet_1_outlet_1",
        "inlet_1_outlet_2",
        "inlet_1_outlet_3",
        "inlet_1_outlet_4"
      ];
      const inlet2Valves = [
        "inlet_2_outlet_1",
        "inlet_2_outlet_2",
        "inlet_2_outlet_3",
        "inlet_2_outlet_4"
      ];
  
      const openInlet1Valves = openValves.filter(valve => inlet1Valves.includes(valve));
      const openInlet2Valves = openValves.filter(valve => inlet2Valves.includes(valve));
  
      const allInlet1ValvesClosed = openInlet1Valves.length === 0;
      const allInlet2ValvesClosed = openInlet2Valves.length === 0;
  
      const updatedStatus = { ...buttonStatus };
  
      valvesToCheck.forEach(valve => {
        let buttonId;
        switch (valve) {
          case "inlet_1_outlet_1":
            buttonId = "inlet_1_button_1";
            break;
          case "inlet_1_outlet_2":
            buttonId = "inlet_1_button_2";
            break;
          case "inlet_1_outlet_3":
            buttonId = "inlet_1_button_3";
            break;
          case "inlet_1_outlet_4":
            buttonId = "inlet_1_button_4";
            break;
          case "inlet_2_outlet_1":
            buttonId = "inlet_2_button_1";
            break;
          case "inlet_2_outlet_2":
            buttonId = "inlet_2_button_2";
            break;
          case "inlet_2_outlet_3":
            buttonId = "inlet_2_button_3";
            break;
          case "inlet_2_outlet_4":
            buttonId = "inlet_2_button_4";
            break;
          default:
            break;
        }
  
        if (valve.startsWith("inlet_1")) {
          if (allInlet1ValvesClosed) {
            d3.select(`ellipse#${buttonId}`).on("click", () => handleValveClick(getInletID(valve), getOutletID(valve)));
            updatedStatus[buttonId] = "on";
          } else {
            if (openInlet1Valves.includes(valve)) {
              d3.select(`ellipse#${buttonId}`).on("click", () => handleValveClick(getInletID(valve), getOutletID(valve)));
              updatedStatus[buttonId] = "off";
            } else {
              d3.select(`ellipse#${buttonId}`).on("click", null);
              updatedStatus[buttonId] = "";
            }
          }
        } else if (valve.startsWith("inlet_2")) {
          if (allInlet2ValvesClosed) {
            d3.select(`ellipse#${buttonId}`).on("click", () => handleValveClick(getInletID(valve), getOutletID(valve)));
            updatedStatus[buttonId] = "on";
          } else {
            if (openInlet2Valves.includes(valve)) {
              d3.select(`ellipse#${buttonId}`).on("click", () => handleValveClick(getInletID(valve), getOutletID(valve)));
              updatedStatus[buttonId] = "off";
            } else {
              d3.select(`ellipse#${buttonId}`).on("click", null);
              updatedStatus[buttonId] = "";
            }
          }
        }
      });
  
      setButtonStatus(updatedStatus);
    }, [manipulatedData]);
  
    const getInletID = (valve) => {
      if (valve.startsWith("inlet_1")) {
        return 0;
      } else if (valve.startsWith("inlet_2")) {
        return 1;
      }
      return null;
    };
  
    const getOutletID = (valve) => {
      const parts = valve.split('_');
      return parts.length > 2 ? parseInt(parts[parts.length - 1]) : 0;
    };
    

  return (
    <svg
  width="400mm"
  height="297mm"
  viewBox="30 100 250 150" 
  version="1.1"
  id="svg1"
  style={{ height: '100%', display: "flex", justifyContent: "center", alignItems: "center" }}
  // xmlSpace="preserve"
  // sodipodi:docname="bitmap.svg"
  // inkscape:version="1.3.2 (091e20e, 2023-11-25, custom)"
  // inkscape:export-filename="bitmap.svg"
  // inkscape:export-xdpi={96}
  // inkscape:export-ydpi={96}
  // xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
  // xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
  // xmlnsXlink="http://www.w3.org/1999/xlink"
  // xmlns="http://www.w3.org/2000/svg"
  // xmlns:svg="http://www.w3.org/2000/svg"
>
  <defs id="defs1">
    <linearGradient id="swatch17" >
      <stop
        style={{ stopColor: "#000000", stopOpacity: 1 }}
        offset={0}
        id="stop17"
      />
    </linearGradient>
    <linearGradient id="swatch16" >
      <stop
        style={{ stopColor: "#000000", stopOpacity: 1 }}
        offset={0}
        id="stop16"
      />
    </linearGradient>
    <linearGradient id="swatch15" >
      <stop
        style={{ stopColor: "#000000", stopOpacity: 1 }}
        offset={0}
        id="stop15"
      />
    </linearGradient>
    <linearGradient id="swatch13" >
      <stop
        style={{ stopColor: "#000000", stopOpacity: 1 }}
        offset={0}
        id="stop13"
      />
    </linearGradient>
    <linearGradient id="swatch12" >
      <stop
        style={{ stopColor: "#000000", stopOpacity: 1 }}
        offset={0}
        id="stop12"
      />
    </linearGradient>
    <linearGradient id="swatch11" >
      <stop
        style={{ stopColor: "#000000", stopOpacity: 1 }}
        offset={0}
        id="stop11"
      />
    </linearGradient>
    <linearGradient id="swatch10" >
      <stop
        style={{ stopColor: "#000000", stopOpacity: 1 }}
        offset={0}
        id="stop10"
      />
    </linearGradient>
    <linearGradient id="swatch1" >
      <stop
        style={{ stopColor: "#000000", stopOpacity: 1 }}
        offset={0}
        id="stop9"
      />
    </linearGradient>
    <linearGradient id="swatch19" >
      <stop
        style={{ stopColor: "#000000", stopOpacity: 1 }}
        offset={0}
        id="stop19"
      />
    </linearGradient>
    <linearGradient id="swatch14" >
      <stop
        style={{ stopColor: "#000000", stopOpacity: 1 }}
        offset={0}
        id="stop14"
      />
    </linearGradient>
    <linearGradient
      
      xlinkHref="#swatch14"
      id="linearGradient24"
      gradientUnits="userSpaceOnUse"
      x1="112.26446"
      y1="3.885725"
      x2="183.97121"
      y2="3.885725"
      gradientTransform="matrix(0.9999912,0.00885755,-0.00542425,0.99996666,-7.8916709e-7,-2.8616844e-8)"
    />
    <linearGradient
      
      xlinkHref="#swatch14"
      id="linearGradient34"
      gradientUnits="userSpaceOnUse"
      gradientTransform="matrix(0.9999912,0.00885755,-0.00542425,0.99996666,-7.8330594e-7,-2.9968213e-8)"
      x1="112.26446"
      y1="3.885725"
      x2="183.97121"
      y2="3.885725"
    />
    <linearGradient
      
      xlinkHref="#swatch1"
      id="linearGradient9"
      x1="19.898886"
      y1="111.57401"
      x2="60.925316"
      y2="111.57401"
      gradientUnits="userSpaceOnUse"
    />
    <linearGradient
      
      xlinkHref="#swatch10"
      id="linearGradient10"
      x1="20.010809"
      y1="126.76883"
      x2="61.037235"
      y2="126.76883"
      gradientUnits="userSpaceOnUse"
    />
    <linearGradient
      
      xlinkHref="#swatch11"
      id="linearGradient11"
      x1="23.606632"
      y1="33.549549"
      x2="94.646088"
      y2="33.549549"
      gradientUnits="userSpaceOnUse"
    />
    <linearGradient
      
      xlinkHref="#swatch13"
      id="linearGradient13"
      x1="17.337894"
      y1="183.54143"
      x2="58.364323"
      y2="183.54143"
      gradientUnits="userSpaceOnUse"
    />
    <linearGradient
      
      xlinkHref="#swatch15"
      id="linearGradient15"
      x1="17.449818"
      y1="198.73625"
      x2="58.476246"
      y2="198.73625"
      gradientUnits="userSpaceOnUse"
    />
    <linearGradient
      
      xlinkHref="#swatch16"
      id="linearGradient16"
      x1="171.24785"
      y1="130.57857"
      x2="212.27428"
      y2="130.57857"
      gradientUnits="userSpaceOnUse"
    />
    <linearGradient
      
      xlinkHref="#swatch17"
      id="linearGradient17"
      x1="169.52809"
      y1="180.84787"
      x2="210.55452"
      y2="180.84787"
      gradientUnits="userSpaceOnUse"
    />
  </defs>
  <g  id="layer1">
    <g id="layer1-8" transform="translate(-0.23522236,28.463115)">
      <g
        id="g106"
        transform="matrix(0.03386667,0,0,-0.03386667,62.765176,108.49204)"
      >
        <path
          style={{ fill: "#7f7f7f" }}
          d="M 0,500 V 0 h 132 v 500 z"
          id="path99"
        />
        <path
          style={{ fill: "#999999" }}
          d="M 8,500 V 0 h 116 v 500 z"
          id="path100"
        />
        <path
          style={{ fill: "#b2b2b2" }}
          d="M 22,500 V 0 h 88 v 500 z"
          id="path101"
        />
        <path
          style={{ fill: "#cccccc" }}
          d="M 36,500 V 0 h 61 v 500 z"
          id="path102"
        />
        <path
          style={{ fill: "#e5e5e5" }}
          d="M 46,500 V 0 h 39 v 500 z"
          id="path103"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="M 58,500 V 0 h 16 v 500 z"
          id="path104"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 132,500 V 0"
          id="path105"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 0,0 V 500"
          id="path106"
        />
      </g>
      <g
        id="g357"
        transform="matrix(0.03386667,0,0,-0.03386667,90.620231,108.85379)"
      >
        <path
          style={{ fill: "#7f7f7f" }}
          d="M 0,500 V 0 h 132 v 500 z"
          id="path350"
        />
        <path
          style={{ fill: "#999999" }}
          d="M 8,500 V 0 h 116 v 500 z"
          id="path351"
        />
        <path
          style={{ fill: "#b2b2b2" }}
          d="M 22,500 V 0 h 88 v 500 z"
          id="path352"
        />
        <path
          style={{ fill: "#cccccc" }}
          d="M 36,500 V 0 h 61 v 500 z"
          id="path353"
        />
        <path
          style={{ fill: "#e5e5e5" }}
          d="M 46,500 V 0 h 39 v 500 z"
          id="path354"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="M 58,500 V 0 h 16 v 500 z"
          id="path355"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 132,500 V 0"
          id="path356"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 0,0 V 500"
          id="path357"
        />
      </g>
      <g
        id="g409"
        transform="matrix(0.03386667,0,0,-0.03386667,125.34862,108.85379)"
      >
        <path
          style={{ fill: "#7f7f7f" }}
          d="M 0,500 V 0 h 132 v 500 z"
          id="path402"
        />
        <path
          style={{ fill: "#999999" }}
          d="M 8,500 V 0 h 116 v 500 z"
          id="path403"
        />
        <path
          style={{ fill: "#b2b2b2" }}
          d="M 22,500 V 0 h 88 v 500 z"
          id="path404"
        />
        <path
          style={{ fill: "#cccccc" }}
          d="M 36,500 V 0 h 61 v 500 z"
          id="path405"
        />
        <path
          style={{ fill: "#e5e5e5" }}
          d="M 46,500 V 0 h 39 v 500 z"
          id="path406"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="M 58,500 V 0 h 16 v 500 z"
          id="path407"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 132,500 V 0"
          id="path408"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 0,0 V 500"
          id="path409"
        />
      </g>
      <g
        id="g461"
        transform="matrix(0.03386667,0,0,-0.03386667,160.077,108.85379)"
      >
        <path
          style={{ fill: "#7f7f7f" }}
          d="M 0,500 V 0 h 132 v 500 z"
          id="path454"
        />
        <path
          style={{ fill: "#999999" }}
          d="M 8,500 V 0 h 116 v 500 z"
          id="path455"
        />
        <path
          style={{ fill: "#b2b2b2" }}
          d="M 22,500 V 0 h 88 v 500 z"
          id="path456"
        />
        <path
          style={{ fill: "#cccccc" }}
          d="M 36,500 V 0 h 61 v 500 z"
          id="path457"
        />
        <path
          style={{ fill: "#e5e5e5" }}
          d="M 46,500 V 0 h 39 v 500 z"
          id="path458"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="M 58,500 V 0 h 16 v 500 z"
          id="path459"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 132,500 V 0"
          id="path460"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 0,0 V 500"
          id="path461"
        />
      </g>
      <g
        id="inletID_1"
        transform="matrix(0.01694947,0,0,-0.01694947,39.531684,151.88516)"
      >
        <path
          style={{ fill: "#fcfcfc" }}
          d="m 457,82 h 84 v 286 h -84 z"
          id="path1"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="m 473,82 h 52 v 286 h -52 z"
          id="path2"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="m 493,82 h 12 v 286 h -12 z"
          id="path3"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="m 457,82 h 84 V 368 H 457 V 82"
          id="path4"
        />
        <path
          style={{ fill: "#656565" }}
          d="M 179,555 H 817 V 888 H 179 Z"
          id="path5"
        />
        <path
          style={{ fill: "#7b7b7b" }}
          d="M 180,603 H 818 V 838 H 180 Z"
          id="path6"
        />
        <path
          style={{ fill: "#939393" }}
          d="M 180,652 H 818 V 789 H 180 Z"
          id="path7"
        />
        <path
          style={{ fill: "#a9a9a9" }}
          d="m 179,700 h 638 v 41 H 179 Z"
          id="path8"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 179,888 H 817"
          id="path9"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 817,555 H 179"
          id="path10"
        />
        <path
          style={{ fill: "#656565" }}
          d="M 972,441 H 815 v 556 h 157 z"
          id="path11"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="m 972,441 h -78 v 556 h 78 V 441"
          id="path12"
        />
        <path
          style={{ fill: "#434343" }}
          d="m 338,525 v 35 l 1,10 2,9 5,8 6,8 7,8 9,6 10,6 11,6 12,5 13,4 13,3 15,3 14,2 15,2 16,1 h 15 15 l 16,-1 15,-2 14,-2 15,-3 13,-3 13,-4 12,-5 11,-6 10,-6 9,-6 7,-8 6,-8 5,-8 2,-9 1,-10 v -35 z"
          id="path13"
        />
        <path
          style={{ fill: "#4f4f4f" }}
          d="m 381,525 v 34 10 l 2,9 4,9 4,8 6,7 6,7 8,6 8,5 9,5 9,4 10,3 11,3 11,2 11,2 11,1 h 12 11 l 12,-1 11,-2 11,-2 10,-3 10,-3 10,-4 9,-5 8,-5 7,-6 7,-7 6,-7 4,-8 3,-9 2,-9 1,-10 v -34 z"
          id="path14"
        />
        <path
          style={{ fill: "#595959" }}
          d="m 423,525 v 34 l 2,19 5,17 8,14 10,11 12,9 14,6 14,4 15,1 15,-1 14,-4 14,-6 12,-9 10,-11 8,-14 5,-17 2,-19 v -34 z"
          id="path15"
        />
        <path
          style={{ fill: "#656565" }}
          d="m 465,525 v 35 l 1,19 2,16 4,14 4,12 6,9 6,6 7,4 7,1 7,-1 7,-4 6,-6 6,-9 4,-12 4,-14 2,-16 1,-19 v -35 z"
          id="path16"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="m 338,525 v 35 l 1,10 2,9 5,8 6,8 7,8 9,6 10,6 11,6 12,5 13,4 13,3 15,3 14,2 15,2 16,1 h 15 15 l 16,-1 15,-2 14,-2 15,-3 13,-3 13,-4 12,-5 11,-6 10,-6 9,-6 7,-8 6,-8 5,-8 2,-9 1,-10 V 525 H 338"
          id="path17"
        />
        <path
          style={{ fill: "#656565" }}
          d="M 223,368 V 525 H 779 V 368 Z"
          id="path18"
        />
        <path
          style={{ fill: "#7b7b7b" }}
          d="M 305,368 V 524 H 697 V 368 Z"
          id="path19"
        />
        <path
          style={{ fill: "#939393" }}
          d="M 386,368 V 524 H 616 V 368 Z"
          id="path20"
        />
        <path
          style={{ fill: "#a9a9a9" }}
          d="m 467,368 v 157 h 68 V 368 Z"
          id="path21"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="m 223,368 v 79 H 779 V 368 H 223"
          id="path22"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="m 223,447 v 78 H 779 V 447 H 223"
          id="path23"
        />
        <path
          style={{ fill: "#000000" }}
          d="M 98,0 H 900 V 82 H 98 Z"
          id="path24"
        />
        <path
          style={{ fill: "#fcfcfc" }}
          d="M 98,15 H 900 V 68 H 98 Z"
          id="path25"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="M 98,34 H 900 V 50 H 98 Z"
          id="path26"
        />
        <path
          style={{ fill: "#b1b1b1" }}
          d="M 213,0 H 785 V 82 H 213 Z"
          id="path27"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="M 213,15 H 785 V 68 H 213 Z"
          id="path28"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="M 213,34 H 785 V 50 H 213 Z"
          id="path29"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="M 364,0 H 634 V 82 H 364 Z"
          id="path30"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="M 364,15 H 634 V 68 H 364 Z"
          id="path31"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="M 364,34 H 634 V 50 H 364 Z"
          id="path32"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 98,0 H 900 V 82 H 98 V 0"
          id="path33"
        />
        <path
          style={{ fill: "#656565", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 374,368 V 124 H 624 V 368 H 588 V 161 H 410 v 207 z"
          id="path34"
        />
        <path
          style={{ fill: "#656565" }}
          d="M 181,441 H 26 v 556 h 155 z"
          id="path35"
        />
        <path
          style={{ fill: "#656565" }}
          d="m 972,884 27,10 v 68 l -27,10 z"
          id="path36"
        />
        <path
          style={{ fill: "#656565" }}
          d="m 815,884 -24,10 v 68 l 24,10 z"
          id="path37"
        />
        <path
          style={{ fill: "#656565", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="m 815,676 -24,10 v 69 l 24,10 z"
          id="path38"
        />
        <path
          style={{ fill: "#656565" }}
          d="m 972,676 27,10 v 69 l -27,10 z"
          id="path39"
        />
        <path
          style={{ fill: "#656565" }}
          d="m 972,467 27,12 v 66 l -27,10 z"
          id="path40"
        />
        <path
          style={{ fill: "#656565", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="m 815,467 -24,12 v 66 l 24,10 z"
          id="path41"
        />
        <path
          style={{ fill: "#878787" }}
          d="m 973,896 26,8 v 48 l -26,7 z"
          id="path42"
        />
        <path
          style={{ fill: "#878787" }}
          d="m 816,896 -26,8 v 48 l 26,7 z"
          id="path43"
        />
        <path
          style={{ fill: "#878787" }}
          d="m 816,688 -26,7 v 48 l 26,8 z"
          id="path44"
        />
        <path
          style={{ fill: "#878787" }}
          d="m 973,688 26,7 v 48 l -26,8 z"
          id="path45"
        />
        <path
          style={{ fill: "#878787" }}
          d="m 973,480 26,8 v 47 l -26,8 z"
          id="path46"
        />
        <path
          style={{ fill: "#878787" }}
          d="m 816,480 -26,8 v 47 l 26,8 z"
          id="path47"
        />
        <path
          style={{ fill: "#a9a9a9" }}
          d="m 972,493 27,4 v 28 l -27,4 z"
          id="path48"
        />
        <path
          style={{ fill: "#a9a9a9" }}
          d="m 815,493 -24,4 v 28 l 24,4 z"
          id="path49"
        />
        <path
          style={{ fill: "#a9a9a9" }}
          d="m 815,702 -24,4 v 29 l 24,4 z"
          id="path50"
        />
        <path
          style={{ fill: "#a9a9a9" }}
          d="m 972,702 27,4 v 29 l -27,4 z"
          id="path51"
        />
        <path
          style={{ fill: "#a9a9a9" }}
          d="m 972,910 27,4 v 28 l -27,4 z"
          id="path52"
        />
        <path
          style={{ fill: "#a9a9a9" }}
          d="m 815,910 -24,4 v 28 l 24,4 z"
          id="path53"
        />
        <path
          style={{ fill: "#7b7b7b" }}
          d="M 973,523 H 816 v 393 h 157 z"
          id="path54"
        />
        <path
          style={{ fill: "#939393" }}
          d="M 973,605 H 816 v 230 h 157 z"
          id="path55"
        />
        <path
          style={{ fill: "#a9a9a9" }}
          d="M 972,686 H 815 v 69 h 157 z"
          id="path56"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="m 815,884 -24,10 v 68 l 24,10 v -88"
          id="path57"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="m 972,676 27,10 v 69 l -27,10 v -89"
          id="path58"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="m 972,467 27,12 v 66 l -27,10 v -88"
          id="path59"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="m 972,884 27,10 v 68 l -27,10 v -88"
          id="path60"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="m 894,441 h -79 v 556 h 79 V 441"
          id="path61"
        />
        <path
          style={{ fill: "#656565" }}
          d="m 183,884 26,10 v 68 l -26,10 z"
          id="path62"
        />
        <path
          style={{ fill: "#656565", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 26,884 0,894 v 68 l 26,10 z"
          id="path63"
        />
        <path
          style={{ fill: "#656565" }}
          d="M 26,676 0,686 v 69 l 26,10 z"
          id="path64"
        />
        <path
          style={{ fill: "#656565", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="m 183,676 26,10 v 69 l -26,10 z"
          id="path65"
        />
        <path
          style={{ fill: "#656565" }}
          d="m 183,467 26,12 v 66 l -26,10 z"
          id="path66"
        />
        <path
          style={{ fill: "#656565" }}
          d="M 26,467 0,479 v 66 l 26,10 z"
          id="path67"
        />
        <path
          style={{ fill: "#878787" }}
          d="m 183,896 26,8 v 48 l -26,7 z"
          id="path68"
        />
        <path
          style={{ fill: "#878787" }}
          d="m 26,896 -26,8 v 48 l 26,7 z"
          id="path69"
        />
        <path
          style={{ fill: "#878787" }}
          d="m 26,688 -4,1 -3,1 -3,1 -4,1 -3,1 -3,1 -4,1 -2,1 v 6 5 6 6 6 6 6 6 l 2,1 h 4 l 3,2 3,1 4,1 3,1 3,1 4,1 v -4 -5 -4 -4 -4 -3 -4 -4 -4 -4 -4 -4 -3 -4 -4 z"
          id="path70"
        />
        <path
          style={{ fill: "#878787" }}
          d="m 183,688 26,7 v 48 l -26,8 z"
          id="path71"
        />
        <path
          style={{ fill: "#878787" }}
          d="m 183,480 26,8 v 47 l -26,8 z"
          id="path72"
        />
        <path
          style={{ fill: "#878787" }}
          d="m 26,481 -4,1 h -3 l -3,1 -4,1 -3,1 H 6 l -4,1 -2,1 v 6 6 6 6 6 6 6 6 l 2,1 4,1 3,1 3,1 4,1 3,1 3,1 4,1 v -4 -4 -4 -4 -4 -4 -4 -4 -3 -4 -4 -4 -4 -3 -4 z"
          id="path73"
        />
        <path
          style={{ fill: "#a9a9a9" }}
          d="m 183,910 26,4 v 28 l -26,4 z"
          id="path74"
        />
        <path
          style={{ fill: "#a9a9a9" }}
          d="m 26,910 -26,4 v 28 l 26,4 z"
          id="path75"
        />
        <path
          style={{ fill: "#a9a9a9" }}
          d="m 26,702 -26,4 v 29 l 26,4 z"
          id="path76"
        />
        <path
          style={{ fill: "#a9a9a9" }}
          d="m 183,702 26,4 v 29 l -26,4 z"
          id="path77"
        />
        <path
          style={{ fill: "#a9a9a9" }}
          d="m 183,493 26,4 v 28 l -26,4 z"
          id="path78"
        />
        <path
          style={{ fill: "#a9a9a9" }}
          d="m 26,493 -26,4 v 28 l 26,4 z"
          id="path79"
        />
        <path
          style={{ fill: "#7b7b7b" }}
          d="M 182,523 H 26 v 393 h 156 z"
          id="path80"
        />
        <path
          style={{ fill: "#939393" }}
          d="M 182,605 H 26 v 230 h 156 z"
          id="path81"
        />
        <path
          style={{ fill: "#a9a9a9" }}
          d="M 183,686 H 26 v 69 h 157 z"
          id="path82"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="m 183,884 26,10 v 68 l -26,10 v -88"
          id="path83"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 26,676 0,686 v 69 l 26,10 v -89"
          id="path84"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="m 183,467 26,12 v 66 l -26,10 v -88"
          id="path85"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 26,467 0,479 v 66 l 26,10 v -88"
          id="path86"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="m 181,441 h -77 v 556 h 77 V 441"
          id="path87"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 104,441 H 26 v 556 h 78 V 441"
          id="path88"
        />
      </g>
      <g id="inlet_pipe_1" transform="matrix(1,0,0,-1,11.641668,243.46081)">
        <path
          style={{ fill: "#7f7f7f", strokeWidth: "0.0338667" }}
          d="M 51.123508,122.60004 V 105.6667 h 4.4704 v 16.93334 z"
          id="path1-2"
        />
        <path
          style={{ fill: "#999999", strokeWidth: "0.0338667" }}
          d="M 51.394441,122.60004 V 105.6667 h 3.928534 v 16.93334 z"
          id="path2-5"
        />
        <path
          style={{ fill: "#b2b2b2", strokeWidth: "0.0338667" }}
          d="M 51.868575,122.60004 V 105.6667 h 2.980267 v 16.93334 z"
          id="path3-6"
        />
        <path
          style={{ fill: "#cccccc", strokeWidth: "0.0338667" }}
          d="M 52.342708,122.60004 V 105.6667 h 2.065867 v 16.93334 z"
          id="path4-4"
        />
        <path
          style={{ fill: "#e5e5e5", strokeWidth: "0.0338667" }}
          d="M 52.681375,122.60004 V 105.6667 h 1.3208 v 16.93334 z"
          id="path5-8"
        />
        <path
          style={{ fill: "#ffffff", strokeWidth: "0.0338667" }}
          d="M 53.087775,122.60004 V 105.6667 h 0.541867 v 16.93334 z"
          id="path6-5"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: "0.0677333" }}
          d="M 55.593908,122.60004 V 105.6667"
          id="path7-6"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: "0.0677333" }}
          d="m 51.123508,105.6667 v 16.93334"
          id="path8-3"
        />
        <path
          style={{ fill: "#7f7f7f", strokeWidth: "0.0338667" }}
          d="m 78.978563,122.23829 v -16.93334 h 4.4704 v 16.93334 z"
          id="path322"
        />
        <path
          style={{ fill: "#999999", strokeWidth: "0.0338667" }}
          d="m 79.249496,122.23829 v -16.93334 h 3.928534 v 16.93334 z"
          id="path323"
        />
        <path
          style={{ fill: "#b2b2b2", strokeWidth: "0.0338667" }}
          d="m 79.72363,122.23829 v -16.93334 h 2.980267 v 16.93334 z"
          id="path324"
        />
        <path
          style={{ fill: "#cccccc", strokeWidth: "0.0338667" }}
          d="m 80.197763,122.23829 v -16.93334 h 2.065867 v 16.93334 z"
          id="path325"
        />
        <path
          style={{ fill: "#e5e5e5", strokeWidth: "0.0338667" }}
          d="m 80.53643,122.23829 v -16.93334 h 1.3208 v 16.93334 z"
          id="path326"
        />
        <path
          style={{ fill: "#ffffff", strokeWidth: "0.0338667" }}
          d="m 80.94283,122.23829 v -16.93334 h 0.541867 v 16.93334 z"
          id="path327"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: "0.0677333" }}
          d="M 83.448963,122.23829 V 105.30495"
          id="path328"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: "0.0677333" }}
          d="m 78.978563,105.30495 v 16.93334"
          id="path329"
        />
        <path
          style={{ fill: "#7f7f7f", strokeWidth: "0.0338667" }}
          d="m 113.70695,122.23829 v -16.93334 h 4.4704 v 16.93334 z"
          id="path374"
        />
        <path
          style={{ fill: "#999999", strokeWidth: "0.0338667" }}
          d="m 113.97788,122.23829 v -16.93334 h 3.92854 v 16.93334 z"
          id="path375"
        />
        <path
          style={{ fill: "#b2b2b2", strokeWidth: "0.0338667" }}
          d="m 114.45202,122.23829 v -16.93334 h 2.98026 v 16.93334 z"
          id="path376"
        />
        <path
          style={{ fill: "#cccccc", strokeWidth: "0.0338667" }}
          d="m 114.92615,122.23829 v -16.93334 h 2.06587 v 16.93334 z"
          id="path377"
        />
        <path
          style={{ fill: "#e5e5e5", strokeWidth: "0.0338667" }}
          d="m 115.26482,122.23829 v -16.93334 h 1.3208 v 16.93334 z"
          id="path378"
        />
        <path
          style={{ fill: "#ffffff", strokeWidth: "0.0338667" }}
          d="m 115.67122,122.23829 v -16.93334 h 0.54186 v 16.93334 z"
          id="path379"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: "0.0677333" }}
          d="M 118.17735,122.23829 V 105.30495"
          id="path380"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: "0.0677333" }}
          d="m 113.70695,105.30495 v 16.93334"
          id="path381"
        />
        <path
          style={{ fill: "#7f7f7f", strokeWidth: "0.0338667" }}
          d="m 148.43533,122.23829 v -16.93334 h 4.4704 v 16.93334 z"
          id="path426"
        />
        <path
          style={{ fill: "#999999", strokeWidth: "0.0338667" }}
          d="m 148.70626,122.23829 v -16.93334 h 3.92854 v 16.93334 z"
          id="path427"
        />
        <path
          style={{ fill: "#b2b2b2", strokeWidth: "0.0338667" }}
          d="m 149.1804,122.23829 v -16.93334 h 2.98026 v 16.93334 z"
          id="path428"
        />
        <path
          style={{ fill: "#cccccc", strokeWidth: "0.0338667" }}
          d="m 149.65453,122.23829 v -16.93334 h 2.06587 v 16.93334 z"
          id="path429"
        />
        <path
          style={{ fill: "#e5e5e5", strokeWidth: "0.0338667" }}
          d="m 149.9932,122.23829 v -16.93334 h 1.3208 v 16.93334 z"
          id="path430"
        />
        <path
          style={{ fill: "#ffffff", strokeWidth: "0.0338667" }}
          d="m 150.3996,122.23829 v -16.93334 h 0.54186 v 16.93334 z"
          id="path431"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: "0.0677333" }}
          d="M 152.90573,122.23829 V 105.30495"
          id="path432"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: "0.0677333" }}
          d="m 148.43533,105.30495 v 16.93334"
          id="path433"
        />
        <g id="g4" transform="translate(-1.0852619,-37.260658)">
          <g
            id="g2"
            transform="matrix(0.0338603,0,0,0.0338603,46.340649,138.65145)"
          >
            <path
              style={{ fill: "#7f7f7f" }}
              d="M 0,0 H 500 V 133 H 0 Z"
              id="path1-8"
            />
            <path
              style={{ fill: "#999999" }}
              d="M 0,9 H 500 V 125 H 0 Z"
              id="path2-7"
            />
            <path
              style={{ fill: "#b2b2b2" }}
              d="m 0,23 h 500 v 88 H 0 Z"
              id="path3-5"
            />
            <path
              style={{ fill: "#cccccc" }}
              d="M 0,36 H 500 V 97 H 0 Z"
              id="path4-1"
            />
            <path
              style={{ fill: "#e5e5e5" }}
              d="M 0,47 H 500 V 86 H 0 Z"
              id="path5-2"
            />
            <path
              style={{ fill: "#ffffff" }}
              d="M 0,59 H 500 V 75 H 0 Z"
              id="path6-3"
            />
            <path
              style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
              d="M 0,133 H 500"
              id="path7-4"
            />
            <path
              style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
              d="M 500,0 H 0"
              id="path8-0"
            />
          </g>
        </g>
        <g id="g97" transform="translate(15.749761,-37.260658)">
          <g
            id="g96"
            transform="matrix(0.0338603,0,0,0.0338603,46.340649,138.65145)"
          >
            <path
              style={{ fill: "#7f7f7f" }}
              d="M 0,0 H 500 V 133 H 0 Z"
              id="path89"
            />
            <path
              style={{ fill: "#999999" }}
              d="M 0,9 H 500 V 125 H 0 Z"
              id="path90"
            />
            <path
              style={{ fill: "#b2b2b2" }}
              d="m 0,23 h 500 v 88 H 0 Z"
              id="path91"
            />
            <path
              style={{ fill: "#cccccc" }}
              d="M 0,36 H 500 V 97 H 0 Z"
              id="path92"
            />
            <path
              style={{ fill: "#e5e5e5" }}
              d="M 0,47 H 500 V 86 H 0 Z"
              id="path93"
            />
            <path
              style={{ fill: "#ffffff" }}
              d="M 0,59 H 500 V 75 H 0 Z"
              id="path94"
            />
            <path
              style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
              d="M 0,133 H 500"
              id="path95"
            />
            <path
              style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
              d="M 500,0 H 0"
              id="path96"
            />
          </g>
        </g>
        <g id="g322" transform="translate(33.113951,-37.260658)">
          <g
            id="g321"
            transform="matrix(0.0338603,0,0,0.0338603,46.340649,138.65145)"
          >
            <path
              style={{ fill: "#7f7f7f" }}
              d="M 0,0 H 500 V 133 H 0 Z"
              id="path314"
            />
            <path
              style={{ fill: "#999999" }}
              d="M 0,9 H 500 V 125 H 0 Z"
              id="path315"
            />
            <path
              style={{ fill: "#b2b2b2" }}
              d="m 0,23 h 500 v 88 H 0 Z"
              id="path316"
            />
            <path
              style={{ fill: "#cccccc" }}
              d="M 0,36 H 500 V 97 H 0 Z"
              id="path317"
            />
            <path
              style={{ fill: "#e5e5e5" }}
              d="M 0,47 H 500 V 86 H 0 Z"
              id="path318"
            />
            <path
              style={{ fill: "#ffffff" }}
              d="M 0,59 H 500 V 75 H 0 Z"
              id="path319"
            />
            <path
              style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
              d="M 0,133 H 500"
              id="path320"
            />
            <path
              style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
              d="M 500,0 H 0"
              id="path321"
            />
          </g>
        </g>
        <g id="g366" transform="translate(50.310728,-37.260658)">
          <g
            id="g365"
            transform="matrix(0.0338603,0,0,0.0338603,46.340649,138.65145)"
          >
            <path
              style={{ fill: "#7f7f7f" }}
              d="M 0,0 H 500 V 133 H 0 Z"
              id="path358"
            />
            <path
              style={{ fill: "#999999" }}
              d="M 0,9 H 500 V 125 H 0 Z"
              id="path359"
            />
            <path
              style={{ fill: "#b2b2b2" }}
              d="m 0,23 h 500 v 88 H 0 Z"
              id="path360"
            />
            <path
              style={{ fill: "#cccccc" }}
              d="M 0,36 H 500 V 97 H 0 Z"
              id="path361"
            />
            <path
              style={{ fill: "#e5e5e5" }}
              d="M 0,47 H 500 V 86 H 0 Z"
              id="path362"
            />
            <path
              style={{ fill: "#ffffff" }}
              d="M 0,59 H 500 V 75 H 0 Z"
              id="path363"
            />
            <path
              style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
              d="M 0,133 H 500"
              id="path364"
            />
            <path
              style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
              d="M 500,0 H 0"
              id="path365"
            />
          </g>
        </g>
        <g id="g374" transform="translate(67.842331,-37.260658)">
          <g
            id="g373"
            transform="matrix(0.0338603,0,0,0.0338603,46.340649,138.65145)"
          >
            <path
              style={{ fill: "#7f7f7f" }}
              d="M 0,0 H 500 V 133 H 0 Z"
              id="path366"
            />
            <path
              style={{ fill: "#999999" }}
              d="M 0,9 H 500 V 125 H 0 Z"
              id="path367"
            />
            <path
              style={{ fill: "#b2b2b2" }}
              d="m 0,23 h 500 v 88 H 0 Z"
              id="path368"
            />
            <path
              style={{ fill: "#cccccc" }}
              d="M 0,36 H 500 V 97 H 0 Z"
              id="path369"
            />
            <path
              style={{ fill: "#e5e5e5" }}
              d="M 0,47 H 500 V 86 H 0 Z"
              id="path370"
            />
            <path
              style={{ fill: "#ffffff" }}
              d="M 0,59 H 500 V 75 H 0 Z"
              id="path371"
            />
            <path
              style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
              d="M 0,133 H 500"
              id="path372"
            />
            <path
              style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
              d="M 500,0 H 0"
              id="path373"
            />
          </g>
        </g>
        <g id="g418" transform="translate(85.039108,-37.260658)">
          <g
            id="g417"
            transform="matrix(0.0338603,0,0,0.0338603,46.340649,138.65145)"
          >
            <path
              style={{ fill: "#7f7f7f" }}
              d="M 0,0 H 500 V 133 H 0 Z"
              id="path410"
            />
            <path
              style={{ fill: "#999999" }}
              d="M 0,9 H 500 V 125 H 0 Z"
              id="path411"
            />
            <path
              style={{ fill: "#b2b2b2" }}
              d="m 0,23 h 500 v 88 H 0 Z"
              id="path412"
            />
            <path
              style={{ fill: "#cccccc" }}
              d="M 0,36 H 500 V 97 H 0 Z"
              id="path413"
            />
            <path
              style={{ fill: "#e5e5e5" }}
              d="M 0,47 H 500 V 86 H 0 Z"
              id="path414"
            />
            <path
              style={{ fill: "#ffffff" }}
              d="M 0,59 H 500 V 75 H 0 Z"
              id="path415"
            />
            <path
              style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
              d="M 0,133 H 500"
              id="path416"
            />
            <path
              style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
              d="M 500,0 H 0"
              id="path417"
            />
          </g>
        </g>
        <g id="g426" transform="translate(102.57071,-37.260658)">
          <g
            id="g425"
            transform="matrix(0.0338603,0,0,0.0338603,46.340649,138.65145)"
          >
            <path
              style={{ fill: "#7f7f7f" }}
              d="M 0,0 H 500 V 133 H 0 Z"
              id="path418"
            />
            <path
              style={{ fill: "#999999" }}
              d="M 0,9 H 500 V 125 H 0 Z"
              id="path419"
            />
            <path
              style={{ fill: "#b2b2b2" }}
              d="m 0,23 h 500 v 88 H 0 Z"
              id="path420"
            />
            <path
              style={{ fill: "#cccccc" }}
              d="M 0,36 H 500 V 97 H 0 Z"
              id="path421"
            />
            <path
              style={{ fill: "#e5e5e5" }}
              d="M 0,47 H 500 V 86 H 0 Z"
              id="path422"
            />
            <path
              style={{ fill: "#ffffff" }}
              d="M 0,59 H 500 V 75 H 0 Z"
              id="path423"
            />
            <path
              style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
              d="M 0,133 H 500"
              id="path424"
            />
            <path
              style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
              d="M 500,0 H 0"
              id="path425"
            />
          </g>
        </g>
        <g id="g470" transform="translate(119.76749,-37.260658)">
          <g
            id="g469"
            transform="matrix(0.0338603,0,0,0.0338603,46.340649,138.65145)"
          >
            <path
              style={{ fill: "#7f7f7f" }}
              d="M 0,0 H 500 V 133 H 0 Z"
              id="path462"
            />
            <path
              style={{ fill: "#999999" }}
              d="M 0,9 H 500 V 125 H 0 Z"
              id="path463"
            />
            <path
              style={{ fill: "#b2b2b2" }}
              d="m 0,23 h 500 v 88 H 0 Z"
              id="path464"
            />
            <path
              style={{ fill: "#cccccc" }}
              d="M 0,36 H 500 V 97 H 0 Z"
              id="path465"
            />
            <path
              style={{ fill: "#e5e5e5" }}
              d="M 0,47 H 500 V 86 H 0 Z"
              id="path466"
            />
            <path
              style={{ fill: "#ffffff" }}
              d="M 0,59 H 500 V 75 H 0 Z"
              id="path467"
            />
            <path
              style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
              d="M 0,133 H 500"
              id="path468"
            />
            <path
              style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
              d="M 500,0 H 0"
              id="path469"
            />
          </g>
        </g>
      </g>
      <g
        id="inlet_1_outlet_1"
        transform="matrix(0,-0.03867649,-0.04019195,0,81.670084,121.27758)"
      >
        <path
          style={{ fill: "#7f7f7f" }}
          d="M 185,131 V 413 H 152 V 131 Z"
          id="path1369"
        />
        <path
          style={{ fill: "#b2b2b2" }}
          d="M 182,131 V 413 H 155 V 131 Z"
          id="path1370"
        />
        <path
          style={{ fill: "#e5e5e5" }}
          d="M 178,131 V 413 H 160 V 131 Z"
          id="path1371"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="m 172,131 v 282 h -6 V 131 Z"
          id="path1372"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 152,131 V 413"
          id="path1373"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 185,413 V 131"
          id="path1374"
        />
        <path
          style={{ fill: "#7f7f7f" }}
          d="M 166,135 H 31 l 3,-27 8,-25 12,-23 16,-20 20,-17 23,-12 26,-8 27,-3 27,3 25,8 23,12 20,17 17,20 12,23 8,25 3,27 z"
          id="path1375"
        />
        <path
          style={{ fill: "#999999" }}
          d="M 166,135 H 43 l 2,-27 8,-25 11,-23 15,-20 18,-17 21,-12 23,-8 25,-3 24,3 23,8 21,12 18,17 15,20 11,23 8,25 2,27 z"
          id="path1376"
        />
        <path
          style={{ fill: "#b2b2b2" }}
          d="M 166,135 H 69 l 2,-27 6,-25 8,-23 12,-20 15,-17 16,-12 18,-8 20,-3 19,3 19,8 16,12 14,17 12,20 9,23 6,25 2,27 z"
          id="path1377"
        />
        <path
          style={{ fill: "#cccccc" }}
          d="M 166,135 H 94 l 1,-27 5,-25 6,-23 9,-20 11,-17 12,-12 13,-8 15,-3 14,3 14,8 12,12 10,17 9,20 6,23 5,25 1,27 z"
          id="path1378"
        />
        <path
          style={{ fill: "#e5e5e5" }}
          d="m 166,135 h -47 l 1,-27 3,-25 4,-23 6,-20 7,-17 8,-12 9,-8 9,-3 9,3 9,8 8,12 6,17 6,20 4,23 3,25 1,27 z"
          id="path1379"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 166,135 H 31 l 3,-27 8,-25 12,-23 16,-20 20,-17 23,-12 26,-8 27,-3 27,3 25,8 23,12 20,17 17,20 12,23 8,25 3,27 H 166"
          id="path1380"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="m 166,135 h -13 v -27 l 1,-25 1,-23 2,-20 2,-17 2,-12 2,-8 3,-3 3,3 2,8 2,12 2,17 1,20 1,23 1,25 v 27 z"
          id="path1381"
        />
        <path
          style={{ fill: "#7f7f7f" }}
          d="M 0,493 V 327 L 332,493 V 327 Z"
          id="path1382"
        />
        <path
          style={{ fill: "#999999" }}
          d="M 0,485 V 336 L 332,485 V 336 Z"
          id="path1383"
        />
        <path
          style={{ fill: "#b2b2b2" }}
          d="M 0,468 V 353 L 332,468 V 353 Z"
          id="path1384"
        />
        <path
          style={{ fill: "#cccccc" }}
          d="m 0,451 v -81 l 332,81 v -81 z"
          id="path1385"
        />
        <path
          style={{ fill: "#e5e5e5" }}
          d="m 0,438 v -55 l 332,55 v -55 z"
          id="path1386"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="m 0,421 v -21 l 332,21 v -21 z"
          id="path1387"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 0,493 V 327 L 332,493 V 327 L 0,493"
          id="path1388"
        />
      </g>
      <g
        id="inlet_1_outlet_pipe_1"
        transform="matrix(0.03386667,0,0,-0.03386667,62.765182,108.49204)"
      >
        <path
          style={{ fill: "#7f7f7f" }}
          d="M 0,500 V 0 h 132 v 500 z"
          id="path1389"
        />
        <path
          style={{ fill: "#999999" }}
          d="M 8,500 V 0 h 116 v 500 z"
          id="path1390"
        />
        <path
          style={{ fill: "#b2b2b2" }}
          d="M 22,500 V 0 h 88 v 500 z"
          id="path1391"
        />
        <path
          style={{ fill: "#cccccc" }}
          d="M 36,500 V 0 h 61 v 500 z"
          id="path1392"
        />
        <path
          style={{ fill: "#e5e5e5" }}
          d="M 46,500 V 0 h 39 v 500 z"
          id="path1393"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="M 58,500 V 0 h 16 v 500 z"
          id="path1394"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 132,500 V 0"
          id="path1395"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 0,0 V 500"
          id="path1396"
        />
      </g>
      <g
        id="inlet_1_outlet_2"
        transform="matrix(0,-0.03867649,-0.04019195,0,109.16345,122.00109)"
      >
        <path
          style={{ fill: "#7f7f7f" }}
          d="M 185,131 V 413 H 152 V 131 Z"
          id="path1397"
        />
        <path
          style={{ fill: "#b2b2b2" }}
          d="M 182,131 V 413 H 155 V 131 Z"
          id="path1398"
        />
        <path
          style={{ fill: "#e5e5e5" }}
          d="M 178,131 V 413 H 160 V 131 Z"
          id="path1399"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="m 172,131 v 282 h -6 V 131 Z"
          id="path1400"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 152,131 V 413"
          id="path1401"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 185,413 V 131"
          id="path1402"
        />
        <path
          style={{ fill: "#7f7f7f" }}
          d="M 166,135 H 31 l 3,-27 8,-25 12,-23 16,-20 20,-17 23,-12 26,-8 27,-3 27,3 25,8 23,12 20,17 17,20 12,23 8,25 3,27 z"
          id="path1403"
        />
        <path
          style={{ fill: "#999999" }}
          d="M 166,135 H 43 l 2,-27 8,-25 11,-23 15,-20 18,-17 21,-12 23,-8 25,-3 24,3 23,8 21,12 18,17 15,20 11,23 8,25 2,27 z"
          id="path1404"
        />
        <path
          style={{ fill: "#b2b2b2" }}
          d="M 166,135 H 69 l 2,-27 6,-25 8,-23 12,-20 15,-17 16,-12 18,-8 20,-3 19,3 19,8 16,12 14,17 12,20 9,23 6,25 2,27 z"
          id="path1405"
        />
        <path
          style={{ fill: "#cccccc" }}
          d="M 166,135 H 94 l 1,-27 5,-25 6,-23 9,-20 11,-17 12,-12 13,-8 15,-3 14,3 14,8 12,12 10,17 9,20 6,23 5,25 1,27 z"
          id="path1406"
        />
        <path
          style={{ fill: "#e5e5e5" }}
          d="m 166,135 h -47 l 1,-27 3,-25 4,-23 6,-20 7,-17 8,-12 9,-8 9,-3 9,3 9,8 8,12 6,17 6,20 4,23 3,25 1,27 z"
          id="path1407"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 166,135 H 31 l 3,-27 8,-25 12,-23 16,-20 20,-17 23,-12 26,-8 27,-3 27,3 25,8 23,12 20,17 17,20 12,23 8,25 3,27 H 166"
          id="path1408"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="m 166,135 h -13 v -27 l 1,-25 1,-23 2,-20 2,-17 2,-12 2,-8 3,-3 3,3 2,8 2,12 2,17 1,20 1,23 1,25 v 27 z"
          id="path1409"
        />
        <path
          style={{ fill: "#7f7f7f" }}
          d="M 0,493 V 327 L 332,493 V 327 Z"
          id="path1410"
        />
        <path
          style={{ fill: "#999999" }}
          d="M 0,485 V 336 L 332,485 V 336 Z"
          id="path1411"
        />
        <path
          style={{ fill: "#b2b2b2" }}
          d="M 0,468 V 353 L 332,468 V 353 Z"
          id="path1412"
        />
        <path
          style={{ fill: "#cccccc" }}
          d="m 0,451 v -81 l 332,81 v -81 z"
          id="path1413"
        />
        <path
          style={{ fill: "#e5e5e5" }}
          d="m 0,438 v -55 l 332,55 v -55 z"
          id="path1414"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="m 0,421 v -21 l 332,21 v -21 z"
          id="path1415"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 0,493 V 327 L 332,493 V 327 L 0,493"
          id="path1416"
        />
      </g>
      <g
        id="inlet_1_outlet_pipe_2"
        transform="matrix(0.03386667,0,0,-0.03386667,90.620237,108.85379)"
      >
        <path
          style={{ fill: "#7f7f7f" }}
          d="M 0,500 V 0 h 132 v 500 z"
          id="path1417"
        />
        <path
          style={{ fill: "#999999" }}
          d="M 8,500 V 0 h 116 v 500 z"
          id="path1418"
        />
        <path
          style={{ fill: "#b2b2b2" }}
          d="M 22,500 V 0 h 88 v 500 z"
          id="path1419"
        />
        <path
          style={{ fill: "#cccccc" }}
          d="M 36,500 V 0 h 61 v 500 z"
          id="path1420"
        />
        <path
          style={{ fill: "#e5e5e5" }}
          d="M 46,500 V 0 h 39 v 500 z"
          id="path1421"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="M 58,500 V 0 h 16 v 500 z"
          id="path1422"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 132,500 V 0"
          id="path1423"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 0,0 V 500"
          id="path1424"
        />
      </g>
      <g
        id="inlet_1_outlet_3"
        transform="matrix(0,-0.03867649,-0.04019195,0,143.89184,121.27758)"
      >
        <path
          style={{ fill: "#7f7f7f" }}
          d="M 185,131 V 413 H 152 V 131 Z"
          id="path1425"
        />
        <path
          style={{ fill: "#b2b2b2" }}
          d="M 182,131 V 413 H 155 V 131 Z"
          id="path1426"
        />
        <path
          style={{ fill: "#e5e5e5" }}
          d="M 178,131 V 413 H 160 V 131 Z"
          id="path1427"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="m 172,131 v 282 h -6 V 131 Z"
          id="path1428"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 152,131 V 413"
          id="path1429"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 185,413 V 131"
          id="path1430"
        />
        <path
          style={{ fill: "#7f7f7f" }}
          d="M 166,135 H 31 l 3,-27 8,-25 12,-23 16,-20 20,-17 23,-12 26,-8 27,-3 27,3 25,8 23,12 20,17 17,20 12,23 8,25 3,27 z"
          id="path1431"
        />
        <path
          style={{ fill: "#999999" }}
          d="M 166,135 H 43 l 2,-27 8,-25 11,-23 15,-20 18,-17 21,-12 23,-8 25,-3 24,3 23,8 21,12 18,17 15,20 11,23 8,25 2,27 z"
          id="path1432"
        />
        <path
          style={{ fill: "#b2b2b2" }}
          d="M 166,135 H 69 l 2,-27 6,-25 8,-23 12,-20 15,-17 16,-12 18,-8 20,-3 19,3 19,8 16,12 14,17 12,20 9,23 6,25 2,27 z"
          id="path1433"
        />
        <path
          style={{ fill: "#cccccc" }}
          d="M 166,135 H 94 l 1,-27 5,-25 6,-23 9,-20 11,-17 12,-12 13,-8 15,-3 14,3 14,8 12,12 10,17 9,20 6,23 5,25 1,27 z"
          id="path1434"
        />
        <path
          style={{ fill: "#e5e5e5" }}
          d="m 166,135 h -47 l 1,-27 3,-25 4,-23 6,-20 7,-17 8,-12 9,-8 9,-3 9,3 9,8 8,12 6,17 6,20 4,23 3,25 1,27 z"
          id="path1435"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 166,135 H 31 l 3,-27 8,-25 12,-23 16,-20 20,-17 23,-12 26,-8 27,-3 27,3 25,8 23,12 20,17 17,20 12,23 8,25 3,27 H 166"
          id="path1436"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="m 166,135 h -13 v -27 l 1,-25 1,-23 2,-20 2,-17 2,-12 2,-8 3,-3 3,3 2,8 2,12 2,17 1,20 1,23 1,25 v 27 z"
          id="path1437"
        />
        <path
          style={{ fill: "#7f7f7f" }}
          d="M 0,493 V 327 L 332,493 V 327 Z"
          id="path1438"
        />
        <path
          style={{ fill: "#999999" }}
          d="M 0,485 V 336 L 332,485 V 336 Z"
          id="path1439"
        />
        <path
          style={{ fill: "#b2b2b2" }}
          d="M 0,468 V 353 L 332,468 V 353 Z"
          id="path1440"
        />
        <path
          style={{ fill: "#cccccc" }}
          d="m 0,451 v -81 l 332,81 v -81 z"
          id="path1441"
        />
        <path
          style={{ fill: "#e5e5e5" }}
          d="m 0,438 v -55 l 332,55 v -55 z"
          id="path1442"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="m 0,421 v -21 l 332,21 v -21 z"
          id="path1443"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 0,493 V 327 L 332,493 V 327 L 0,493"
          id="path1444"
        />
      </g>
      <g
        id="inlet_1_outlet_pipe_3"
        transform="matrix(0.03386667,0,0,-0.03386667,125.34869,108.85379)"
      >
        <path
          style={{ fill: "#7f7f7f" }}
          d="M 0,500 V 0 h 132 v 500 z"
          id="path1445"
        />
        <path
          style={{ fill: "#999999" }}
          d="M 8,500 V 0 h 116 v 500 z"
          id="path1446"
        />
        <path
          style={{ fill: "#b2b2b2" }}
          d="M 22,500 V 0 h 88 v 500 z"
          id="path1447"
        />
        <path
          style={{ fill: "#cccccc" }}
          d="M 36,500 V 0 h 61 v 500 z"
          id="path1448"
        />
        <path
          style={{ fill: "#e5e5e5" }}
          d="M 46,500 V 0 h 39 v 500 z"
          id="path1449"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="M 58,500 V 0 h 16 v 500 z"
          id="path1450"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 132,500 V 0"
          id="path1451"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 0,0 V 500"
          id="path1452"
        />
      </g>
      <g
        id="inlet_1_outlet_pipe_4"
        transform="matrix(0.03386667,0,0,-0.03386667,160.07707,108.85379)"
      >
        <path
          style={{ fill: "#7f7f7f" }}
          d="M 0,500 V 0 h 132 v 500 z"
          id="path1473"
        />
        <path
          style={{ fill: "#999999" }}
          d="M 8,500 V 0 h 116 v 500 z"
          id="path1474"
        />
        <path
          style={{ fill: "#b2b2b2" }}
          d="M 22,500 V 0 h 88 v 500 z"
          id="path1475"
        />
        <path
          style={{ fill: "#cccccc" }}
          d="M 36,500 V 0 h 61 v 500 z"
          id="path1476"
        />
        <path
          style={{ fill: "#e5e5e5" }}
          d="M 46,500 V 0 h 39 v 500 z"
          id="path1477"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="M 58,500 V 0 h 16 v 500 z"
          id="path1478"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 132,500 V 0"
          id="path1479"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 0,0 V 500"
          id="path1480"
        />
      </g>
      <g
        id="g794"
        transform="matrix(0.03386667,0,0,0.03386667,63.488684,199.36097)"
      >
        <path
          style={{ fill: "#7f7f7f" }}
          d="M 0,500 V 0 h 132 v 500 z"
          id="path787"
        />
        <path
          style={{ fill: "#999999" }}
          d="M 8,500 V 0 h 116 v 500 z"
          id="path788"
        />
        <path
          style={{ fill: "#b2b2b2" }}
          d="M 22,500 V 0 h 88 v 500 z"
          id="path789"
        />
        <path
          style={{ fill: "#cccccc" }}
          d="M 36,500 V 0 h 61 v 500 z"
          id="path790"
        />
        <path
          style={{ fill: "#e5e5e5" }}
          d="M 46,500 V 0 h 39 v 500 z"
          id="path791"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="M 58,500 V 0 h 16 v 500 z"
          id="path792"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 132,500 V 0"
          id="path793"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 0,0 V 500"
          id="path794"
        />
      </g>
      <g
        id="g822"
        transform="matrix(0.03386667,0,0,0.03386667,91.343739,198.99923)"
      >
        <path
          style={{ fill: "#7f7f7f" }}
          d="M 0,500 V 0 h 132 v 500 z"
          id="path815"
        />
        <path
          style={{ fill: "#999999" }}
          d="M 8,500 V 0 h 116 v 500 z"
          id="path816"
        />
        <path
          style={{ fill: "#b2b2b2" }}
          d="M 22,500 V 0 h 88 v 500 z"
          id="path817"
        />
        <path
          style={{ fill: "#cccccc" }}
          d="M 36,500 V 0 h 61 v 500 z"
          id="path818"
        />
        <path
          style={{ fill: "#e5e5e5" }}
          d="M 46,500 V 0 h 39 v 500 z"
          id="path819"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="M 58,500 V 0 h 16 v 500 z"
          id="path820"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 132,500 V 0"
          id="path821"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 0,0 V 500"
          id="path822"
        />
      </g>
      <g
        id="g850"
        transform="matrix(0.03386667,0,0,0.03386667,126.07213,198.99923)"
      >
        <path
          style={{ fill: "#7f7f7f" }}
          d="M 0,500 V 0 h 132 v 500 z"
          id="path843"
        />
        <path
          style={{ fill: "#999999" }}
          d="M 8,500 V 0 h 116 v 500 z"
          id="path844"
        />
        <path
          style={{ fill: "#b2b2b2" }}
          d="M 22,500 V 0 h 88 v 500 z"
          id="path845"
        />
        <path
          style={{ fill: "#cccccc" }}
          d="M 36,500 V 0 h 61 v 500 z"
          id="path846"
        />
        <path
          style={{ fill: "#e5e5e5" }}
          d="M 46,500 V 0 h 39 v 500 z"
          id="path847"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="M 58,500 V 0 h 16 v 500 z"
          id="path848"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 132,500 V 0"
          id="path849"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 0,0 V 500"
          id="path850"
        />
      </g>
      <g
        id="g878"
        transform="matrix(0.03386667,0,0,0.03386667,160.80051,198.99923)"
      >
        <path
          style={{ fill: "#7f7f7f" }}
          d="M 0,500 V 0 h 132 v 500 z"
          id="path871"
        />
        <path
          style={{ fill: "#999999" }}
          d="M 8,500 V 0 h 116 v 500 z"
          id="path872"
        />
        <path
          style={{ fill: "#b2b2b2" }}
          d="M 22,500 V 0 h 88 v 500 z"
          id="path873"
        />
        <path
          style={{ fill: "#cccccc" }}
          d="M 36,500 V 0 h 61 v 500 z"
          id="path874"
        />
        <path
          style={{ fill: "#e5e5e5" }}
          d="M 46,500 V 0 h 39 v 500 z"
          id="path875"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="M 58,500 V 0 h 16 v 500 z"
          id="path876"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 132,500 V 0"
          id="path877"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 0,0 V 500"
          id="path878"
        />
      </g>
      <g
        id="inletID_2"
        transform="matrix(0.01694947,0,0,0.01694947,40.255195,155.96787)"
      >
        <path
          style={{ fill: "#fcfcfc" }}
          d="m 457,82 h 84 v 286 h -84 z"
          id="path879"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="m 473,82 h 52 v 286 h -52 z"
          id="path880"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="m 493,82 h 12 v 286 h -12 z"
          id="path881"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="m 457,82 h 84 V 368 H 457 V 82"
          id="path882"
        />
        <path
          style={{ fill: "#656565" }}
          d="M 179,555 H 817 V 888 H 179 Z"
          id="path883"
        />
        <path
          style={{ fill: "#7b7b7b" }}
          d="M 180,603 H 818 V 838 H 180 Z"
          id="path884"
        />
        <path
          style={{ fill: "#939393" }}
          d="M 180,652 H 818 V 789 H 180 Z"
          id="path885"
        />
        <path
          style={{ fill: "#a9a9a9" }}
          d="m 179,700 h 638 v 41 H 179 Z"
          id="path886"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 179,888 H 817"
          id="path887"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 817,555 H 179"
          id="path888"
        />
        <path
          style={{ fill: "#656565" }}
          d="M 972,441 H 815 v 556 h 157 z"
          id="path889"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="m 972,441 h -78 v 556 h 78 V 441"
          id="path890"
        />
        <path
          style={{ fill: "#434343" }}
          d="m 338,525 v 35 l 1,10 2,9 5,8 6,8 7,8 9,6 10,6 11,6 12,5 13,4 13,3 15,3 14,2 15,2 16,1 h 15 15 l 16,-1 15,-2 14,-2 15,-3 13,-3 13,-4 12,-5 11,-6 10,-6 9,-6 7,-8 6,-8 5,-8 2,-9 1,-10 v -35 z"
          id="path891"
        />
        <path
          style={{ fill: "#4f4f4f" }}
          d="m 381,525 v 34 10 l 2,9 4,9 4,8 6,7 6,7 8,6 8,5 9,5 9,4 10,3 11,3 11,2 11,2 11,1 h 12 11 l 12,-1 11,-2 11,-2 10,-3 10,-3 10,-4 9,-5 8,-5 7,-6 7,-7 6,-7 4,-8 3,-9 2,-9 1,-10 v -34 z"
          id="path892"
        />
        <path
          style={{ fill: "#595959" }}
          d="m 423,525 v 34 l 2,19 5,17 8,14 10,11 12,9 14,6 14,4 15,1 15,-1 14,-4 14,-6 12,-9 10,-11 8,-14 5,-17 2,-19 v -34 z"
          id="path893"
        />
        <path
          style={{ fill: "#656565" }}
          d="m 465,525 v 35 l 1,19 2,16 4,14 4,12 6,9 6,6 7,4 7,1 7,-1 7,-4 6,-6 6,-9 4,-12 4,-14 2,-16 1,-19 v -35 z"
          id="path894"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="m 338,525 v 35 l 1,10 2,9 5,8 6,8 7,8 9,6 10,6 11,6 12,5 13,4 13,3 15,3 14,2 15,2 16,1 h 15 15 l 16,-1 15,-2 14,-2 15,-3 13,-3 13,-4 12,-5 11,-6 10,-6 9,-6 7,-8 6,-8 5,-8 2,-9 1,-10 V 525 H 338"
          id="path895"
        />
        <path
          style={{ fill: "#656565" }}
          d="M 223,368 V 525 H 779 V 368 Z"
          id="path896"
        />
        <path
          style={{ fill: "#7b7b7b" }}
          d="M 305,368 V 524 H 697 V 368 Z"
          id="path897"
        />
        <path
          style={{ fill: "#939393" }}
          d="M 386,368 V 524 H 616 V 368 Z"
          id="path898"
        />
        <path
          style={{ fill: "#a9a9a9" }}
          d="m 467,368 v 157 h 68 V 368 Z"
          id="path899"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="m 223,368 v 79 H 779 V 368 H 223"
          id="path900"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="m 223,447 v 78 H 779 V 447 H 223"
          id="path901"
        />
        <path
          style={{ fill: "#000000" }}
          d="M 98,0 H 900 V 82 H 98 Z"
          id="path902"
        />
        <path
          style={{ fill: "#fcfcfc" }}
          d="M 98,15 H 900 V 68 H 98 Z"
          id="path903"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="M 98,34 H 900 V 50 H 98 Z"
          id="path904"
        />
        <path
          style={{ fill: "#b1b1b1" }}
          d="M 213,0 H 785 V 82 H 213 Z"
          id="path905"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="M 213,15 H 785 V 68 H 213 Z"
          id="path906"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="M 213,34 H 785 V 50 H 213 Z"
          id="path907"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="M 364,0 H 634 V 82 H 364 Z"
          id="path908"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="M 364,15 H 634 V 68 H 364 Z"
          id="path909"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="M 364,34 H 634 V 50 H 364 Z"
          id="path910"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 98,0 H 900 V 82 H 98 V 0"
          id="path911"
        />
        <path
          style={{ fill: "#656565", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 374,368 V 124 H 624 V 368 H 588 V 161 H 410 v 207 z"
          id="path912"
        />
        <path
          style={{ fill: "#656565" }}
          d="M 181,441 H 26 v 556 h 155 z"
          id="path913"
        />
        <path
          style={{ fill: "#656565" }}
          d="m 972,884 27,10 v 68 l -27,10 z"
          id="path914"
        />
        <path
          style={{ fill: "#656565" }}
          d="m 815,884 -24,10 v 68 l 24,10 z"
          id="path915"
        />
        <path
          style={{ fill: "#656565", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="m 815,676 -24,10 v 69 l 24,10 z"
          id="path916"
        />
        <path
          style={{ fill: "#656565" }}
          d="m 972,676 27,10 v 69 l -27,10 z"
          id="path917"
        />
        <path
          style={{ fill: "#656565" }}
          d="m 972,467 27,12 v 66 l -27,10 z"
          id="path918"
        />
        <path
          style={{ fill: "#656565", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="m 815,467 -24,12 v 66 l 24,10 z"
          id="path919"
        />
        <path
          style={{ fill: "#878787" }}
          d="m 973,896 26,8 v 48 l -26,7 z"
          id="path920"
        />
        <path
          style={{ fill: "#878787" }}
          d="m 816,896 -26,8 v 48 l 26,7 z"
          id="path921"
        />
        <path
          style={{ fill: "#878787" }}
          d="m 816,688 -26,7 v 48 l 26,8 z"
          id="path922"
        />
        <path
          style={{ fill: "#878787" }}
          d="m 973,688 26,7 v 48 l -26,8 z"
          id="path923"
        />
        <path
          style={{ fill: "#878787" }}
          d="m 973,480 26,8 v 47 l -26,8 z"
          id="path924"
        />
        <path
          style={{ fill: "#878787" }}
          d="m 816,480 -26,8 v 47 l 26,8 z"
          id="path925"
        />
        <path
          style={{ fill: "#a9a9a9" }}
          d="m 972,493 27,4 v 28 l -27,4 z"
          id="path926"
        />
        <path
          style={{ fill: "#a9a9a9" }}
          d="m 815,493 -24,4 v 28 l 24,4 z"
          id="path927"
        />
        <path
          style={{ fill: "#a9a9a9" }}
          d="m 815,702 -24,4 v 29 l 24,4 z"
          id="path928"
        />
        <path
          style={{ fill: "#a9a9a9" }}
          d="m 972,702 27,4 v 29 l -27,4 z"
          id="path929"
        />
        <path
          style={{ fill: "#a9a9a9" }}
          d="m 972,910 27,4 v 28 l -27,4 z"
          id="path930"
        />
        <path
          style={{ fill: "#a9a9a9" }}
          d="m 815,910 -24,4 v 28 l 24,4 z"
          id="path931"
        />
        <path
          style={{ fill: "#7b7b7b" }}
          d="M 973,523 H 816 v 393 h 157 z"
          id="path932"
        />
        <path
          style={{ fill: "#939393" }}
          d="M 973,605 H 816 v 230 h 157 z"
          id="path933"
        />
        <path
          style={{ fill: "#a9a9a9" }}
          d="M 972,686 H 815 v 69 h 157 z"
          id="path934"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="m 815,884 -24,10 v 68 l 24,10 v -88"
          id="path935"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="m 972,676 27,10 v 69 l -27,10 v -89"
          id="path936"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="m 972,467 27,12 v 66 l -27,10 v -88"
          id="path937"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="m 972,884 27,10 v 68 l -27,10 v -88"
          id="path938"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="m 894,441 h -79 v 556 h 79 V 441"
          id="path939"
        />
        <path
          style={{ fill: "#656565" }}
          d="m 183,884 26,10 v 68 l -26,10 z"
          id="path940"
        />
        <path
          style={{ fill: "#656565", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 26,884 0,894 v 68 l 26,10 z"
          id="path941"
        />
        <path
          style={{ fill: "#656565" }}
          d="M 26,676 0,686 v 69 l 26,10 z"
          id="path942"
        />
        <path
          style={{ fill: "#656565", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="m 183,676 26,10 v 69 l -26,10 z"
          id="path943"
        />
        <path
          style={{ fill: "#656565" }}
          d="m 183,467 26,12 v 66 l -26,10 z"
          id="path944"
        />
        <path
          style={{ fill: "#656565" }}
          d="M 26,467 0,479 v 66 l 26,10 z"
          id="path945"
        />
        <path
          style={{ fill: "#878787" }}
          d="m 183,896 26,8 v 48 l -26,7 z"
          id="path946"
        />
        <path
          style={{ fill: "#878787" }}
          d="m 26,896 -26,8 v 48 l 26,7 z"
          id="path947"
        />
        <path
          style={{ fill: "#878787" }}
          d="m 26,688 -4,1 -3,1 -3,1 -4,1 -3,1 -3,1 -4,1 -2,1 v 6 5 6 6 6 6 6 6 l 2,1 h 4 l 3,2 3,1 4,1 3,1 3,1 4,1 v -4 -5 -4 -4 -4 -3 -4 -4 -4 -4 -4 -4 -3 -4 -4 z"
          id="path948"
        />
        <path
          style={{ fill: "#878787" }}
          d="m 183,688 26,7 v 48 l -26,8 z"
          id="path949"
        />
        <path
          style={{ fill: "#878787" }}
          d="m 183,480 26,8 v 47 l -26,8 z"
          id="path950"
        />
        <path
          style={{ fill: "#878787" }}
          d="m 26,481 -4,1 h -3 l -3,1 -4,1 -3,1 H 6 l -4,1 -2,1 v 6 6 6 6 6 6 6 6 l 2,1 4,1 3,1 3,1 4,1 3,1 3,1 4,1 v -4 -4 -4 -4 -4 -4 -4 -4 -3 -4 -4 -4 -4 -3 -4 z"
          id="path951"
        />
        <path
          style={{ fill: "#a9a9a9" }}
          d="m 183,910 26,4 v 28 l -26,4 z"
          id="path952"
        />
        <path
          style={{ fill: "#a9a9a9" }}
          d="m 26,910 -26,4 v 28 l 26,4 z"
          id="path953"
        />
        <path
          style={{ fill: "#a9a9a9" }}
          d="m 26,702 -26,4 v 29 l 26,4 z"
          id="path954"
        />
        <path
          style={{ fill: "#a9a9a9" }}
          d="m 183,702 26,4 v 29 l -26,4 z"
          id="path955"
        />
        <path
          style={{ fill: "#a9a9a9" }}
          d="m 183,493 26,4 v 28 l -26,4 z"
          id="path956"
        />
        <path
          style={{ fill: "#a9a9a9" }}
          d="m 26,493 -26,4 v 28 l 26,4 z"
          id="path957"
        />
        <path
          style={{ fill: "#7b7b7b" }}
          d="M 182,523 H 26 v 393 h 156 z"
          id="path958"
        />
        <path
          style={{ fill: "#939393" }}
          d="M 182,605 H 26 v 230 h 156 z"
          id="path959"
        />
        <path
          style={{ fill: "#a9a9a9" }}
          d="M 183,686 H 26 v 69 h 157 z"
          id="path960"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="m 183,884 26,10 v 68 l -26,10 v -88"
          id="path961"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 26,676 0,686 v 69 l 26,10 v -89"
          id="path962"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="m 183,467 26,12 v 66 l -26,10 v -88"
          id="path963"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 26,467 0,479 v 66 l 26,10 v -88"
          id="path964"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="m 181,441 h -77 v 556 h 77 V 441"
          id="path965"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 104,441 H 26 v 556 h 78 V 441"
          id="path966"
        />
      </g>
      <g id="inlet_pipe_2" transform="translate(12.365176,64.392205)">
        <path
          style={{ fill: "#7f7f7f", strokeWidth: "0.0338667" }}
          d="M 51.123508,122.60004 V 105.6667 h 4.4704 v 16.93334 z"
          id="path967"
        />
        <path
          style={{ fill: "#999999", strokeWidth: "0.0338667" }}
          d="M 51.394441,122.60004 V 105.6667 h 3.928534 v 16.93334 z"
          id="path968"
        />
        <path
          style={{ fill: "#b2b2b2", strokeWidth: "0.0338667" }}
          d="M 51.868575,122.60004 V 105.6667 h 2.980267 v 16.93334 z"
          id="path969"
        />
        <path
          style={{ fill: "#cccccc", strokeWidth: "0.0338667" }}
          d="M 52.342708,122.60004 V 105.6667 h 2.065867 v 16.93334 z"
          id="path970"
        />
        <path
          style={{ fill: "#e5e5e5", strokeWidth: "0.0338667" }}
          d="M 52.681375,122.60004 V 105.6667 h 1.3208 v 16.93334 z"
          id="path971"
        />
        <path
          style={{ fill: "#ffffff", strokeWidth: "0.0338667" }}
          d="M 53.087775,122.60004 V 105.6667 h 0.541867 v 16.93334 z"
          id="path972"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: "0.0677333" }}
          d="M 55.593908,122.60004 V 105.6667"
          id="path973"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: "0.0677333" }}
          d="m 51.123508,105.6667 v 16.93334"
          id="path974"
        />
        <path
          style={{ fill: "#7f7f7f", strokeWidth: "0.0338667" }}
          d="m 78.978563,122.23829 v -16.93334 h 4.4704 v 16.93334 z"
          id="path975"
        />
        <path
          style={{ fill: "#999999", strokeWidth: "0.0338667" }}
          d="m 79.249496,122.23829 v -16.93334 h 3.928534 v 16.93334 z"
          id="path976"
        />
        <path
          style={{ fill: "#b2b2b2", strokeWidth: "0.0338667" }}
          d="m 79.72363,122.23829 v -16.93334 h 2.980267 v 16.93334 z"
          id="path977"
        />
        <path
          style={{ fill: "#cccccc", strokeWidth: "0.0338667" }}
          d="m 80.197763,122.23829 v -16.93334 h 2.065867 v 16.93334 z"
          id="path978"
        />
        <path
          style={{ fill: "#e5e5e5", strokeWidth: "0.0338667" }}
          d="m 80.53643,122.23829 v -16.93334 h 1.3208 v 16.93334 z"
          id="path979"
        />
        <path
          style={{ fill: "#ffffff", strokeWidth: "0.0338667" }}
          d="m 80.94283,122.23829 v -16.93334 h 0.541867 v 16.93334 z"
          id="path980"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: "0.0677333" }}
          d="M 83.448963,122.23829 V 105.30495"
          id="path981"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: "0.0677333" }}
          d="m 78.978563,105.30495 v 16.93334"
          id="path982"
        />
        <path
          style={{ fill: "#7f7f7f", strokeWidth: "0.0338667" }}
          d="m 113.70695,122.23829 v -16.93334 h 4.4704 v 16.93334 z"
          id="path983"
        />
        <path
          style={{ fill: "#999999", strokeWidth: "0.0338667" }}
          d="m 113.97788,122.23829 v -16.93334 h 3.92854 v 16.93334 z"
          id="path984"
        />
        <path
          style={{ fill: "#b2b2b2", strokeWidth: "0.0338667" }}
          d="m 114.45202,122.23829 v -16.93334 h 2.98026 v 16.93334 z"
          id="path985"
        />
        <path
          style={{ fill: "#cccccc", strokeWidth: "0.0338667" }}
          d="m 114.92615,122.23829 v -16.93334 h 2.06587 v 16.93334 z"
          id="path986"
        />
        <path
          style={{ fill: "#e5e5e5", strokeWidth: "0.0338667" }}
          d="m 115.26482,122.23829 v -16.93334 h 1.3208 v 16.93334 z"
          id="path987"
        />
        <path
          style={{ fill: "#ffffff", strokeWidth: "0.0338667" }}
          d="m 115.67122,122.23829 v -16.93334 h 0.54186 v 16.93334 z"
          id="path988"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: "0.0677333" }}
          d="M 118.17735,122.23829 V 105.30495"
          id="path989"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: "0.0677333" }}
          d="m 113.70695,105.30495 v 16.93334"
          id="path990"
        />
        <path
          style={{ fill: "#7f7f7f", strokeWidth: "0.0338667" }}
          d="m 148.43533,122.23829 v -16.93334 h 4.4704 v 16.93334 z"
          id="path991"
        />
        <path
          style={{ fill: "#999999", strokeWidth: "0.0338667" }}
          d="m 148.70626,122.23829 v -16.93334 h 3.92854 v 16.93334 z"
          id="path992"
        />
        <path
          style={{ fill: "#b2b2b2", strokeWidth: "0.0338667" }}
          d="m 149.1804,122.23829 v -16.93334 h 2.98026 v 16.93334 z"
          id="path993"
        />
        <path
          style={{ fill: "#cccccc", strokeWidth: "0.0338667" }}
          d="m 149.65453,122.23829 v -16.93334 h 2.06587 v 16.93334 z"
          id="path994"
        />
        <path
          style={{ fill: "#e5e5e5", strokeWidth: "0.0338667" }}
          d="m 149.9932,122.23829 v -16.93334 h 1.3208 v 16.93334 z"
          id="path995"
        />
        <path
          style={{ fill: "#ffffff", strokeWidth: "0.0338667" }}
          d="m 150.3996,122.23829 v -16.93334 h 0.54186 v 16.93334 z"
          id="path996"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: "0.0677333" }}
          d="M 152.90573,122.23829 V 105.30495"
          id="path997"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: "0.0677333" }}
          d="m 148.43533,105.30495 v 16.93334"
          id="path998"
        />
        <g id="g1007" transform="translate(-1.0852619,-37.260658)">
          <g
            id="g1006"
            transform="matrix(0.0338603,0,0,0.0338603,46.340649,138.65145)"
          >
            <path
              style={{ fill: "#7f7f7f" }}
              d="M 0,0 H 500 V 133 H 0 Z"
              id="path999"
            />
            <path
              style={{ fill: "#999999" }}
              d="M 0,9 H 500 V 125 H 0 Z"
              id="path1000"
            />
            <path
              style={{ fill: "#b2b2b2" }}
              d="m 0,23 h 500 v 88 H 0 Z"
              id="path1001"
            />
            <path
              style={{ fill: "#cccccc" }}
              d="M 0,36 H 500 V 97 H 0 Z"
              id="path1002"
            />
            <path
              style={{ fill: "#e5e5e5" }}
              d="M 0,47 H 500 V 86 H 0 Z"
              id="path1003"
            />
            <path
              style={{ fill: "#ffffff" }}
              d="M 0,59 H 500 V 75 H 0 Z"
              id="path1004"
            />
            <path
              style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
              d="M 0,133 H 500"
              id="path1005"
            />
            <path
              style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
              d="M 500,0 H 0"
              id="path1006"
            />
          </g>
        </g>
        <g id="g1015" transform="translate(15.749761,-37.260658)">
          <g
            id="g1014"
            transform="matrix(0.0338603,0,0,0.0338603,46.340649,138.65145)"
          >
            <path
              style={{ fill: "#7f7f7f" }}
              d="M 0,0 H 500 V 133 H 0 Z"
              id="path1007"
            />
            <path
              style={{ fill: "#999999" }}
              d="M 0,9 H 500 V 125 H 0 Z"
              id="path1008"
            />
            <path
              style={{ fill: "#b2b2b2" }}
              d="m 0,23 h 500 v 88 H 0 Z"
              id="path1009"
            />
            <path
              style={{ fill: "#cccccc" }}
              d="M 0,36 H 500 V 97 H 0 Z"
              id="path1010"
            />
            <path
              style={{ fill: "#e5e5e5" }}
              d="M 0,47 H 500 V 86 H 0 Z"
              id="path1011"
            />
            <path
              style={{ fill: "#ffffff" }}
              d="M 0,59 H 500 V 75 H 0 Z"
              id="path1012"
            />
            <path
              style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
              d="M 0,133 H 500"
              id="path1013"
            />
            <path
              style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
              d="M 500,0 H 0"
              id="path1014"
            />
          </g>
        </g>
        <g id="g1023" transform="translate(33.113951,-37.260658)">
          <g
            id="g1022"
            transform="matrix(0.0338603,0,0,0.0338603,46.340649,138.65145)"
          >
            <path
              style={{ fill: "#7f7f7f" }}
              d="M 0,0 H 500 V 133 H 0 Z"
              id="path1015"
            />
            <path
              style={{ fill: "#999999" }}
              d="M 0,9 H 500 V 125 H 0 Z"
              id="path1016"
            />
            <path
              style={{ fill: "#b2b2b2" }}
              d="m 0,23 h 500 v 88 H 0 Z"
              id="path1017"
            />
            <path
              style={{ fill: "#cccccc" }}
              d="M 0,36 H 500 V 97 H 0 Z"
              id="path1018"
            />
            <path
              style={{ fill: "#e5e5e5" }}
              d="M 0,47 H 500 V 86 H 0 Z"
              id="path1019"
            />
            <path
              style={{ fill: "#ffffff" }}
              d="M 0,59 H 500 V 75 H 0 Z"
              id="path1020"
            />
            <path
              style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
              d="M 0,133 H 500"
              id="path1021"
            />
            <path
              style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
              d="M 500,0 H 0"
              id="path1022"
            />
          </g>
        </g>
        <g id="g1031" transform="translate(50.310728,-37.260658)">
          <g
            id="g1030"
            transform="matrix(0.0338603,0,0,0.0338603,46.340649,138.65145)"
          >
            <path
              style={{ fill: "#7f7f7f" }}
              d="M 0,0 H 500 V 133 H 0 Z"
              id="path1023"
            />
            <path
              style={{ fill: "#999999" }}
              d="M 0,9 H 500 V 125 H 0 Z"
              id="path1024"
            />
            <path
              style={{ fill: "#b2b2b2" }}
              d="m 0,23 h 500 v 88 H 0 Z"
              id="path1025"
            />
            <path
              style={{ fill: "#cccccc" }}
              d="M 0,36 H 500 V 97 H 0 Z"
              id="path1026"
            />
            <path
              style={{ fill: "#e5e5e5" }}
              d="M 0,47 H 500 V 86 H 0 Z"
              id="path1027"
            />
            <path
              style={{ fill: "#ffffff" }}
              d="M 0,59 H 500 V 75 H 0 Z"
              id="path1028"
            />
            <path
              style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
              d="M 0,133 H 500"
              id="path1029"
            />
            <path
              style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
              d="M 500,0 H 0"
              id="path1030"
            />
          </g>
        </g>
        <g id="g1039" transform="translate(67.842331,-37.260658)">
          <g
            id="g1038"
            transform="matrix(0.0338603,0,0,0.0338603,46.340649,138.65145)"
          >
            <path
              style={{ fill: "#7f7f7f" }}
              d="M 0,0 H 500 V 133 H 0 Z"
              id="path1031"
            />
            <path
              style={{ fill: "#999999" }}
              d="M 0,9 H 500 V 125 H 0 Z"
              id="path1032"
            />
            <path
              style={{ fill: "#b2b2b2" }}
              d="m 0,23 h 500 v 88 H 0 Z"
              id="path1033"
            />
            <path
              style={{ fill: "#cccccc" }}
              d="M 0,36 H 500 V 97 H 0 Z"
              id="path1034"
            />
            <path
              style={{ fill: "#e5e5e5" }}
              d="M 0,47 H 500 V 86 H 0 Z"
              id="path1035"
            />
            <path
              style={{ fill: "#ffffff" }}
              d="M 0,59 H 500 V 75 H 0 Z"
              id="path1036"
            />
            <path
              style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
              d="M 0,133 H 500"
              id="path1037"
            />
            <path
              style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
              d="M 500,0 H 0"
              id="path1038"
            />
          </g>
        </g>
        <g id="g1047" transform="translate(85.039108,-37.260658)">
          <g
            id="g1046"
            transform="matrix(0.0338603,0,0,0.0338603,46.340649,138.65145)"
          >
            <path
              style={{ fill: "#7f7f7f" }}
              d="M 0,0 H 500 V 133 H 0 Z"
              id="path1039"
            />
            <path
              style={{ fill: "#999999" }}
              d="M 0,9 H 500 V 125 H 0 Z"
              id="path1040"
            />
            <path
              style={{ fill: "#b2b2b2" }}
              d="m 0,23 h 500 v 88 H 0 Z"
              id="path1041"
            />
            <path
              style={{ fill: "#cccccc" }}
              d="M 0,36 H 500 V 97 H 0 Z"
              id="path1042"
            />
            <path
              style={{ fill: "#e5e5e5" }}
              d="M 0,47 H 500 V 86 H 0 Z"
              id="path1043"
            />
            <path
              style={{ fill: "#ffffff" }}
              d="M 0,59 H 500 V 75 H 0 Z"
              id="path1044"
            />
            <path
              style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
              d="M 0,133 H 500"
              id="path1045"
            />
            <path
              style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
              d="M 500,0 H 0"
              id="path1046"
            />
          </g>
        </g>
        <g id="g1055" transform="translate(102.57071,-37.260658)">
          <g
            id="g1054"
            transform="matrix(0.0338603,0,0,0.0338603,46.340649,138.65145)"
          >
            <path
              style={{ fill: "#7f7f7f" }}
              d="M 0,0 H 500 V 133 H 0 Z"
              id="path1047"
            />
            <path
              style={{ fill: "#999999" }}
              d="M 0,9 H 500 V 125 H 0 Z"
              id="path1048"
            />
            <path
              style={{ fill: "#b2b2b2" }}
              d="m 0,23 h 500 v 88 H 0 Z"
              id="path1049"
            />
            <path
              style={{ fill: "#cccccc" }}
              d="M 0,36 H 500 V 97 H 0 Z"
              id="path1050"
            />
            <path
              style={{ fill: "#e5e5e5" }}
              d="M 0,47 H 500 V 86 H 0 Z"
              id="path1051"
            />
            <path
              style={{ fill: "#ffffff" }}
              d="M 0,59 H 500 V 75 H 0 Z"
              id="path1052"
            />
            <path
              style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
              d="M 0,133 H 500"
              id="path1053"
            />
            <path
              style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
              d="M 500,0 H 0"
              id="path1054"
            />
          </g>
        </g>
        <g id="g1063" transform="translate(119.76749,-37.260658)">
          <g
            id="g1062"
            transform="matrix(0.0338603,0,0,0.0338603,46.340649,138.65145)"
          >
            <path
              style={{ fill: "#7f7f7f" }}
              d="M 0,0 H 500 V 133 H 0 Z"
              id="path1055"
            />
            <path
              style={{ fill: "#999999" }}
              d="M 0,9 H 500 V 125 H 0 Z"
              id="path1056"
            />
            <path
              style={{ fill: "#b2b2b2" }}
              d="m 0,23 h 500 v 88 H 0 Z"
              id="path1057"
            />
            <path
              style={{ fill: "#cccccc" }}
              d="M 0,36 H 500 V 97 H 0 Z"
              id="path1058"
            />
            <path
              style={{ fill: "#e5e5e5" }}
              d="M 0,47 H 500 V 86 H 0 Z"
              id="path1059"
            />
            <path
              style={{ fill: "#ffffff" }}
              d="M 0,59 H 500 V 75 H 0 Z"
              id="path1060"
            />
            <path
              style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
              d="M 0,133 H 500"
              id="path1061"
            />
            <path
              style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
              d="M 500,0 H 0"
              id="path1062"
            />
          </g>
        </g>
      </g>
      <g
        id="inlet_2_outlet_1"
        transform="matrix(0,0.03867649,-0.04019195,0,82.393595,186.93718)"
      >
        <path
          style={{ fill: "#7f7f7f" }}
          d="M 185,131 V 413 H 152 V 131 Z"
          id="path1064"
        />
        <path
          style={{ fill: "#b2b2b2" }}
          d="M 182,131 V 413 H 155 V 131 Z"
          id="path1065"
        />
        <path
          style={{ fill: "#e5e5e5" }}
          d="M 178,131 V 413 H 160 V 131 Z"
          id="path1066"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="m 172,131 v 282 h -6 V 131 Z"
          id="path1067"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 152,131 V 413"
          id="path1068"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 185,413 V 131"
          id="path1069"
        />
        <path
          style={{ fill: "#7f7f7f" }}
          d="M 166,135 H 31 l 3,-27 8,-25 12,-23 16,-20 20,-17 23,-12 26,-8 27,-3 27,3 25,8 23,12 20,17 17,20 12,23 8,25 3,27 z"
          id="path1070"
        />
        <path
          style={{ fill: "#999999" }}
          d="M 166,135 H 43 l 2,-27 8,-25 11,-23 15,-20 18,-17 21,-12 23,-8 25,-3 24,3 23,8 21,12 18,17 15,20 11,23 8,25 2,27 z"
          id="path1071"
        />
        <path
          style={{ fill: "#b2b2b2" }}
          d="M 166,135 H 69 l 2,-27 6,-25 8,-23 12,-20 15,-17 16,-12 18,-8 20,-3 19,3 19,8 16,12 14,17 12,20 9,23 6,25 2,27 z"
          id="path1072"
        />
        <path
          style={{ fill: "#cccccc" }}
          d="M 166,135 H 94 l 1,-27 5,-25 6,-23 9,-20 11,-17 12,-12 13,-8 15,-3 14,3 14,8 12,12 10,17 9,20 6,23 5,25 1,27 z"
          id="path1073"
        />
        <path
          style={{ fill: "#e5e5e5" }}
          d="m 166,135 h -47 l 1,-27 3,-25 4,-23 6,-20 7,-17 8,-12 9,-8 9,-3 9,3 9,8 8,12 6,17 6,20 4,23 3,25 1,27 z"
          id="path1074"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 166,135 H 31 l 3,-27 8,-25 12,-23 16,-20 20,-17 23,-12 26,-8 27,-3 27,3 25,8 23,12 20,17 17,20 12,23 8,25 3,27 H 166"
          id="path1075"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="m 166,135 h -13 v -27 l 1,-25 1,-23 2,-20 2,-17 2,-12 2,-8 3,-3 3,3 2,8 2,12 2,17 1,20 1,23 1,25 v 27 z"
          id="path1076"
        />
        <path
          style={{ fill: "#7f7f7f" }}
          d="M 0,493 V 327 L 332,493 V 327 Z"
          id="path1077"
        />
        <path
          style={{ fill: "#999999" }}
          d="M 0,485 V 336 L 332,485 V 336 Z"
          id="path1078"
        />
        <path
          style={{ fill: "#b2b2b2" }}
          d="M 0,468 V 353 L 332,468 V 353 Z"
          id="path1079"
        />
        <path
          style={{ fill: "#cccccc" }}
          d="m 0,451 v -81 l 332,81 v -81 z"
          id="path1080"
        />
        <path
          style={{ fill: "#e5e5e5" }}
          d="m 0,438 v -55 l 332,55 v -55 z"
          id="path1081"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="m 0,421 v -21 l 332,21 v -21 z"
          id="path1082"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 0,493 V 327 L 332,493 V 327 L 0,493"
          id="path1083"
        />
      </g>
      <g
        id="inlet_2_outlet_pipe_1"
        transform="matrix(0.03386667,0,0,0.03386667,63.48869,199.36097)"
      >
        <path
          style={{ fill: "#7f7f7f" }}
          d="M 0,500 V 0 h 132 v 500 z"
          id="path1084"
        />
        <path
          style={{ fill: "#999999" }}
          d="M 8,500 V 0 h 116 v 500 z"
          id="path1085"
        />
        <path
          style={{ fill: "#b2b2b2" }}
          d="M 22,500 V 0 h 88 v 500 z"
          id="path1086"
        />
        <path
          style={{ fill: "#cccccc" }}
          d="M 36,500 V 0 h 61 v 500 z"
          id="path1087"
        />
        <path
          style={{ fill: "#e5e5e5" }}
          d="M 46,500 V 0 h 39 v 500 z"
          id="path1088"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="M 58,500 V 0 h 16 v 500 z"
          id="path1089"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 132,500 V 0"
          id="path1090"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 0,0 V 500"
          id="path1091"
        />
      </g>
      <g
        id="inlet_2_outlet_2"
        transform="matrix(0,0.03867649,-0.04019195,0,110.24871,186.40801)"
      >
        <path
          style={{ fill: "#7f7f7f" }}
          d="M 185,131 V 413 H 152 V 131 Z"
          id="path1092"
        />
        <path
          style={{ fill: "#b2b2b2" }}
          d="M 182,131 V 413 H 155 V 131 Z"
          id="path1093"
        />
        <path
          style={{ fill: "#e5e5e5" }}
          d="M 178,131 V 413 H 160 V 131 Z"
          id="path1094"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="m 172,131 v 282 h -6 V 131 Z"
          id="path1095"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 152,131 V 413"
          id="path1096"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 185,413 V 131"
          id="path1097"
        />
        <path
          style={{ fill: "#7f7f7f" }}
          d="M 166,135 H 31 l 3,-27 8,-25 12,-23 16,-20 20,-17 23,-12 26,-8 27,-3 27,3 25,8 23,12 20,17 17,20 12,23 8,25 3,27 z"
          id="path1098"
        />
        <path
          style={{ fill: "#999999" }}
          d="M 166,135 H 43 l 2,-27 8,-25 11,-23 15,-20 18,-17 21,-12 23,-8 25,-3 24,3 23,8 21,12 18,17 15,20 11,23 8,25 2,27 z"
          id="path1099"
        />
        <path
          style={{ fill: "#b2b2b2" }}
          d="M 166,135 H 69 l 2,-27 6,-25 8,-23 12,-20 15,-17 16,-12 18,-8 20,-3 19,3 19,8 16,12 14,17 12,20 9,23 6,25 2,27 z"
          id="path1100"
        />
        <path
          style={{ fill: "#cccccc" }}
          d="M 166,135 H 94 l 1,-27 5,-25 6,-23 9,-20 11,-17 12,-12 13,-8 15,-3 14,3 14,8 12,12 10,17 9,20 6,23 5,25 1,27 z"
          id="path1101"
        />
        <path
          style={{ fill: "#e5e5e5" }}
          d="m 166,135 h -47 l 1,-27 3,-25 4,-23 6,-20 7,-17 8,-12 9,-8 9,-3 9,3 9,8 8,12 6,17 6,20 4,23 3,25 1,27 z"
          id="path1102"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 166,135 H 31 l 3,-27 8,-25 12,-23 16,-20 20,-17 23,-12 26,-8 27,-3 27,3 25,8 23,12 20,17 17,20 12,23 8,25 3,27 H 166"
          id="path1103"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="m 166,135 h -13 v -27 l 1,-25 1,-23 2,-20 2,-17 2,-12 2,-8 3,-3 3,3 2,8 2,12 2,17 1,20 1,23 1,25 v 27 z"
          id="path1104"
        />
        <path
          style={{ fill: "#7f7f7f" }}
          d="M 0,493 V 327 L 332,493 V 327 Z"
          id="path1105"
        />
        <path
          style={{ fill: "#999999" }}
          d="M 0,485 V 336 L 332,485 V 336 Z"
          id="path1106"
        />
        <path
          style={{ fill: "#b2b2b2" }}
          d="M 0,468 V 353 L 332,468 V 353 Z"
          id="path1107"
        />
        <path
          style={{ fill: "#cccccc" }}
          d="m 0,451 v -81 l 332,81 v -81 z"
          id="path1108"
        />
        <path
          style={{ fill: "#e5e5e5" }}
          d="m 0,438 v -55 l 332,55 v -55 z"
          id="path1109"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="m 0,421 v -21 l 332,21 v -21 z"
          id="path1110"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 0,493 V 327 L 332,493 V 327 L 0,493"
          id="path1111"
        />
      </g>
      <g
        id="inlet_2_outlet_pipe_2"
        transform="matrix(0.03386667,0,0,0.03386667,91.343745,198.99922)"
      >
        <path
          style={{ fill: "#7f7f7f" }}
          d="M 0,500 V 0 h 132 v 500 z"
          id="path1112"
        />
        <path
          style={{ fill: "#999999" }}
          d="M 8,500 V 0 h 116 v 500 z"
          id="path1113"
        />
        <path
          style={{ fill: "#b2b2b2" }}
          d="M 22,500 V 0 h 88 v 500 z"
          id="path1114"
        />
        <path
          style={{ fill: "#cccccc" }}
          d="M 36,500 V 0 h 61 v 500 z"
          id="path1115"
        />
        <path
          style={{ fill: "#e5e5e5" }}
          d="M 46,500 V 0 h 39 v 500 z"
          id="path1116"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="M 58,500 V 0 h 16 v 500 z"
          id="path1117"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 132,500 V 0"
          id="path1118"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 0,0 V 500"
          id="path1119"
        />
      </g>
      <g
        id="inlet_2_outlet_3"
        transform="matrix(0,0.03867649,-0.04019195,0,144.9771,186.93719)"
      >
        <path
          style={{ fill: "#7f7f7f" }}
          d="M 185,131 V 413 H 152 V 131 Z"
          id="path1120"
        />
        <path
          style={{ fill: "#b2b2b2" }}
          d="M 182,131 V 413 H 155 V 131 Z"
          id="path1121"
        />
        <path
          style={{ fill: "#e5e5e5" }}
          d="M 178,131 V 413 H 160 V 131 Z"
          id="path1122"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="m 172,131 v 282 h -6 V 131 Z"
          id="path1123"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 152,131 V 413"
          id="path1124"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 185,413 V 131"
          id="path1125"
        />
        <path
          style={{ fill: "#7f7f7f" }}
          d="M 166,135 H 31 l 3,-27 8,-25 12,-23 16,-20 20,-17 23,-12 26,-8 27,-3 27,3 25,8 23,12 20,17 17,20 12,23 8,25 3,27 z"
          id="path1126"
        />
        <path
          style={{ fill: "#999999" }}
          d="M 166,135 H 43 l 2,-27 8,-25 11,-23 15,-20 18,-17 21,-12 23,-8 25,-3 24,3 23,8 21,12 18,17 15,20 11,23 8,25 2,27 z"
          id="path1127"
        />
        <path
          style={{ fill: "#b2b2b2" }}
          d="M 166,135 H 69 l 2,-27 6,-25 8,-23 12,-20 15,-17 16,-12 18,-8 20,-3 19,3 19,8 16,12 14,17 12,20 9,23 6,25 2,27 z"
          id="path1128"
        />
        <path
          style={{ fill: "#cccccc" }}
          d="M 166,135 H 94 l 1,-27 5,-25 6,-23 9,-20 11,-17 12,-12 13,-8 15,-3 14,3 14,8 12,12 10,17 9,20 6,23 5,25 1,27 z"
          id="path1129"
        />
        <path
          style={{ fill: "#e5e5e5" }}
          d="m 166,135 h -47 l 1,-27 3,-25 4,-23 6,-20 7,-17 8,-12 9,-8 9,-3 9,3 9,8 8,12 6,17 6,20 4,23 3,25 1,27 z"
          id="path1130"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 166,135 H 31 l 3,-27 8,-25 12,-23 16,-20 20,-17 23,-12 26,-8 27,-3 27,3 25,8 23,12 20,17 17,20 12,23 8,25 3,27 H 166"
          id="path1131"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="m 166,135 h -13 v -27 l 1,-25 1,-23 2,-20 2,-17 2,-12 2,-8 3,-3 3,3 2,8 2,12 2,17 1,20 1,23 1,25 v 27 z"
          id="path1132"
        />
        <path
          style={{ fill: "#7f7f7f" }}
          d="M 0,493 V 327 L 332,493 V 327 Z"
          id="path1133"
        />
        <path
          style={{ fill: "#999999" }}
          d="M 0,485 V 336 L 332,485 V 336 Z"
          id="path1134"
        />
        <path
          style={{ fill: "#b2b2b2" }}
          d="M 0,468 V 353 L 332,468 V 353 Z"
          id="path1135"
        />
        <path
          style={{ fill: "#cccccc" }}
          d="m 0,451 v -81 l 332,81 v -81 z"
          id="path1136"
        />
        <path
          style={{ fill: "#e5e5e5" }}
          d="m 0,438 v -55 l 332,55 v -55 z"
          id="path1137"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="m 0,421 v -21 l 332,21 v -21 z"
          id="path1138"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 0,493 V 327 L 332,493 V 327 L 0,493"
          id="path1139"
        />
      </g>
      <g
        id="inlet_2_outlet_pipe_3"
        transform="matrix(0.03386667,0,0,0.03386667,126.0722,198.99922)"
      >
        <path
          style={{ fill: "#7f7f7f" }}
          d="M 0,500 V 0 h 132 v 500 z"
          id="path1140"
        />
        <path
          style={{ fill: "#999999" }}
          d="M 8,500 V 0 h 116 v 500 z"
          id="path1141"
        />
        <path
          style={{ fill: "#b2b2b2" }}
          d="M 22,500 V 0 h 88 v 500 z"
          id="path1142"
        />
        <path
          style={{ fill: "#cccccc" }}
          d="M 36,500 V 0 h 61 v 500 z"
          id="path1143"
        />
        <path
          style={{ fill: "#e5e5e5" }}
          d="M 46,500 V 0 h 39 v 500 z"
          id="path1144"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="M 58,500 V 0 h 16 v 500 z"
          id="path1145"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 132,500 V 0"
          id="path1146"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 0,0 V 500"
          id="path1147"
        />
      </g>
      <g
        id="inlet_2_outlet_4"
        transform="matrix(0,0.03867649,-0.04019195,0,179.70548,186.93718)"
      >
        <path
          style={{ fill: "#7f7f7f" }}
          d="M 185,131 V 413 H 152 V 131 Z"
          id="path1148"
        />
        <path
          style={{ fill: "#b2b2b2" }}
          d="M 182,131 V 413 H 155 V 131 Z"
          id="path1149"
        />
        <path
          style={{ fill: "#e5e5e5" }}
          d="M 178,131 V 413 H 160 V 131 Z"
          id="path1150"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="m 172,131 v 282 h -6 V 131 Z"
          id="path1151"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 152,131 V 413"
          id="path1152"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 185,413 V 131"
          id="path1153"
        />
        <path
          style={{ fill: "#7f7f7f" }}
          d="M 166,135 H 31 l 3,-27 8,-25 12,-23 16,-20 20,-17 23,-12 26,-8 27,-3 27,3 25,8 23,12 20,17 17,20 12,23 8,25 3,27 z"
          id="path1154"
        />
        <path
          style={{ fill: "#999999" }}
          d="M 166,135 H 43 l 2,-27 8,-25 11,-23 15,-20 18,-17 21,-12 23,-8 25,-3 24,3 23,8 21,12 18,17 15,20 11,23 8,25 2,27 z"
          id="path1155"
        />
        <path
          style={{ fill: "#b2b2b2" }}
          d="M 166,135 H 69 l 2,-27 6,-25 8,-23 12,-20 15,-17 16,-12 18,-8 20,-3 19,3 19,8 16,12 14,17 12,20 9,23 6,25 2,27 z"
          id="path1156"
        />
        <path
          style={{ fill: "#cccccc" }}
          d="M 166,135 H 94 l 1,-27 5,-25 6,-23 9,-20 11,-17 12,-12 13,-8 15,-3 14,3 14,8 12,12 10,17 9,20 6,23 5,25 1,27 z"
          id="path1157"
        />
        <path
          style={{ fill: "#e5e5e5" }}
          d="m 166,135 h -47 l 1,-27 3,-25 4,-23 6,-20 7,-17 8,-12 9,-8 9,-3 9,3 9,8 8,12 6,17 6,20 4,23 3,25 1,27 z"
          id="path1158"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 166,135 H 31 l 3,-27 8,-25 12,-23 16,-20 20,-17 23,-12 26,-8 27,-3 27,3 25,8 23,12 20,17 17,20 12,23 8,25 3,27 H 166"
          id="path1159"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="m 166,135 h -13 v -27 l 1,-25 1,-23 2,-20 2,-17 2,-12 2,-8 3,-3 3,3 2,8 2,12 2,17 1,20 1,23 1,25 v 27 z"
          id="path1160"
        />
        <path
          style={{ fill: "#7f7f7f" }}
          d="M 0,493 V 327 L 332,493 V 327 Z"
          id="path1161"
        />
        <path
          style={{ fill: "#999999" }}
          d="M 0,485 V 336 L 332,485 V 336 Z"
          id="path1162"
        />
        <path
          style={{ fill: "#b2b2b2" }}
          d="M 0,468 V 353 L 332,468 V 353 Z"
          id="path1163"
        />
        <path
          style={{ fill: "#cccccc" }}
          d="m 0,451 v -81 l 332,81 v -81 z"
          id="path1164"
        />
        <path
          style={{ fill: "#e5e5e5" }}
          d="m 0,438 v -55 l 332,55 v -55 z"
          id="path1165"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="m 0,421 v -21 l 332,21 v -21 z"
          id="path1166"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 0,493 V 327 L 332,493 V 327 L 0,493"
          id="path1167"
        />
      </g>
      <g
        id="inlet_2_outlet_pipe_4"
        transform="matrix(0.03386667,0,0,0.03386667,160.80058,198.99922)"
      >
        <path
          style={{ fill: "#7f7f7f" }}
          d="M 0,500 V 0 h 132 v 500 z"
          id="path1168"
        />
        <path
          style={{ fill: "#999999" }}
          d="M 8,500 V 0 h 116 v 500 z"
          id="path1169"
        />
        <path
          style={{ fill: "#b2b2b2" }}
          d="M 22,500 V 0 h 88 v 500 z"
          id="path1170"
        />
        <path
          style={{ fill: "#cccccc" }}
          d="M 36,500 V 0 h 61 v 500 z"
          id="path1171"
        />
        <path
          style={{ fill: "#e5e5e5" }}
          d="M 46,500 V 0 h 39 v 500 z"
          id="path1172"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="M 58,500 V 0 h 16 v 500 z"
          id="path1173"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 132,500 V 0"
          id="path1174"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 0,0 V 500"
          id="path1175"
        />
      </g>
      <g id="inlet_pipe" style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}>
        <g
          id="g1216"
          transform="matrix(0,1,1,0,-123.73331,-22.561189)"
          style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
        >
          <g
            id="g1215"
            transform="matrix(0.0338603,0,0,0.0338603,46.340649,138.65145)"
            style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
          >
            <path
              style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
              d="M 0,0 H 500 V 133 H 0 Z"
              id="path1208"
            />
            <path
              style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
              d="M 0,9 H 500 V 125 H 0 Z"
              id="path1209"
            />
            <path
              style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
              d="m 0,23 h 500 v 88 H 0 Z"
              id="path1210"
            />
            <path
              style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
              d="M 0,36 H 500 V 97 H 0 Z"
              id="path1211"
            />
            <path
              style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
              d="M 0,47 H 500 V 86 H 0 Z"
              id="path1212"
            />
            <path
              style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
              d="M 0,59 H 500 V 75 H 0 Z"
              id="path1213"
            />
            <path
              style={{
                fill: "#63a6ff",
                fillOpacity: "0.355731",
                stroke: "#4c4c4c",
                strokeWidth: 2
              }}
              d="M 0,133 H 500"
              id="path1214"
            />
            <path
              style={{
                fill: "#63a6ff",
                fillOpacity: "0.355731",
                stroke: "#4c4c4c",
                strokeWidth: 2
              }}
              d="M 500,0 H 0"
              id="path1215"
            />
          </g>
        </g>
        <g
          id="g1224"
          transform="matrix(0,1,1,0,-123.73331,-5.7261661)"
          style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
        >
          <g
            id="g1223"
            transform="matrix(0.0338603,0,0,0.0338603,46.340649,138.65145)"
            style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
          >
            <path
              style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
              d="M 0,0 H 500 V 133 H 0 Z"
              id="path1216"
            />
            <path
              style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
              d="M 0,9 H 500 V 125 H 0 Z"
              id="path1217"
            />
            <path
              style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
              d="m 0,23 h 500 v 88 H 0 Z"
              id="path1218"
            />
            <path
              style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
              d="M 0,36 H 500 V 97 H 0 Z"
              id="path1219"
            />
            <path
              style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
              d="M 0,47 H 500 V 86 H 0 Z"
              id="path1220"
            />
            <path
              style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
              d="M 0,59 H 500 V 75 H 0 Z"
              id="path1221"
            />
            <path
              style={{
                fill: "#63a6ff",
                fillOpacity: "0.355731",
                stroke: "#4c4c4c",
                strokeWidth: 2
              }}
              d="M 0,133 H 500"
              id="path1222"
            />
            <path
              style={{
                fill: "#63a6ff",
                fillOpacity: "0.355731",
                stroke: "#4c4c4c",
                strokeWidth: 2
              }}
              d="M 500,0 H 0"
              id="path1223"
            />
          </g>
        </g>
        <g
          id="g1232"
          transform="matrix(0,1,1,0,-123.73331,11.638024)"
          style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
        >
          <g
            id="g1231"
            transform="matrix(0.0338603,0,0,0.0338603,46.340649,138.65145)"
            style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
          >
            <path
              style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
              d="M 0,0 H 500 V 133 H 0 Z"
              id="path1224"
            />
            <path
              style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
              d="M 0,9 H 500 V 125 H 0 Z"
              id="path1225"
            />
            <path
              style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
              d="m 0,23 h 500 v 88 H 0 Z"
              id="path1226"
            />
            <path
              style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
              d="M 0,36 H 500 V 97 H 0 Z"
              id="path1227"
            />
            <path
              style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
              d="M 0,47 H 500 V 86 H 0 Z"
              id="path1228"
            />
            <path
              style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
              d="M 0,59 H 500 V 75 H 0 Z"
              id="path1229"
            />
            <path
              style={{
                fill: "#63a6ff",
                fillOpacity: "0.355731",
                stroke: "#4c4c4c",
                strokeWidth: 2
              }}
              d="M 0,133 H 500"
              id="path1230"
            />
            <path
              style={{
                fill: "#63a6ff",
                fillOpacity: "0.355731",
                stroke: "#4c4c4c",
                strokeWidth: 2
              }}
              d="M 500,0 H 0"
              id="path1231"
            />
          </g>
        </g>
        <g
          id="g1240"
          transform="matrix(0,1,1,0,-123.73331,28.834801)"
          style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
        >
          <g
            id="g1239"
            transform="matrix(0.0338603,0,0,0.0338603,46.340649,138.65145)"
            style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
          >
            <path
              style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
              d="M 0,0 H 500 V 133 H 0 Z"
              id="path1232"
            />
            <path
              style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
              d="M 0,9 H 500 V 125 H 0 Z"
              id="path1233"
            />
            <path
              style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
              d="m 0,23 h 500 v 88 H 0 Z"
              id="path1234"
            />
            <path
              style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
              d="M 0,36 H 500 V 97 H 0 Z"
              id="path1235"
            />
            <path
              style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
              d="M 0,47 H 500 V 86 H 0 Z"
              id="path1236"
            />
            <path
              style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
              d="M 0,59 H 500 V 75 H 0 Z"
              id="path1237"
            />
            <path
              style={{
                fill: "#63a6ff",
                fillOpacity: "0.355731",
                stroke: "#4c4c4c",
                strokeWidth: 2
              }}
              d="M 0,133 H 500"
              id="path1238"
            />
            <path
              style={{
                fill: "#63a6ff",
                fillOpacity: "0.355731",
                stroke: "#4c4c4c",
                strokeWidth: 2
              }}
              d="M 500,0 H 0"
              id="path1239"
            />
          </g>
        </g>
        <g
          id="g1248"
          transform="matrix(0,1,1,0,-123.73331,46.366404)"
          style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
        >
          <g
            id="g1247"
            transform="matrix(0.0338603,0,0,0.0338603,46.340649,138.65145)"
            style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
          >
            <path
              style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
              d="M 0,0 H 500 V 133 H 0 Z"
              id="path1240"
            />
            <path
              style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
              d="M 0,9 H 500 V 125 H 0 Z"
              id="path1241"
            />
            <path
              style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
              d="m 0,23 h 500 v 88 H 0 Z"
              id="path1242"
            />
            <path
              style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
              d="M 0,36 H 500 V 97 H 0 Z"
              id="path1243"
            />
            <path
              style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
              d="M 0,47 H 500 V 86 H 0 Z"
              id="path1244"
            />
            <path
              style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
              d="M 0,59 H 500 V 75 H 0 Z"
              id="path1245"
            />
            <path
              style={{
                fill: "#63a6ff",
                fillOpacity: "0.355731",
                stroke: "#4c4c4c",
                strokeWidth: 2
              }}
              d="M 0,133 H 500"
              id="path1246"
            />
            <path
              style={{
                fill: "#63a6ff",
                fillOpacity: "0.355731",
                stroke: "#4c4c4c",
                strokeWidth: 2
              }}
              d="M 500,0 H 0"
              id="path1247"
            />
          </g>
        </g>
        <g
          id="g1256"
          transform="matrix(0,1,1,0,-123.73331,63.563181)"
          style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
        >
          <g
            id="g1255"
            transform="matrix(0.0338603,0,0,0.0338603,46.340649,138.65145)"
            style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
          >
            <path
              style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
              d="M 0,0 H 500 V 133 H 0 Z"
              id="path1248"
            />
            <path
              style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
              d="M 0,9 H 500 V 125 H 0 Z"
              id="path1249"
            />
            <path
              style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
              d="m 0,23 h 500 v 88 H 0 Z"
              id="path1250"
            />
            <path
              style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
              d="M 0,36 H 500 V 97 H 0 Z"
              id="path1251"
            />
            <path
              style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
              d="M 0,47 H 500 V 86 H 0 Z"
              id="path1252"
            />
            <path
              style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
              d="M 0,59 H 500 V 75 H 0 Z"
              id="path1253"
            />
            <path
              style={{
                fill: "#63a6ff",
                fillOpacity: "0.355731",
                stroke: "#4c4c4c",
                strokeWidth: 2
              }}
              d="M 0,133 H 500"
              id="path1254"
            />
            <path
              style={{
                fill: "#63a6ff",
                fillOpacity: "0.355731",
                stroke: "#4c4c4c",
                strokeWidth: 2
              }}
              d="M 500,0 H 0"
              id="path1255"
            />
          </g>
        </g>
        <g
          id="g1264"
          transform="matrix(0,1,1,0,-123.73331,81.094783)"
          style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
        >
          <g
            id="g1263"
            transform="matrix(0.0338603,0,0,0.0338603,46.340649,138.65145)"
            style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
          >
            <path
              style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
              d="M 0,0 H 500 V 133 H 0 Z"
              id="path1256"
            />
            <path
              style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
              d="M 0,9 H 500 V 125 H 0 Z"
              id="path1257"
            />
            <path
              style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
              d="m 0,23 h 500 v 88 H 0 Z"
              id="path1258"
            />
            <path
              style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
              d="M 0,36 H 500 V 97 H 0 Z"
              id="path1259"
            />
            <path
              style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
              d="M 0,47 H 500 V 86 H 0 Z"
              id="path1260"
            />
            <path
              style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
              d="M 0,59 H 500 V 75 H 0 Z"
              id="path1261"
            />
            <path
              style={{
                fill: "#63a6ff",
                fillOpacity: "0.355731",
                stroke: "#4c4c4c",
                strokeWidth: 2
              }}
              d="M 0,133 H 500"
              id="path1262"
            />
            <path
              style={{
                fill: "#63a6ff",
                fillOpacity: "0.355731",
                stroke: "#4c4c4c",
                strokeWidth: 2
              }}
              d="M 500,0 H 0"
              id="path1263"
            />
          </g>
        </g>
        <g
          id="g1272"
          transform="matrix(0,1,1,0,-123.73331,98.29156)"
          style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
        >
          <g
            id="g1271"
            transform="matrix(0.0338603,0,0,0.0338603,46.340649,138.65145)"
            style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
          >
            <path
              style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
              d="M 0,0 H 500 V 133 H 0 Z"
              id="path1264"
            />
            <path
              style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
              d="M 0,9 H 500 V 125 H 0 Z"
              id="path1265"
            />
            <path
              style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
              d="m 0,23 h 500 v 88 H 0 Z"
              id="path1266"
            />
            <path
              style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
              d="M 0,36 H 500 V 97 H 0 Z"
              id="path1267"
            />
            <path
              style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
              d="M 0,47 H 500 V 86 H 0 Z"
              id="path1268"
            />
            <path
              style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
              d="M 0,59 H 500 V 75 H 0 Z"
              id="path1269"
            />
            <path
              style={{
                fill: "#63a6ff",
                fillOpacity: "0.355731",
                stroke: "#4c4c4c",
                strokeWidth: 2
              }}
              d="M 0,133 H 500"
              id="path1270"
            />
            <path
              style={{
                fill: "#63a6ff",
                fillOpacity: "0.355731",
                stroke: "#4c4c4c",
                strokeWidth: 2
              }}
              d="M 500,0 H 0"
              id="path1271"
            />
          </g>
        </g>
        <g
          id="Group_Right_Angle"
          transform="matrix(0.07977553,0,0,0.08620671,15.020412,161.25736)"
          style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
        >
          <linearGradient
            id="SVGID_1_"
            gradientUnits="userSpaceOnUse"
            x1="56.25"
            y1="56.2495"
            x2={0}
            y2="56.2495"
          >
            <stop offset="0.01" style={{ stopColor: "#646566" }} id="stop1" />
            <stop offset="0.18" style={{ stopColor: "#959595" }} id="stop2" />
            <stop offset="0.5" style={{ stopColor: "#E0E0E0" }} id="stop3" />
            <stop offset={1} style={{ stopColor: "#666666" }} id="stop4" />
          </linearGradient>
          <path
            fill="url(#SVGID_1_)"
            d="M 56.25,0 H 0 V 112.5 L 56.25,97.15 Z"
            id="path4-41"
            style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
          />
          <linearGradient
            id="SVGID_2_"
            gradientUnits="userSpaceOnUse"
            x1="56.2495"
            y1="56.25"
            x2="56.2495"
            y2="112.5005"
          >
            <stop offset="0.01" style={{ stopColor: "#646566" }} id="stop5" />
            <stop offset="0.16" style={{ stopColor: "#909091" }} id="stop6" />
            <stop offset="0.49" style={{ stopColor: "#E0E0E0" }} id="stop7" />
            <stop offset={1} style={{ stopColor: "#646566" }} id="stop8" />
          </linearGradient>
          <path
            fill="url(#SVGID_2_)"
            d="M 112.5,56.25 V 112.5 H 0 L 56.25,56.25 Z"
            id="path8-32"
            style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
          />
        </g>
        <g
          id="g1281"
          transform="matrix(0,-0.03386667,0.03386667,0,23.392835,170.82598)"
          style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
        >
          <path
            style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
            d="M 0,500 V 0 h 132 v 500 z"
            id="path1274"
          />
          <path
            style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
            d="M 8,500 V 0 h 116 v 500 z"
            id="path1275"
          />
          <path
            style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
            d="M 22,500 V 0 h 88 v 500 z"
            id="path1276"
          />
          <path
            style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
            d="M 36,500 V 0 h 61 v 500 z"
            id="path1277"
          />
          <path
            style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
            d="M 46,500 V 0 h 39 v 500 z"
            id="path1278"
          />
          <path
            style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
            d="M 58,500 V 0 h 16 v 500 z"
            id="path1279"
          />
          <path
            style={{
              fill: "#63a6ff",
              fillOpacity: "0.355731",
              stroke: "#4c4c4c",
              strokeWidth: 2
            }}
            d="M 132,500 V 0"
            id="path1280"
          />
          <path
            style={{
              fill: "#63a6ff",
              fillOpacity: "0.355731",
              stroke: "#4c4c4c",
              strokeWidth: 2
            }}
            d="M 0,0 V 500"
            id="path1281"
          />
        </g>
        <g
          id="g1289"
          transform="matrix(0,0.03386667,0.04254877,0,18.187795,137.58311)"
          style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
        >
          <path
            style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
            d="M 0,500 V 0 h 132 v 500 z"
            id="path1282"
          />
          <path
            style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
            d="M 8,500 V 0 h 116 v 500 z"
            id="path1283"
          />
          <path
            style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
            d="M 22,500 V 0 h 88 v 500 z"
            id="path1284"
          />
          <path
            style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
            d="M 36,500 V 0 h 61 v 500 z"
            id="path1285"
          />
          <path
            style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
            d="M 46,500 V 0 h 39 v 500 z"
            id="path1286"
          />
          <path
            style={{ fill: "#63a6ff", fillOpacity: "0.355731" }}
            d="M 58,500 V 0 h 16 v 500 z"
            id="path1287"
          />
          <path
            style={{
              fill: "#63a6ff",
              fillOpacity: "0.355731",
              stroke: "#4c4c4c",
              strokeWidth: 2
            }}
            d="M 132,500 V 0"
            id="path1288"
          />
          <path
            style={{
              fill: "#63a6ff",
              fillOpacity: "0.355731",
              stroke: "#4c4c4c",
              strokeWidth: 2
            }}
            d="M 0,0 V 500"
            id="path1289"
          />
        </g>
      </g>
      <ellipse
        style={{
          fill: buttonStatus["inlet_1_button_1"] === "on" ? "#808080" : "#c52626",  
          fillOpacity: "0.670588",
          strokeWidth: "0.264583"
        }}
        id="inlet_1_button_1"
        cx="65.170059"
        cy="146.98755"
        rx="3.0749087"
        ry="2.8940315"
        className="valveButtons"
      />
      {buttonStatus["inlet_1_button_1"] !== "" && (
        <text x="70" y="148" fill="#343434" fontSize="4.5" fontFamily="Comic Sans MS" fontWeight={600}>
          {buttonStatus["inlet_1_button_1"]}
        </text>
      )}
      <ellipse
        style={{
          fill: buttonStatus["inlet_1_button_2"] === "on" ? "#808080" : "#c52626",
          fillOpacity: "0.670588",
          strokeWidth: "0.264583"
        }}
        id="inlet_1_button_2"
        cx="93.025116"
        cy="146.62579"
        rx="3.0749087"
        ry="2.8940315"
        className="valveButtons"
      />
      {buttonStatus["inlet_1_button_2"] !== "" && (
    <text x="98" y="148" fill="#343434" fontSize="4.5" fontFamily="Comic Sans MS" fontWeight={600}>
      {buttonStatus["inlet_1_button_2"]}
    </text>
  )}
      <ellipse
        style={{
          fill: buttonStatus["inlet_1_button_3"] === "on" ? "#808080" : "#c52626", 
          fillOpacity: "0.670588",
          strokeWidth: "0.264583"
        }}
        id="inlet_1_button_3"
        cx="128.11525"
        cy="146.62579"
        rx="3.0749087"
        ry="2.8940315"
        className="valveButtons"
      />
      {buttonStatus["inlet_1_button_3"] !== "" && (
        <text x="133" y="148" fill="#343434" fontSize="4.5" fontFamily="Comic Sans MS" fontWeight={600}>
          {buttonStatus["inlet_1_button_3"]}
        </text>
      )}
      <ellipse
        style={{
          fill: buttonStatus["inlet_1_button_4"] === "on" ? "#808080" : "#c52626",
          fillOpacity: "0.670588",
          strokeWidth: "0.264583"
        }}
        id="inlet_1_button_4"
        cx="163.20538"
        cy="146.98755"
        rx="3.0749087"
        ry="2.8940315"
        className="valveButtons"
      />
      {buttonStatus["inlet_1_button_4"] !== "" && (
       <text x="168" y="148" fill="#343434" fontSize="4.5" fontFamily="Comic Sans MS" fontWeight={600}>
        {buttonStatus["inlet_1_button_4"]}
       </text>
      )}

      <ellipse
        style={{
          fill: buttonStatus["inlet_2_button_1"] === "on" ? "#808080" : "#c52626",
          fillOpacity: "0.670588",
          strokeWidth: "0.264583"
        }}
        id="inlet_2_button_1"
        cx="65.170059"
        cy="160.73419"
        rx="3.0749087"
        ry="2.8940315"
        className="valveButtons"
      />
      {buttonStatus["inlet_2_button_1"] !== "" && (
      <text x="70" y="162" fill="#343434" fontSize="4.5" fontFamily="Comic Sans MS" fontWeight={600}>
      {buttonStatus["inlet_2_button_1"]}
      </text>
      )}

      <ellipse
        style={{
          fill: buttonStatus["inlet_2_button_2"] === "on" ? "#808080" : "#c52626",
          fillOpacity: "0.670588",
          strokeWidth: "0.264583"
        }}
        id="inlet_2_button_2"
        cx="93.025116"
        cy="160.64893"
        rx="3.0749087"
        ry="2.8940315"
        className="valveButtons"
      />
      {buttonStatus["inlet_2_button_2"] !== "" && (
      <text x="98" y="162" fill="#343434" fontSize="4.5" fontFamily="Comic Sans MS" fontWeight={600}>
      {buttonStatus["inlet_2_button_2"]}
      </text>
      )}
      <ellipse
        style={{
          fill: buttonStatus["inlet_2_button_3"] === "on" ? "#808080" : "#c52626",
          fillOpacity: "0.670588",
          strokeWidth: "0.264583"
        }}
        id="inlet_2_button_3"
        cx="128.47701"
        cy="160.64893"
        rx="3.0749087"
        ry="2.8940315"
        className="valveButtons"
      />
      {buttonStatus["inlet_2_button_3"] !== "" && (
      <text x="133" y="162" fill="#343434" fontSize="4.5" fontFamily="Comic Sans MS" fontWeight={600}>
      {buttonStatus["inlet_2_button_3"]}
      </text>
      )}
      <ellipse
        style={{
          fill: buttonStatus["inlet_2_button_4"] === "on" ? "#808080" : "#c52626",
          fillOpacity: "0.670588",
          strokeWidth: "0.264583"
        }}
        id="inlet_2_button_4"
        cx="163.56714"
        cy="160.67244"
        rx="3.0749087"
        ry="2.8940315"
        className="valveButtons"
      />
      {buttonStatus["inlet_2_button_4"] !== "" && (
      <text x="168" y="162" fill="#343434" fontSize="4.5" fontFamily="Comic Sans MS" fontWeight={600}>
      {buttonStatus["inlet_2_button_4"]}
      </text>
      )}
      <rect
        style={{
          fill: "#ffffff",
          fillOpacity: 1,
          stroke: "url(#linearGradient11)",
          strokeWidth: "0.3",
          strokeDasharray: "none"
        }}
        id="rect19"
        width="41.039459"
        height="14.467863"
        x="23.606632"
        y="75.315619"
        ry="3.7"
        rx="3.7"
      />
      <text
        xmlSpace="preserve"
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: "normal",
          fontStretch: "normal",
          fontSize: "4.9389px",
          fontFamily: '"Century Schoolbook"',
          InkscapeFontSpecification: '"Century Schoolbook, Normal"',
          fontVariantLigatures: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fill: "#2e231e",
          fillOpacity: "0.92549",
          stroke: "#000000",
          strokeWidth: "0.365",
          strokeLinejoin: "miter",
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "stroke markers fill"
        }}
        x="32.069569"
        y="31.949795"
        id="text19"
      >
        <tspan
          
          id="tspan19"
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: "normal",
            fontStretch: "normal",
            fontSize: "4.9389px",
            fontFamily: '"Century Schoolbook"',
            InkscapeFontSpecification: '"Century Schoolbook, Normal"',
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fill: "#2e231e",
            fillOpacity: "0.92549",
            stroke: "#000000",
            strokeWidth: "0.365",
            strokeDasharray: "none",
            strokeOpacity: 1
          }}
          x="30.069569"
          y="80.949795"
        >
          PRESSURE 
        </tspan>
      </text>
      <text
        xmlSpace="preserve"
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: "normal",
          fontStretch: "normal",
          fontSize: "4.9389px",
          fontFamily: '"Century Schoolbook"',
          InkscapeFontSpecification: '"Century Schoolbook, Normal"',
          fontVariantLigatures: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fill: "#000000",
          fillOpacity: 1,
          stroke: "#000000",
          strokeWidth: "0.365",
          strokeLinejoin: "miter",
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "stroke markers fill"
        }}
        x="58.839352"
        y="37.737858"
        id="text20"
      >
        <tspan
          
          id="tspan20"
          style={{
            fill: "#000000",
            fillOpacity: 1,
            stroke: "#000000",
            strokeWidth: "0.365",
            strokeDasharray: "none",
            strokeOpacity: 1
          }}
          x="41.839352"
          y="87.737858"
        >
          Kg/cm2
        </tspan>
      </text>
      <text
        xmlSpace="preserve"
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: "normal",
          fontStretch: "normal",
          fontSize: "4.93889px",
          fontFamily: '"Century Schoolbook"',
          InkscapeFontSpecification: '"Century Schoolbook, Normal"',
          fontVariantLigatures: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fill: "#000000",
          fillOpacity: 1,
          stroke: "#000000",
          strokeWidth: "0.665001",
          strokeLinejoin: "miter",
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "stroke markers fill",
          textAnchor: "middle"
        }}
        x="51.015438"
        y="38.193718"
        id="inlet_pressure_text"
      >
        <tspan
          
          id="tspan21"
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: "normal",
            fontStretch: "normal",
            fontSize: "4.93889px",
            fontFamily: '"Century Schoolbook"',
            InkscapeFontSpecification: '"Century Schoolbook, Normal"',
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fill: "#000000",
            fillOpacity: 1,
            strokeWidth: "0.665",
            strokeDasharray: "none"
          }}
          x="36.015438"
          y="87.593719"
        >
          0
        </tspan>
      </text>
      <rect
        style={{
          fill: "#211915",
          fillOpacity: "0.92549",
          stroke: "url(#linearGradient24)",
          strokeWidth: "0.667296",
          strokeLinejoin: "miter",
          strokeDasharray: "0.667296, 0.667296",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "stroke markers fill"
        }}
        id="rect21"
        width="41.026428"
        height="13.760125"
        x="171.24785"
        y="123.6985"
        ry="3.5190036"
        transform="matrix(0.99996077,-0.0088575,0.00542423,0.99998529,0,0)"
        rx="3.5190036"
      />
      <text
        xmlSpace="preserve"
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: "normal",
          fontStretch: "normal",
          fontSize: "4.32512px",
          fontFamily: '"Century Schoolbook"',
          InkscapeFontSpecification: '"Century Schoolbook, Normal"',
          fontVariantLigatures: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fill: "#ffffff",
          fillOpacity: 1,
          stroke: "#000000",
          strokeWidth: "0.665001",
          strokeLinejoin: "miter",
          strokeDasharray: "0.665001, 0.665001",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "stroke markers fill"
        }}
        x="195.05"
        y="115.30627"
        id="text22"
        transform="matrix(0.89765025,-0.00599151,0.00801892,1.1139661,0,0)"
      >
        <tspan
          
          id="tspan22"
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: "bold",
            fontStretch: "normal",
            fontSize: "3.52778px",
            fontFamily: '"Century Schoolbook"',
            InkscapeFontSpecification: '"Century Schoolbook, Bold"',
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fill: "#ffffff",
            fillOpacity: 1,
            strokeWidth: "0.665"
          }}
          x="195.05"
          y="115.30627"
        >
          PRESSURE 
        </tspan>
      </text>
      <text
        xmlSpace="preserve"
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: "normal",
          fontStretch: "normal",
          fontSize: "4.32512px",
          fontFamily: '"Century Schoolbook"',
          InkscapeFontSpecification: '"Century Schoolbook, Normal"',
          fontVariantLigatures: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fill: "#fefef4",
          fillOpacity: "0.978362",
          stroke: "#000000",
          strokeWidth: "0.665001",
          strokeLinejoin: "miter",
          strokeDasharray: "0.665001, 0.665001",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "stroke markers fill"
        }}
        x="210.24031"
        y="120.3306"
        id="text23"
        transform="matrix(0.89765025,-0.00599151,0.00801892,1.1139661,0,0)"
      >
        <tspan
          
          id="tspan23"
          style={{ strokeWidth: "0.665" }}
          x="210.24031"
          y="120.3306"
        >
          Kg/cm2
        </tspan>
      </text>
      <rect
        style={{
          fill: "#ffffff",
          fillOpacity: 1,
          stroke: "url(#linearGradient16)",
          strokeWidth: "0.300001",
          strokeDasharray: "0.300001, 0.300001",
          strokeDashoffset: 0
        }}
        id="rect25"
        width="41.026428"
        height="13.760125"
        x="171.24785"
        y="123.6985"
        ry="3.5190036"
        transform="matrix(0.99996077,-0.0088575,0.00542423,0.99998529,0,0)"
        rx="3.5190036"
      />
      <text
        xmlSpace="preserve"
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: "normal",
          fontStretch: "normal",
          fontSize: "4.32512px",
          fontFamily: '"Century Schoolbook"',
          InkscapeFontSpecification: '"Century Schoolbook, Normal"',
          fontVariantLigatures: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fill: "#000000",
          fillOpacity: 1,
          stroke: "#000000",
          strokeWidth: "0.365",
          strokeLinejoin: "miter",
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "stroke markers fill"
        }}
        x="195.05"
        y="115.30627"
        id="text25"
        transform="matrix(0.89765025,-0.00599151,0.00801892,1.1139661,0,0)"
      >
        <tspan
          
          id="tspan25"
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: "bold",
            fontStretch: "normal",
            fontSize: "3.52778px",
            fontFamily: '"Century Schoolbook"',
            InkscapeFontSpecification: '"Century Schoolbook, Bold"',
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fill: "#000000",
            fillOpacity: 1,
            strokeWidth: "0.365",
            strokeDasharray: "none"
          }}
          x="200.05"
          y="115.30627"
        >
          PRESSURE 
        </tspan>
      </text>
      <text
        xmlSpace="preserve"
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: "normal",
          fontStretch: "normal",
          fontSize: "4.32512px",
          fontFamily: '"Century Schoolbook"',
          InkscapeFontSpecification: '"Century Schoolbook, Normal"',
          fontVariantLigatures: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fill: "#000000",
          fillOpacity: 1,
          stroke: "#000000",
          strokeWidth: "0.365",
          strokeLinejoin: "miter",
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "stroke markers fill"
        }}
        x="210.24031"
        y="120.3306"
        id="text26"
        transform="matrix(0.89765025,-0.00599151,0.00801892,1.1139661,0,0)"
      >
        <tspan
          
          id="tspan26"
          style={{
            fill: "#000000",
            fillOpacity: 1,
            strokeWidth: "0.365",
            strokeDasharray: "none"
          }}
          x="208.24031"
          y="120.3306"
        >
          Kg/cm2
        </tspan>
      </text>
      <text
        xmlSpace="preserve"
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: "normal",
          fontStretch: "normal",
          fontSize: "4.23333px",
          fontFamily: '"Century Schoolbook"',
          InkscapeFontSpecification: '"Century Schoolbook, Normal"',
          fontVariantLigatures: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fill: "#000000",
          fillOpacity: 1,
          stroke: "#000000",
          strokeWidth: "0.665001",
          strokeLinejoin: "miter",
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "stroke markers fill",
          textAnchor: "middle"
        }}
        x="198.57477"
        y="120.3017"
        id="inlet_1_pressure_text"
        transform="matrix(0.89765025,-0.00599151,0.00801892,1.1139661,0,0)"
      >
        <tspan  id="tspan124" x="202.57477" y="120.3017">
          0
        </tspan>
      </text>
      <rect
        style={{
          fill: "#211915",
          fillOpacity: "0.92549",
          stroke: "url(#linearGradient34)",
          strokeWidth: "0.667296",
          strokeLinejoin: "miter",
          strokeDasharray: "0.667296, 0.667296",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "stroke markers fill"
        }}
        id="rect27"
        width="31.026428"
        height="13.760125"
        x="169.52809"
        y="173.9678"
        ry="3.5190036"
        transform="matrix(0.99996077,-0.0088575,0.00542423,0.99998529,0,0)"
        rx="3.5190036"
      />
      <text
        xmlSpace="preserve"
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: "normal",
          fontStretch: "normal",
          fontSize: "4.32512px",
          fontFamily: '"Century Schoolbook"',
          InkscapeFontSpecification: '"Century Schoolbook, Normal"',
          fontVariantLigatures: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fill: "#ffffff",
          fillOpacity: 1,
          stroke: "#000000",
          strokeWidth: "0.665001",
          strokeLinejoin: "miter",
          strokeDasharray: "0.665001, 0.665001",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "stroke markers fill"
        }}
        x="193.03485"
        y="160.43486"
        id="text28"
        transform="matrix(0.89765025,-0.00599151,0.00801892,1.1139661,0,0)"
      >
        <tspan
          
          id="tspan28"
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: "bold",
            fontStretch: "normal",
            fontSize: "3.52778px",
            fontFamily: '"Century Schoolbook"',
            InkscapeFontSpecification: '"Century Schoolbook, Bold"',
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fill: "#ffffff",
            fillOpacity: 1,
            strokeWidth: "0.665"
          }}
          x="193.03485"
          y="160.43486"
        >
          PRESSURE 
        </tspan>
      </text>
      <text
        xmlSpace="preserve"
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: "normal",
          fontStretch: "normal",
          fontSize: "4.32512px",
          fontFamily: '"Century Schoolbook"',
          InkscapeFontSpecification: '"Century Schoolbook, Normal"',
          fontVariantLigatures: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fill: "#fefef4",
          fillOpacity: "0.978362",
          stroke: "#000000",
          strokeWidth: "0.665001",
          strokeLinejoin: "miter",
          strokeDasharray: "0.665001, 0.665001",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "stroke markers fill"
        }}
        x="208.22516"
        y="165.4592"
        id="text29"
        transform="matrix(0.89765025,-0.00599151,0.00801892,1.1139661,0,0)"
      >
        <tspan
          
          id="tspan29"
          style={{ strokeWidth: "0.665" }}
          x="200.22516"
          y="165.4592"
        >
          Kg/cm2
        </tspan>
      </text>
      <text
        xmlSpace="preserve"
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: "normal",
          fontStretch: "normal",
          fontSize: "4.94298px",
          fontFamily: '"Century Schoolbook"',
          InkscapeFontSpecification: '"Century Schoolbook, Normal"',
          fontVariantLigatures: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fill: "#fefef4",
          fillOpacity: "0.978362",
          stroke: "#000000",
          strokeWidth: "0.665001",
          strokeLinejoin: "miter",
          strokeDasharray: "0.665001, 0.665001",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "stroke markers fill",
          textAnchor: "middle"
        }}
        x="204.15401"
        y="165.94623"
        id="text30"
        transform="matrix(0.89765025,-0.00599151,0.00801892,1.1139661,0,0)"
      >
        <tspan
          
          id="tspan30"
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: "normal",
            fontStretch: "normal",
            fontSize: "4.94298px",
            fontFamily: '"Century Schoolbook"',
            InkscapeFontSpecification: '"Century Schoolbook, Normal"',
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            strokeWidth: "0.665"
          }}
          x="204.15401"
          y="165.94623"
        >
          0
        </tspan>
      </text>
      <rect
        style={{
          fill: "#ffffff",
          fillOpacity: 1,
          stroke: "url(#linearGradient17)",
          strokeWidth: "0.300001",
          strokeDasharray: "0.300001, 0.300001",
          strokeDashoffset: 0
        }}
        id="rect30"
        width="41.026428"
        height="13.760125"
        x="169.52809"
        y="173.9678"
        ry="3.5190036"
        transform="matrix(0.99996077,-0.0088575,0.00542423,0.99998529,0,0)"
        rx="3.5190036"
      />
      <text
        xmlSpace="preserve"
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: "normal",
          fontStretch: "normal",
          fontSize: "4.32512px",
          fontFamily: '"Century Schoolbook"',
          InkscapeFontSpecification: '"Century Schoolbook, Normal"',
          fontVariantLigatures: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fill: "#000000",
          fillOpacity: 1,
          stroke: "#000000",
          strokeWidth: "0.365",
          strokeLinejoin: "miter",
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "stroke markers fill"
        }}
        x="193.03485"
        y="160.43486"
        id="text31"
        transform="matrix(0.89765025,-0.00599151,0.00801892,1.1139661,0,0)"
      >
        <tspan
          
          id="tspan31"
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: "bold",
            fontStretch: "normal",
            fontSize: "3.52778px",
            fontFamily: '"Century Schoolbook"',
            InkscapeFontSpecification: '"Century Schoolbook, Bold"',
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fill: "#000000",
            fillOpacity: 1,
            strokeWidth: "0.365",
            strokeDasharray: "none"
          }}
          x="200.03485"
          y="160.43486"
        >
          PRESSURE 
        </tspan>
      </text>
      <text
        xmlSpace="preserve"
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: "normal",
          fontStretch: "normal",
          fontSize: "4.32512px",
          fontFamily: '"Century Schoolbook"',
          InkscapeFontSpecification: '"Century Schoolbook, Normal"',
          fontVariantLigatures: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fill: "#000000",
          fillOpacity: 1,
          stroke: "#000000",
          strokeWidth: "0.365",
          strokeLinejoin: "miter",
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "stroke markers fill"
        }}
        x="208.22516"
        y="165.4592"
        id="text32"
        transform="matrix(0.89765025,-0.00599151,0.00801892,1.1139661,0,0)"
      >
        <tspan
          
          id="tspan32"
          style={{
            fill: "#000000",
            fillOpacity: 1,
            strokeWidth: "0.365",
            strokeDasharray: "none"
          }}
          x="207.22516"
          y="165.4592"
        >
          Kg/cm2
        </tspan>
      </text>
      <text
        xmlSpace="preserve"
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: "normal",
          fontStretch: "normal",
          fontSize: "4.23333px",
          fontFamily: '"Century Schoolbook"',
          InkscapeFontSpecification: '"Century Schoolbook, Normal"',
          fontVariantLigatures: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fill: "#000000",
          fillOpacity: 1,
          stroke: "#000000",
          strokeWidth: "0.665001",
          strokeLinejoin: "miter",
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "stroke markers fill",
          textAnchor: "middle"
        }}
        x="196.11871"
        y="165.42798"
        id="inlet_2_pressure_text"
        transform="matrix(0.89765025,-0.00599151,0.00801892,1.1139661,0,0)"
      >
        <tspan  id="tspan129" x="202.11871" y="165.42800">
          0
        </tspan>
      </text>
      <rect
        style={{
          fill: "#ffffff",
          fillOpacity: 1,
          stroke: "url(#linearGradient9)",
          strokeWidth: "0.300001",
          strokeDasharray: "0.300001, 0.300001",
          strokeDashoffset: 0
        }}
        id="rect41"
        width="31.026428"
        height="13.760125"
        x="23.898886"
        y="102.69395"
        ry="3.5190036"
        transform="matrix(0.99996077,-0.0088575,0.00542423,0.99998529,0,0)"
        rx="3.5190036"
      />
      <text
        xmlSpace="preserve"
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: "normal",
          fontStretch: "normal",
          fontSize: "4.23333px",
          fontFamily: '"Century Schoolbook"',
          InkscapeFontSpecification: '"Century Schoolbook, Normal"',
          fontVariantLigatures: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fill: "#000000",
          fillOpacity: 1,
          stroke: "#000000",
          strokeWidth: "0.665001",
          strokeLinejoin: "miter",
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "stroke markers fill"
        }}
        x="35.599541"
        y="104.04322"
        id="inlet_1_flow"
        transform="matrix(0.89765025,-0.00599151,0.00801892,1.1139661,0,0)"
      >
        <tspan
          
          id="tspan43"
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: "normal",
            fontStretch: "normal",
            fontSize: "4.23333px",
            fontFamily: '"Century Schoolbook"',
            InkscapeFontSpecification: '"Century Schoolbook, Normal"',
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fill: "#000000",
            fillOpacity: 1,
            strokeWidth: "0.665",
            strokeDasharray: "none",
            textAnchor: "middle"
          }}
          x="35.599541"
          y="103.00322"
        >
          0
        </tspan>
      </text>
      <text
        xmlSpace="preserve"
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: "normal",
          fontStretch: "normal",
          fontSize: "4.32512px",
          fontFamily: '"Century Schoolbook"',
          InkscapeFontSpecification: '"Century Schoolbook, Normal"',
          fontVariantLigatures: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fill: "#000000",
          fillOpacity: 1,
          stroke: "#000000",
          strokeWidth: "0.365",
          strokeLinejoin: "miter",
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "stroke markers fill"
        }}
        x="26.485779"
        y="98.542793"
        id="text44"
        transform="matrix(0.89765025,-0.00599151,0.00801892,1.1139661,0,0)"
      >
        <tspan  id="tspan47" x="35.485779" y="97.542793">
          FLOW 
        </tspan>
      </text>
      <text
        xmlSpace="preserve"
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: "normal",
          fontStretch: "normal",
          fontSize: "4.32512px",
          fontFamily: '"Century Schoolbook"',
          InkscapeFontSpecification: '"Century Schoolbook, Normal"',
          fontVariantLigatures: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fill: "#000000",
          fillOpacity: 1,
          stroke: "#000000",
          strokeWidth: "0.365",
          strokeLinejoin: "miter",
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "stroke markers fill"
        }}
        x="41.676231"
        y="103.56705"
        id="text45"
        transform="matrix(0.89765025,-0.00599151,0.00801892,1.1139661,0,0)"
      >
        <tspan  id="tspan48" x="41.676231" y="102.56706">
          LPS
        </tspan>
      </text>
      <rect
        style={{
          fill: "#ffffff",
          fillOpacity: 1,
          stroke: "url(#linearGradient10)",
          strokeWidth: "0.300001",
          strokeDasharray: "0.300001, 0.300001",
          strokeDashoffset: 0
        }}
        id="rect48"
        width="31.026428"
        height="13.760125"
        x="24.010809"
        y="119.88877"
        ry="3.5190036"
        transform="matrix(0.99996077,-0.0088575,0.00542423,0.99998529,0,0)"
        rx="3.5190036"
      />
      <text
        xmlSpace="preserve"
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: "normal",
          fontStretch: "normal",
          fontSize: "4.23333px",
          fontFamily: '"Century Schoolbook"',
          InkscapeFontSpecification: '"Century Schoolbook, Normal"',
          fontVariantLigatures: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fill: "#000000",
          fillOpacity: 1,
          stroke: "#000000",
          strokeWidth: "0.665001",
          strokeLinejoin: "miter",
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "stroke markers fill",
          textAnchor: "middle"
        }}
        x="31.796591"
        y="117.66196"
        id="inlet_1_cum"
        transform="matrix(0.89765025,-0.00599151,0.00801892,1.1139661,0,0)"
      >
        <tspan  id="tspan130" x="36.796591" y="117.66196">
          0
        </tspan>
      </text>
      <text
        xmlSpace="preserve"
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: "normal",
          fontStretch: "normal",
          fontSize: "4.32512px",
          fontFamily: '"Century Schoolbook"',
          InkscapeFontSpecification: '"Century Schoolbook, Normal"',
          fontVariantLigatures: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fill: "#000000",
          fillOpacity: 1,
          stroke: "#000000",
          strokeWidth: "0.365",
          strokeLinejoin: "miter",
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "stroke markers fill"
        }}
        x="26.580433"
        y="112.18251"
        id="text50"
        transform="matrix(0.89765025,-0.00599151,0.00801892,1.1139661,0,0)"
      >
        <tspan  id="tspan52" x="36.480433" y="112.16251">
          CUM 
        </tspan>
      </text>
      <text
        xmlSpace="preserve"
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: "normal",
          fontStretch: "normal",
          fontSize: "4.32512px",
          fontFamily: '"Century Schoolbook"',
          InkscapeFontSpecification: '"Century Schoolbook, Normal"',
          fontVariantLigatures: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fill: "#000000",
          fillOpacity: 1,
          stroke: "#000000",
          strokeWidth: "0.365",
          strokeLinejoin: "miter",
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "stroke markers fill"
        }}
        x="41.770885"
        y="117.20687"
        id="text51"
        transform="matrix(0.89765025,-0.00599151,0.00801892,1.1139661,0,0)"
      >
        <tspan  id="tspan51" x="45.770885" y="118.20687">
          m3
        </tspan>
      </text>
      <rect
        style={{
          fill: "#ffffff",
          fillOpacity: 1,
          stroke: "url(#linearGradient13)",
          strokeWidth: "0.300001",
          strokeDasharray: "0.300001, 0.300001",
          strokeDashoffset: 0
        }}
        id="rect52"
        width="32.026428"
        height="13.760125"
        x="19.337894"
        y="176.66136"
        ry="3.5190036"
        transform="matrix(0.99996077,-0.0088575,0.00542423,0.99998529,0,0)"
        rx="3.5190036"
      />
      <text
        xmlSpace="preserve"
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: "normal",
          fontStretch: "normal",
          fontSize: "4.23333px",
          fontFamily: '"Century Schoolbook"',
          InkscapeFontSpecification: '"Century Schoolbook, Normal"',
          fontVariantLigatures: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fill: "#000000",
          fillOpacity: 1,
          stroke: "#000000",
          strokeWidth: "0.665001",
          strokeLinejoin: "miter",
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "stroke markers fill",
          textAnchor: "middle"
        }}
        x="26.57152"
        y="168.61885"
        id="inlet_2_flow"
        transform="matrix(0.89765025,-0.00599151,0.00801892,1.1139661,0,0)"
      >
        <tspan  id="tspan131" x="31.57150" y="169.11875">
          0
        </tspan>
      </text>
      <text
        xmlSpace="preserve"
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: "normal",
          fontStretch: "normal",
          fontSize: "4.32512px",
          fontFamily: '"Century Schoolbook"',
          InkscapeFontSpecification: '"Century Schoolbook, Normal"',
          fontVariantLigatures: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fill: "#000000",
          fillOpacity: 1,
          stroke: "#000000",
          strokeWidth: "0.365",
          strokeLinejoin: "miter",
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "stroke markers fill",
        }}
        x="23.490614"
        y="163.15077"
        id="text54"
        transform="matrix(0.89765025,-0.00599151,0.00801892,1.1139661,0,0)"
      >
        <tspan  id="tspan54" x="31.490614" y="164.14280">
          FLOW 
        </tspan>
      </text>
      <text
        xmlSpace="preserve"
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: "normal",
          fontStretch: "normal",
          fontSize: "4.32512px",
          fontFamily: '"Century Schoolbook"',
          InkscapeFontSpecification: '"Century Schoolbook, Normal"',
          fontVariantLigatures: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fill: "#000000",
          fillOpacity: 1,
          stroke: "#000000",
          strokeWidth: "0.365",
          strokeLinejoin: "miter",
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "stroke markers fill"
        }}
        x="38.681068"
        y="168.17502"
        id="text55"
        transform="matrix(0.89765025,-0.00599151,0.00801892,1.1139661,0,0)"
      >
        <tspan  id="tspan55" x="37.681068" y="169.17502">
          LPS
        </tspan>
      </text>
      <rect
        style={{
          fill: "#ffffff",
          fillOpacity: 1,
          stroke: "url(#linearGradient15)",
          strokeWidth: "0.300001",
          strokeDasharray: "0.300001, 0.300001",
          strokeDashoffset: 0
        }}
        id="rect55"
        width="31.026428"
        height="13.760125"
        x="19.449818"
        y="193.85619"
        ry="3.5190036"
        transform="matrix(0.99996077,-0.0088575,0.00542423,0.99998529,0,0)"
        rx="3.5190036"
      />
      <text
        xmlSpace="preserve"
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: "normal",
          fontStretch: "normal",
          fontSize: "4.23333px",
          fontFamily: '"Century Schoolbook"',
          InkscapeFontSpecification: '"Century Schoolbook, Normal"',
          fontVariantLigatures: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fill: "#000000",
          fillOpacity: 1,
          stroke: "#000000",
          strokeWidth: "0.665001",
          strokeLinejoin: "miter",
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "stroke markers fill",
          textAnchor: "middle"
        }}
        x="29.162388"
        y="182.27206"
        id="inlet_2_cum"
        transform="matrix(0.89765025,-0.00599151,0.00801892,1.1139661,0,0)"
      >
        <tspan
          
          id="tspan56"
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: "normal",
            fontStretch: "normal",
            fontSize: "4.23333px",
            fontFamily: '"Century Schoolbook"',
            InkscapeFontSpecification: '"Century Schoolbook, Normal"',
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            fill: "#000000",
            fillOpacity: 1,
            strokeWidth: "0.665",
            strokeDasharray: "none"
          }}
          x="32.162388"
          y="183.87206"
        >
          0
        </tspan>
      </text>
      <text
        xmlSpace="preserve"
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: "normal",
          fontStretch: "normal",
          fontSize: "4.32512px",
          fontFamily: '"Century Schoolbook"',
          InkscapeFontSpecification: '"Century Schoolbook, Normal"',
          fontVariantLigatures: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fill: "#000000",
          fillOpacity: 1,
          stroke: "#000000",
          strokeWidth: "0.365",
          strokeLinejoin: "miter",
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "stroke markers fill"
        }}
        x="23.585268"
        y="176.79048"
        id="text57"
        transform="matrix(0.89765025,-0.00599151,0.00801892,1.1139661,0,0)"
      >
        <tspan  id="tspan57" x="31.585268" y="178.79048">
          CUM 
        </tspan>
      </text>
      <text
        xmlSpace="preserve"
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: "normal",
          fontStretch: "normal",
          fontSize: "4.32512px",
          fontFamily: '"Century Schoolbook"',
          InkscapeFontSpecification: '"Century Schoolbook, Normal"',
          fontVariantLigatures: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fill: "#000000",
          fillOpacity: 1,
          stroke: "#000000",
          strokeWidth: "0.365",
          strokeLinejoin: "miter",
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          paintOrder: "stroke markers fill"
        }}
        x="38.775723"
        y="181.81485"
        id="text58"
        transform="matrix(0.89765025,-0.00599151,0.00801892,1.1139661,0,0)"
      >
        <tspan  id="tspan58" x="41.475723" y="183.81485">
          m3
        </tspan>
      </text>
      {/* <rect
        style={{ fill: "#c52626", fillOpacity: "0.670588" }}
        id="inlet_2_button"
        width="4.6030002"
        height="4.8119988"
        x="32.431332"
        y="157.84018"
        className="valveButtons"
      /> */}
      {/* <rect
        id="inlet_1_button"
        width="4.6030002"
        height="4.8119988"
        x="32.069576"
        y="145.54054"
        style={{ fill: "#c52626", fillOpacity: "0.670588" }}
        className="valveButtons"
      /> */}
      <g
        id="inlet_1_outlet_4"
        transform="matrix(0,-0.03867649,-0.04019195,0,178.81456,121.83367)"
      >
        <path
          style={{ fill: "#7f7f7f" }}
          d="M 185,131 V 413 H 152 V 131 Z"
          id="path97"
        />
        <path
          style={{ fill: "#b2b2b2" }}
          d="M 182,131 V 413 H 155 V 131 Z"
          id="path98"
        />
        <path
          style={{ fill: "#e5e5e5" }}
          d="M 178,131 V 413 H 160 V 131 Z"
          id="path107"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="m 172,131 v 282 h -6 V 131 Z"
          id="path108"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 152,131 V 413"
          id="path109"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 185,413 V 131"
          id="path110"
        />
        <path
          style={{ fill: "#7f7f7f" }}
          d="M 166,135 H 31 l 3,-27 8,-25 12,-23 16,-20 20,-17 23,-12 26,-8 27,-3 27,3 25,8 23,12 20,17 17,20 12,23 8,25 3,27 z"
          id="path111"
        />
        <path
          style={{ fill: "#999999" }}
          d="M 166,135 H 43 l 2,-27 8,-25 11,-23 15,-20 18,-17 21,-12 23,-8 25,-3 24,3 23,8 21,12 18,17 15,20 11,23 8,25 2,27 z"
          id="path112"
        />
        <path
          style={{ fill: "#b2b2b2" }}
          d="M 166,135 H 69 l 2,-27 6,-25 8,-23 12,-20 15,-17 16,-12 18,-8 20,-3 19,3 19,8 16,12 14,17 12,20 9,23 6,25 2,27 z"
          id="path113"
        />
        <path
          style={{ fill: "#cccccc" }}
          d="M 166,135 H 94 l 1,-27 5,-25 6,-23 9,-20 11,-17 12,-12 13,-8 15,-3 14,3 14,8 12,12 10,17 9,20 6,23 5,25 1,27 z"
          id="path114"
        />
        <path
          style={{ fill: "#e5e5e5" }}
          d="m 166,135 h -47 l 1,-27 3,-25 4,-23 6,-20 7,-17 8,-12 9,-8 9,-3 9,3 9,8 8,12 6,17 6,20 4,23 3,25 1,27 z"
          id="path115"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 166,135 H 31 l 3,-27 8,-25 12,-23 16,-20 20,-17 23,-12 26,-8 27,-3 27,3 25,8 23,12 20,17 17,20 12,23 8,25 3,27 H 166"
          id="path116"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="m 166,135 h -13 v -27 l 1,-25 1,-23 2,-20 2,-17 2,-12 2,-8 3,-3 3,3 2,8 2,12 2,17 1,20 1,23 1,25 v 27 z"
          id="path117"
        />
        <path
          style={{ fill: "#7f7f7f" }}
          d="M 0,493 V 327 L 332,493 V 327 Z"
          id="path118"
        />
        <path
          style={{ fill: "#999999" }}
          d="M 0,485 V 336 L 332,485 V 336 Z"
          id="path119"
        />
        <path
          style={{ fill: "#b2b2b2" }}
          d="M 0,468 V 353 L 332,468 V 353 Z"
          id="path120"
        />
        <path
          style={{ fill: "#cccccc" }}
          d="m 0,451 v -81 l 332,81 v -81 z"
          id="path121"
        />
        <path
          style={{ fill: "#e5e5e5" }}
          d="m 0,438 v -55 l 332,55 v -55 z"
          id="path122"
        />
        <path
          style={{ fill: "#ffffff" }}
          d="m 0,421 v -21 l 332,21 v -21 z"
          id="path123"
        />
        <path
          style={{ fill: "none", stroke: "#4c4c4c", strokeWidth: 2 }}
          d="M 0,493 V 327 L 332,493 V 327 L 0,493"
          id="path124"
        />
      </g>
    </g>
  </g>
</svg>
  );
};

export default SvgComponent;
